@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

%heading-level-2 {
  @include mixins.initialize(heading);
  font-weight: bold;
  padding-bottom: inhouse.get-spacing-size(xs);
  border-bottom: inhouse.get-border-size(m) solid
    inhouse.get-primitive-color(gray, 300);
  @include inhouse.text(l, dense);
}

%heading-level-3 {
  @include mixins.initialize(heading);
  margin-bottom: inhouse.get-spacing-size(xs);
  font-weight: bold;
  @include inhouse.text(m, normal);
}

%section-spacing-top {
  margin-top: inhouse.get-spacing-size(l);
}

.enroll-event-group {
  padding: inhouse.get-spacing-size(m) 0 inhouse.get-spacing-size(xl);
}

.enroll-event-group__course-title {
  @extend %heading-level-3;
  margin-bottom: 0;
}

.enroll-event-group__student {
  margin-top: inhouse.get-spacing-size(l);
}

.enroll-event-group__student-title {
  @extend %heading-level-2;
}

.enroll-event-group__student-note {
  @include inhouse.text(s, dense);

  ul {
    @include mixins.initialize(ul);
    list-style: disc;
    margin: inhouse.get-spacing-size(s) 0 inhouse.get-spacing-size(s)
      inhouse.get-spacing-size(xl);
  }

  li {
    @include mixins.initialize(li);
  }

  li + li {
    margin-top: inhouse.get-spacing-size(xs);
  }

  ._require {
    color: inhouse.get-primitive-color(red, 600);
    text-decoration: none;
  }
}

.enroll-event-group__role-switch {
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 50);
}

.enroll-event-group__role-switch-title {
  margin-bottom: inhouse.get-spacing-size(xs);
  @include inhouse.text(m, normal);
}

.enroll-event-group__student-selector {
  @extend %section-spacing-top;

  &.--hidden {
    display: none;
  }
}

.enroll-event-group__student-selector-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: inhouse.get-spacing-size(xs);
}

.enroll-event-group__student-selector-title {
  @extend %heading-level-3;
  margin-bottom: 0;
}

.enroll-event-group__student-selector-list {
  background-color: inhouse.get-primitive-color(white);
  @include inhouse.interactive-list-style;

  // 上下に間隔を空けない
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._list {
    li > label {
      background-color: inhouse.get-semantic-color(neutral, 50);

      // α版ではスマホ版のインターフェースしか提供しないため
      // PCサイズでも両端のスペーシングはスマホサイズと揃える
      padding-right: inhouse.get-spacing-size(m) !important;
      padding-left: inhouse.get-spacing-size(m) !important;
    }

    li + li {
      margin-top: inhouse.get-spacing-size(xxs);
    }
  }
}

.enroll-event-group__student-form {
  @extend %section-spacing-top;

  &.--hidden {
    display: none;
  }
}

.enroll-event-group__student-address {
  @extend %section-spacing-top;

  &.--hidden {
    display: none;
  }
}

.enroll-event-group__student-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: inhouse.get-spacing-size(xs);
}

.enroll-event-group__student-form-title {
  @extend %heading-level-3;
  margin-bottom: 0;
}

.enroll-event-group__account-data {
  @extend %section-spacing-top;

  &.--hidden {
    display: none;
  }
}

.enroll-event-group__account-data-title {
  @extend %heading-level-3;

  &.--hidden {
    display: none;
  }
}

.enroll-event-group__account-data-note,
.enroll-event-group__automatic-settlement-note {
  @include inhouse.text(s, dense);

  &.--hidden {
    display: none;
  }

  ul {
    @include mixins.initialize(ul);
    list-style: disc;
    margin: inhouse.get-spacing-size(s) 0 inhouse.get-spacing-size(s)
      inhouse.get-spacing-size(xl);
    &.-no-margin {
      margin: 0 0 0 inhouse.get-spacing-size(xl);
    }
  }

  li {
    @include mixins.initialize(li);
  }

  li + li {
    margin-top: inhouse.get-spacing-size(xs);
  }

  a {
    @include mixins.initialize(a);
    color: inhouse.get-semantic-color(informative, 600);
  }
}

.enroll-event-group__automatic-settlement {
  margin-top: inhouse.get-spacing-size(xxl);
  .form {
    margin-top: inhouse.get-spacing-size(s);
  }
}

.enroll-event-group__automatic-settlement-title {
  @extend %heading-level-2;
}

.enroll-event-group__automatic-settlement-body {
  padding: inhouse.get-spacing-size(m);
  @include mixins.multiple-paragraph-typography($size: s, $density: normal);
  background-color: inhouse.get-primitive-color(gray, 50);
}

.enroll-event-group__terms {
  margin-top: inhouse.get-spacing-size(xxl);
}

.enroll-event-group__terms-title {
  @extend %heading-level-2;
}

.enroll-event-group__terms-body {
  padding: inhouse.get-spacing-size(m) 0;
  @include mixins.multiple-paragraph-typography($size: s, $density: normal);
}

.enroll-event-group__action {
  margin-top: inhouse.get-spacing-size(l);
}

.enroll-event-group__confirm {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inhouse.get-primitive-color(white);
  overflow-y: auto;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.--visible {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 2000;
    .modal-header {
      top: 0;
    }
  }
}

.enroll-event-group__confirm-content {
  max-width: inhouse.get-content-size(s);
  padding: inhouse.get-spacing-size(m) 0;
  margin: 0 auto;
}

.enroll-event-group__student-confirm {
  margin-top: inhouse.get-spacing-size(m);

  &.--hidden {
    display: none;
  }
}

.enroll-event-group__student-confirm-title {
  @include mixins.initialize(heading);
  font-weight: bold;
  @include inhouse.text(m, normal);

  &.--hidden {
    display: none;
  }
}

.enroll-event-group__student-confirm-body {
  dt {
    @include inhouse.text(s, normal);
    color: inhouse.get-semantic-color(neutral, 600);
  }

  dd {
    @include inhouse.text(m, normal);
    margin-left: 0;
  }

  dd + dt {
    margin-top: inhouse.get-spacing-size(m);
  }

  span {
    display: block;

    &.--hidden {
      display: none;
    }
  }
}
