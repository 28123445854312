@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.status-label {
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
  height: inhouse.get-sizing-scale-size(20);
  line-height: inhouse.get-sizing-scale-size(20);
  padding: 0 inhouse.get-spacing-size(xxs);
  color: inhouse.get-semantic-color(neutral, 700);
  background-color: inhouse.get-semantic-color(neutral, 100);
  font-size: inhouse.get-font-size(xs);
  border-radius: 2px;

  &.--disabled,
  &.--target-none {
    color: inhouse.get-primitive-color(white);
    background-color: inhouse.get-semantic-color(neutral, 600);
  }

  &.-color-positive,
  &.--done,
  &.--active {
    color: inhouse.get-semantic-color(positive, 800);
    background-color: inhouse.get-semantic-color(positive, 200);
  }

  &.--in-progress {
    color: inhouse.get-semantic-color(notice, 800);
    background-color: inhouse.get-semantic-color(notice, 200);
  }

  &.--reserve,
  &.-color-notice {
    color: inhouse.get-semantic-color(notice, 800);
    background-color: inhouse.get-semantic-color(notice, 300);
  }

  &.--over,
  &.--pending {
    color: inhouse.get-semantic-color(negative, 600);
    background-color: inhouse.get-semantic-color(negative, 100);
  }

  &.--auto {
    border: 1px solid inhouse.get-semantic-color(neutral, 700);
    background-color: inhouse.get-primitive-color(white);
  }
}
