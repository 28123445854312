@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.file-upload {
  input[type="file"] {
    opacity: 0;
    width: 0;
  }

  input[type="file"]:focus + .file-upload__button {
    .button {
      outline: none;
      box-shadow: inhouse.get-focus-ring-box-shadow();
    }
  }

  .avatar,
  .cover {
    position: relative;
  }

  .avatar::after,
  .cover::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: variables.$overlay-color;
    position: absolute;
    top: 0;
  }
}

.file-upload__item {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  @include mixins.mq-boundary-show-drawer(up) {
    flex-direction: row-reverse;
  }
}

.file-upload__button {
  position: relative;
  &.-width-full {
    width: 100%;
  }
}

.file-upload__button .button {
  position: absolute;
  top: 0;
  &.-height-full {
    height: 100%;
  }
}

.file-upload__button-group {
  display: flex;
  flex-direction: column;
  gap: inhouse.get-spacing-size(xs);
  align-items: baseline;
  @include mixins.mq-boundary-show-drawer(up) {
    flex-direction: row;
  }
}
