@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.user__cover {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: inhouse.get-spacing-size(xl) 0 inhouse.get-spacing-size(l);
}

.user__avatar {
  margin-bottom: inhouse.get-spacing-size(xs);
  ._icon {
    width: inhouse.get-sizing-scale-size(32);
    height: inhouse.get-sizing-scale-size(32);
    font-size: inhouse.get-sizing-scale-size(32);
  }
}

.user__name {
  @include mixins.initialize(h1);
  text-align: center;
  font-weight: bold;
  word-break: break-all;
  @include inhouse.text(xxl, dense);
}

.user__name-ruby {
  margin-top: inhouse.get-spacing-size(xxs);
  text-align: center;
  word-break: break-all;
  @include inhouse.text(s, dense);
}

.user__cover-action {
  position: absolute;
  top: 0;
  right: 0 - inhouse.get-spacing-size(s);
}

.user__action {
  display: flex;
  margin-top: inhouse.get-spacing-size(m);
}

.user__classroom {
  border-top: inhouse.get-border-size(m) solid
    inhouse.get-semantic-color(neutral, 300);
}

.user__data {
  padding: inhouse.get-spacing-size(m) 0;
  border-top: inhouse.get-border-size(m) solid
    inhouse.get-semantic-color(neutral, 300);
  @include inhouse.text(m, normal);

  dl {
    @include mixins.initialize(dl);
    display: flex;
    padding: 0 inhouse.get-spacing-size(m);
  }

  dl + dl {
    margin-top: inhouse.get-spacing-size(m);
  }

  dt {
    width: math.div(100%, 4);
    margin-right: inhouse.get-spacing-size(m);
    color: inhouse.get-semantic-color(neutral, 800);

    ._description {
      display: inline-block;
      @include inhouse.text(xs, dense);
    }

    &.-align-center {
      display: flex;
      align-items: center;
    }
  }

  dd {
    @include mixins.initialize(dd);
    flex: 1;
    min-width: 0;
    word-break: break-all;
    color: inhouse.get-semantic-color(neutral, 600);
    display: flex;
    align-items: flex-start;
    gap: inhouse.get-spacing-size(xxs);
  }

  // custom-fieldのddはrowで並べたいため
  > dl dd {
    flex-direction: column;
  }

  dd > * {
    color: inhouse.get-semantic-color(neutral, 800);
  }

  ul {
    @include mixins.initialize(ul);
  }

  li {
    @include mixins.initialize(li);
  }

  a {
    @include mixins.initialize(a);
    color: inhouse.get-semantic-color(informative, 600);
  }

  .user__data-courses {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .user__data-action {
    display: flex;
    flex: 1 1;
    @include inhouse.mq-boundary(down, s) {
      flex-direction: column;
    }
    .user__data-description {
      flex: auto;
    }
    .user__data-button {
      flex: none;
    }
  }

  .user__data-description {
    min-height: inhouse.get-sizing-scale-size(32);
    background-color: inhouse.get-semantic-color(neutral, 50);
    color: inhouse.get-semantic-color(neutral, 800);
    @include inhouse.text(s, dense);
    padding: 0 inhouse.get-spacing-size(s);
    margin-bottom: inhouse.get-spacing-size(xs);
    @include inhouse.mq-boundary(up, s) {
      margin-right: inhouse.get-spacing-size(xs);
      margin-bottom: 0;
    }
    &.--notice {
      background-color: inhouse.get-semantic-color(notice, 100);
    }
  }
  .user__data-description__form {
    .user__data-description__container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
    }
  }
  .user__data-description__field {
    @include inhouse.textfield-style(
      $options: (
        appearance: filled,
        size: s,
      )
    );
    width: calc(100% - 64px);
  }
  .user__data-description__submit {
    @include inhouse.button-style(
      $options: (
        color: interactive,
        size: s,
      )
    );
  }
  .user__data-description__help {
    @include mixins.initialize(p);
    @include inhouse.text(s, dense);
    margin-top: inhouse.get-spacing-size(xs);
  }
}

.user__data-list__course {
  margin: inhouse.get-spacing-size(l) 0;
  @include inhouse.interactive-list-style(
    $option: (
      density: normal,
    )
  );
  @include inhouse.mq-boundary(up, s) {
    margin: inhouse.get-spacing-size(xxl) inhouse.get-spacing-size(m);
  }
  ._list:has(._item) {
    border-bottom: 1px solid inhouse.get-semantic-color(neutral, 300);
  }
  ._item a {
    padding: 0 1.5rem;
  }
  ._item body {
    padding: 0.5rem 0;
  }
  .button__openUpdatingCourses > button {
    @include inhouse.button-style(
      $options: (
        appearance: transparent,
        color: interactive,
        size: s,
      )
    );
  }
}

.user__data-list__recurrence-bill {
  margin: inhouse.get-spacing-size(l) 0;
  @include inhouse.interactive-list-style(
          $option: (
                  density: normal,
          )
  );
  @include inhouse.mq-boundary(up, s) {
    margin: inhouse.get-spacing-size(xxl) inhouse.get-spacing-size(m);
  }
  ._list:has(._item) {
    border-bottom: 1px solid inhouse.get-semantic-color(neutral, 300);
  }
  ._item a {
    padding: 0 1.5rem;
  }
  ._item body {
    padding: 0.5rem 0;
  }
  .button__openUpdatingRecurrenceBills > button {
    @include inhouse.button-style(
            $options: (
                    appearance: transparent,
                    color: interactive,
                    size: s,
            )
    );
  }
}

.user__data-table {
  @include inhouse.interactive-table-style(
    $option: (
      density: dense,
    )
  );
  margin-top: inhouse.get-spacing-size(m);

  th,
  td {
    @include inhouse.text(s, dense);
    padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(s);
    &.-overflow-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: inhouse.get-sizing-scale-size(216);
    }
  }

  .-width-third {
    width: calc(100% / 3);
  }
}

.user__data-table__wrapper {
  overflow: overlay;
}

.user__data-table__action {
  display: flex;
  justify-content: flex-end;
}

.user__data-table__label {
  @include inhouse.text(xs, normal);
  color: inhouse.get-primitive-color(gray, 800);
  background-color: inhouse.get-primitive-color(gray, 200);
  padding: 0 inhouse.get-spacing-size(xxs);
  margin-left: inhouse.get-spacing-size(xxs);
  display: inline-block;
}

.user__data-table__loading {
  @include inhouse.skeleton-style();
  display: block;
  height: inhouse.get-interactive-component-height(xs);
}

.user__data-table__status {
  display: inline-flex;
  gap: inhouse.get-spacing-size(xxs);
  align-items: center;
  @include inhouse.text(s, normal);
  color: inhouse.get-semantic-color(neutral, 700);

  &.--positive {
    .icon {
      color: inhouse.get-semantic-color(positive, 600);
    }
  }

  &.--notice {
    .icon {
      color: inhouse.get-semantic-color(notice, 700);
    }
  }

  &.--negative {
    .icon {
      color: inhouse.get-semantic-color(negative, 600);
    }
  }

  a {
    @include mixins.initialize(a);
    display: inline-flex;
    align-items: center;
    @include mixins.interactive-overlay;
  }

  + ._date {
    @include inhouse.text(s, normal);
    color: inhouse.get-semantic-color(neutral, 600);
  }
}
