@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.call-out {
  @include inhouse.text(s, normal);
  background: inhouse.get-semantic-color(informative, 50);
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
  border-radius: inhouse.get-radius-size(m);
  @include inhouse.elevation($level: 0);
  display: flex;
  flex-direction: row;
  gap: inhouse.get-spacing-size(xs);

  .call-out__leading {
    @include inhouse.text(m, normal);
    .icon {
      vertical-align: middle;
    }
  }

  .call-out__title {
    font-weight: bold;
  }

  .call-out__description {
    a {
      @include mixins.text-link;
    }
    &.-size-xs {
      @include inhouse.text(xs, normal);
    }
  }

  ul {
    padding-left: inhouse.get-spacing-size(m);
    margin-bottom: inhouse.get-spacing-size(s);
    margin-top: 0;
  }

  .call-out__head + .call-out__body {
    margin-top: inhouse.get-spacing-size(xs);
    .call-out__description {
      @include inhouse.text(xs, normal);
    }
  }

  .call-out__action {
    margin-top: inhouse.get-spacing-size(xs);
    display: flex;
    justify-content: flex-end;
  }
}
