@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.bill-list {
  @include inhouse.interactive-list-style;

  // このコンポーネントでは上下に間隔を空けない
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._head {
    display: flex;
    justify-content: flex-end;
    padding: inhouse.get-spacing-size(s);
  }

  ._list {
    background-color: inhouse.get-primitive-color(white);

    a {
      @include mixins.initialize(a);
    }

    // α版ではスマホ版のインターフェースしか提供しないため
    // PCサイズでも両端のスペーシングはスマホサイズと揃える
    li > * {
      padding-right: inhouse.get-spacing-size(m) !important;
      padding-left: inhouse.get-spacing-size(m) !important;
    }
  }

  ._body ._icon {
    width: inhouse.get-sizing-scale-size(12);
    height: inhouse.get-sizing-scale-size(12);
    @include inhouse.text(xs, dense);
  }

  &:not(.--no-border) ._item:first-child {
    border-top: 1px solid inhouse.get-primitive-color(gray, 200);
  }

  ._item:last-child {
    border-bottom: 1px solid inhouse.get-primitive-color(gray, 200);
  }

  ._item + ._item {
    border-top: 1px solid inhouse.get-primitive-color(gray, 200);
  }

  ._item.--notice {
    background-color: inhouse.get-semantic-color(notice, 100);
  }

  ._item.--negative {
    background-color: inhouse.get-semantic-color(negative, 100);
  }
}

.bill-list__content {
  width: 100%;
}

.bill-list__detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bill-list__title {
  @include inhouse.text(m, normal);
  font-weight: bold;
  @include mixins.kerning;
  margin: inhouse.get-spacing-size(xxs) 0;
  &.--disabled {
    @include inhouse.text(s, dense);
    color: inhouse.get-semantic-color(neutral, 600);
  }
}

.bill-list__addressee {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  .addressee-list__body {
    padding: 0;
  }
  .addressee-list__name {
    @include inhouse.text(xs, dense);
  }
}

.bill-list__status {
  color: inhouse.get-semantic-color(neutral, 700);
  @include inhouse.text(xs, dense);
  @include mixins.kerning;
  margin-right: inhouse.get-spacing-size(xs);

  .--notice {
    color: inhouse.get-semantic-color(notice, 700);
  }

  .--positive {
    color: inhouse.get-semantic-color(positive, 600);
  }

  .--negative {
    color: inhouse.get-semantic-color(negative, 700);
  }
}

.bill-list__issued-at,
.bill-list__deadline {
  margin-top: inhouse.get-spacing-size(xxs);
  color: inhouse.get-semantic-color(neutral, 600);
  @include mixins.kerning;
  @include inhouse.text(xs, dense);

  > b {
    @include mixins.initialize(b);
    display: inline-block;
    white-space: nowrap;
    min-width: 3em;
    margin-right: inhouse.get-spacing-size(xs);
  }
}

.bill-list__data {
  margin-left: inhouse.get-spacing-size(xs);
  display: flex;
  align-items: flex-end;
  &.--disabled {
    color: inhouse.get-semantic-color(neutral, 500);
  }
}

.bill-list__amount {
  white-space: nowrap;
  font-weight: bold;
  @include inhouse.text(m, dense);
}

.bill-list__progress {
  display: flex;
  width: 100%;
  height: 2rem;
  align-items: center!important;
  padding: inhouse.get-spacing-size(xs);
  @include inhouse.text(xs, dense);
  background-color: inhouse.get-semantic-color(neutral, 50)!important;

  .icon {
    margin-right: 0.25rem;
    font-size: 1rem;
    line-height: 1;
  }
}

.status{
  &--paid{
    color: inhouse.get-semantic-color(positive, 800);
  }
  &--pending{
    color: inhouse.get-semantic-color(notice, 700);
  }
  &--unpaid{
    color: inhouse.get-semantic-color(negative, 700);
  }
}
