@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.subscription {
  // 月額利用料の支払い設定・支払い履歴
}

.subscription-setting {
  scroll-margin-top: variables.$header-height * 2;
}

.subscription__table {
  @include inhouse.interactive-table-style(
    $option: (
      density: dense,
    )
  );
  width: inherit;
  min-width: 100%;
  table-layout: fixed;
  background-color: inhouse.get-primitive-color(white);

  th,
  td {
    padding-right: inhouse.get-spacing-size(s);
    padding-left: inhouse.get-spacing-size(s);
    padding-top: inhouse.get-spacing-size(xs);
    padding-bottom: inhouse.get-spacing-size(xs);
    @include inhouse.text(s, dense);

    &.-whitespace-normal {
      white-space: normal;
    }
  }

  thead {
    th.-fixed {
      width: 45%;
    }
  }

  .subscription__table-status-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    ._button {
      @include inhouse.button-style(
        $options: (
          size: xs,
          appearance: transparent,
          color: interactive,
        )
      );
      margin-left: inhouse.get-spacing-size(xxs);
    }
  }

  .subscription__table-status {
    display: inline-block;
    &.-color-positive {
      color: inhouse.get-semantic-color(positive, 700);
    }
    &.-color-negative {
      color: inhouse.get-semantic-color(negative, 800);
    }
  }
}

// fincodeのクレカ入力フォームスタイル上書き
#fincode-form {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: inherit !important;
  #fincode {
    display: flex;
  }
  iframe {
    width: 100% !important;
    max-width: 400px;
    margin: 0 auto;
  }
}
