@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.ai-assistant {
  .call-out {
    margin-top: inhouse.get-spacing-size(m);
  }
  .skeleton-box {
    color: inhouse.get-primitive-color(gray, 800);
    background-color: rgba(inhouse.get-primitive-color(gray, 100), 0.3);
    @include inhouse.text(m, normal);
    margin-top: inhouse.get-spacing-size(m);
    padding: inhouse.get-spacing-size(m);
    animation-name: heartbeat;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
  }
}

@keyframes heartbeat {
  0% {
    background-color: rgba(inhouse.get-primitive-color(gray, 100), 0.3);
  }
  50% {
    background-color: rgba(inhouse.get-primitive-color(gray, 100), 1);
  }
  100% {
    background-color: rgba(inhouse.get-primitive-color(gray, 100), 0.3);
  }
}

.ai-assistant__content {
  margin-top: inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(green, 100);
  padding: inhouse.get-spacing-size(m);
  h3 {
    color: inhouse.get-primitive-color(green, 800);
    @include mixins.initialize(heading);
    font-weight: bold;
    @include inhouse.text(xs, normal);
  }
}

.ai-assistant__text {
  @include inhouse.text(s, normal);
  max-height: 12vh;
  overflow-y: auto;
}

.ai-assistant__action {
  margin-top: inhouse.get-spacing-size(m);
  display: flex;
  justify-content: center;
}

.ai-assistant__icon svg {
  fill: inhouse.get-semantic-color(informative, 600);
  width: inhouse.get-sizing-scale-size(16);
  height: inhouse.get-sizing-scale-size(16);
  vertical-align: sub;
}
