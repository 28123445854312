@use "@pepabo-inhouse/components-web" as inhouse;
@use "../../packs/styles/mixins";

.notice {
  border-radius: inhouse.get-radius-size(m);
  border: 1px dashed inhouse.get-primitive-color(gray, 400);
  display: flex;
  line-height: inhouse.get-sizing-scale-size(24);
  margin: inhouse.get-spacing-size(s) 0;
  padding: inhouse.get-spacing-size(s) 0;

  .icon {
    margin: 0 inhouse.get-spacing-size(s);
  }

  .message {
    color: inhouse.get-primitive-color(gray, 600);
    font-size: inhouse.get-font-size(s);
    padding-right: inhouse.get-spacing-size(xl);

    a {
      @include mixins.initialize(a);
      @include mixins.text-link;
    }
  }
}
