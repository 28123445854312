@use '@pepabo-inhouse/components-web' as inhouse;
@use '../variables';
@use '../functions';
@use '../mixins';

.section + .section {
  margin-top: inhouse.get-spacing-size(xxl);
}

.section > ._title {
  @include mixins.initialize(heading);
  padding-bottom: inhouse.get-spacing-size(xs);
  margin-bottom: inhouse.get-spacing-size(m);
  font-weight: bold;
  border-bottom: inhouse.get-border-size(m) solid
    inhouse.get-primitive-color(gray, 300);
  @include inhouse.text(l, dense);
}
