@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

// classroom-manager用にcomponentを分けるかこのscssの名称を変更する
.enrolled-student,
.enrolled-classroom-manager {
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-semantic-color(informative, 100);
}

.enrolled-student + .button {
  margin-top: inhouse.get-spacing-size(m);
}

.enrolled-student__label,
.enrolled-classroom-manager {
  display: block;
  text-align: center;
  color: inhouse.get-semantic-color(informative, 800);
  @include inhouse.text(s, normal);
}

.enrolled-student__list {
  @include mixins.initialize(ul);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 - math.div(inhouse.get-spacing-size(xs), 2);
}

.enrolled-student__label + .enrolled-student__list,
.enrolled-classroom-manager + .enrolled-classroom-manager__list {
  margin-top: inhouse.get-spacing-size(xs);
}

.enrolled-student__item {
  @include mixins.initialize(li);
  display: flex;
  align-items: center;
  padding: math.div(inhouse.get-spacing-size(xs), 2);
}

.enrolled-student__avatar {
  margin-right: inhouse.get-spacing-size(xxs);
  ._icon {
    width: inhouse.get-sizing-scale-size(20);
    height: inhouse.get-sizing-scale-size(20);
    font-size: inhouse.get-sizing-scale-size(20);
  }
}

.enrolled-student__name {
  max-width: 12rem;
  color: inhouse.get-semantic-color(neutral, 800);
  @include inhouse.text(s, dense);
  @include mixins.text-truncate($max-lines: 1);
}
