@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.card-list {
  $gutter: inhouse.get-spacing-size(s);

  padding: inhouse.get-spacing-size(m);

  > ._list {
    @include mixins.initialize(ul);
    display: flex;
    flex-wrap: wrap;
    margin: 0 - math.div($gutter, 2);

    > ._item {
      box-sizing: border-box;
      width: 100%;
      padding: math.div($gutter, 2);
      &.-align-center {
        text-align: center;
      }
    }
  }

  &.--column-1 > ._list > ._item {
    width: 100%;
  }

  &.--column-2 > ._list > ._item {
    width: math.div(100%, 2);
  }

  &.--column-3 > ._list > ._item {
    width: math.div(100%, 3);
  }

  &.--no-padding {
    padding: 0;
  }
}
