@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

/* .tab もroleをつけた構造に変えて統一する */
.content-tab {
  [role="tablist"] {
    display: flex;
    justify-content: center;
  }

  [role="tab"] {
    @include mixins.initialize(a);
    @include mixins.initialize(button);
    display: block;
    position: relative;
    z-index: 2;
    white-space: nowrap;
    height: variables.$tab-nav-height;
    line-height: variables.$tab-nav-height;
    padding: 0 inhouse.get-spacing-size(m);
    font-size: inhouse.get-font-size(m);
    font-weight: bold;
    color: inhouse.get-semantic-color(neutral, 600);
    cursor: pointer;
    &:focus {
      outline: 0;
      box-shadow: inhouse.get-focus-ring-box-shadow() inset;
    }
  }

  [role="tab"][aria-selected="true"] {
    color: inhouse.get-semantic-color(informative, 600);
    border-bottom: inhouse.get-border-size($level: l) solid currentColor;
  }

  [role="tab"] span.focus {
    display: inline-block;
    margin: 2px;
    padding: 4px 6px;
  }

  [role="tab"]:hover span.focus,
  [role="tab"]:focus span.focus,
  [role="tab"]:active span.focus {
    padding: 2px 4px;
    border: 2px solid rgb(36 116 214);
    border-radius: 3px;
  }
}
