@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

$gutter: inhouse.get-spacing-size(m);

.role-switch {
}

.role-switch__list {
  display: flex;
  margin: 0 - $gutter * 0.5;
}

.role-switch__item {
  box-sizing: border-box;
  width: 100% * 0.5;
  padding: $gutter * 0.5;
}

.role-switch__button {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: inhouse.get-spacing-size(m);
  text-align: center;
  font-family: inherit;
  font-weight: normal;
  background-color: inhouse.get-semantic-color(neutral, 100);
  border: 0;
  border-radius: inhouse.get-radius-size(m);
  cursor: pointer;
  appearance: none;
  @include inhouse.elevation($level: 1);
  @include mixins.interactive-overlay;

  &:focus {
    outline: none;
    box-shadow: inhouse.get-focus-ring-box-shadow();
  }
}

.role-switch__image {
  display: block;
  width: inhouse.get-sizing-scale-size(96);
  margin-bottom: inhouse.get-spacing-size(xs);
}

.role-switch__label {
  display: block;
  color: inhouse.get-semantic-color(neutral, 800);
  font-weight: bold;
  @include inhouse.text(s, dense);
  @include mixins.kerning;
}

.role-switch__addition {
  display: block;
  margin-top: inhouse.get-spacing-size(xxs);
  color: inhouse.get-semantic-color(neutral, 800);
  @include inhouse.text(s, dense);
  @include mixins.kerning;
}

.role-switch__note {
  margin-top: inhouse.get-spacing-size(m);
  text-align: center;
  @include inhouse.text(s, dense);
}
