@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.modal-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: variables.$header-height;
  background-color: inhouse.get-primitive-color(white);

  // App bar より z-index が低くなるように指定しています
  z-index: variables.$app-bar-zindex - 1;

  @include inhouse.elevation($level: 2);
  @include mixins.mq-boundary-show-drawer(up) {
    top: variables.$header-height;
    box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-primitive-color(
        gray,
        200
      )};
  }

  &.headerless {
    top: 0;
  }

  ._title {
    @include mixins.initialize(heading);
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: variables.$header-height;
    padding: 0 variables.$header-height;
    text-align: center;
    line-height: variables.$header-height;
    font-weight: bold;
    font-size: 18px;
    pointer-events: none;
    @include mixins.kerning;
    @include mixins.text-truncate($max-lines: 1);
    &.--with-count {
      display: flex;
      justify-content: center;
    }
    ._title-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  ._container {
    box-sizing: border-box;
    position: relative;
    height: variables.$bottom-nav-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: variables.$header-height;
    @include mixins.mq-boundary-show-drawer(up) {
      margin: 0 auto;
      max-width: variables.$container-max-width;
    }

    &.box-shadow {
      box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-primitive-color(
        gray,
        200
      )};
    }
  }

  ._nav {
    padding: math.div(
      variables.$header-height - inhouse.get-interactive-component-height(m),
      2
    );
  }

  ._action {
    padding: math.div(
      variables.$header-height - inhouse.get-interactive-component-height(m),
      2
    );
  }

  ._tralling{
    display: flex;
    align-items: center;
    padding: 0 inhouse.get-spacing-size(m);

    a {
      @include mixins.initialize(a);
      @include inhouse.text(m, dense);
    }
  }
}
