@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.button-group {
  @include mixins.initialize(ul);
  display: flex;
  flex-wrap: wrap;
  margin: 0 - math.div(inhouse.get-spacing-size(xs), 2);

  &.-direction-column {
    flex-direction: column;
  }

  ._item {
    padding: math.div(inhouse.get-spacing-size(xs), 2);
  }

  &.-column-2 ._item {
    box-sizing: border-box;
    width: math.div(100%, 2);
  }
}

.button-group--scalable {
  @include mixins.initialize(ul);
  display: flex;
  flex-wrap: nowrap;
  margin: 0 - math.div(inhouse.get-spacing-size(xs), 2);
  
  &.-column-2--wide ._item {
    box-sizing: border-box;
    width: 100%;
    margin:0 1em 0 0;
  }
}
