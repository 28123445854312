@use "@pepabo-inhouse/components-web" as inhouse;

.powered-by {
  align-items: center;
  display: flex;
  height: inhouse.get-sizing-scale-size(16);
  justify-content: center;
  margin-top: inhouse.get-spacing-size(m);

  span {
    color: inhouse.get-semantic-color(neutral, 600);
    font-size: 0.8rem;
  }

  img {
    height: inhouse.get-sizing-scale-size(16);
    margin-left: 0.3rem;
  }
}
