@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.custom-field {
  margin-top: inhouse.get-spacing-size(m);
}

.custom-field__item {
  dd {
    flex-direction: row;
    justify-content: space-between;
  }
  ._control {
    margin-top: inhouse.get-spacing-size(s);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: inhouse.get-spacing-size(xs);
  }
}

.custom-field__form {
  flex: 1;
}

.custom-field__collection-table {
  @include inhouse.interactive-table-style(
    $option: (
      density: normal,
    )
  );
  white-space: inherit;
  th {
    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  tr {
    &.--edit td {
      vertical-align: top;
    }
  }
  td {
    border: none;
    padding-top: inhouse.get-spacing-size(s);
    padding-bottom: inhouse.get-spacing-size(s);
    &:first-child {
      padding-left: 0;
      padding-right: inhouse.get-spacing-size(xs);
      width: 45%;
    }
    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
      padding-right: 0;
      width: inhouse.get-sizing-scale-size(48);
    }
    @include mixins.mq-boundary-show-drawer(up) {
      &:first-child {
        padding-left: inhouse.get-spacing-size(l);
        padding-right: inhouse.get-spacing-size(m);
      }
      &:last-child {
        padding-right: inhouse.get-spacing-size(l);
        width: inhouse.get-sizing-scale-size(64);
      }
    }
  }
  ._custom-field-name {
    display: block;
  }
  ._custom-field-option {
    > span + span::before {
      content: "/";
      color: inhouse.get-primitive-color(gray, 300);
      display: inline-block;
      padding: 0 inhouse.get-spacing-size(xxs);
    }
  }
  ._custom-field-type {
    color: inhouse.get-primitive-color(gray, 600);
    display: inline-block;
    @include inhouse.text(s, normal);
    padding-right: inhouse.get-spacing-size(s);
  }
  ._custom-field-text {
    @include inhouse.text(s, normal);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .icon {
      margin-right: inhouse.get-spacing-size(xxs);
    }
    &.-color-notice {
      color: inhouse.get-semantic-color(notice, 700);
      .icon {
        color: inhouse.get-semantic-color(notice, 600);
      }
    }
  }
  .-type {
    width: 176px;
    margin-right: inhouse.get-spacing-size(xs);
    &__select {
      @include inhouse.select-style(
        $options: (
          size: m,
          width: full,
        )
      );
    }
  }
  ._form {
    display: flex;
    flex-direction: column;
    gap: inhouse.get-spacing-size(xs);
  }
  ._control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: inhouse.get-spacing-size(xs);
  }
}

.custom-field__collection-action {
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(l);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: inhouse.get-spacing-size(m);
  button,
  a {
    justify-content: flex-start;
  }
}

.custom-select__list {
  display: flex;
  flex-direction: column;
  gap: inhouse.get-spacing-size(xs);
  align-items: flex-start;
}

.custom-select__item {
  display: flex;
  flex-direction: row;
}
