@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.auth {
  ._wrapper {
    padding: 0 inhouse.get-spacing-size(m);
    display: flex;
    height: 100vh;
    flex-direction: column;
  }

  ._header {
    margin: 0 (0 - inhouse.get-spacing-size(m));
    padding: inhouse.get-spacing-size(xs);
    @include mixins.mq-boundary-show-drawer(up) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
    }
    @include mixins.mq-boundary-show-gmo-header(up) {
      top: variables.$gmo-header-height;
    }
  }

  ._brand {
    height: 24px;
  }

  ._body {
    height: 100%;
    align-items: center;
    display: flex;
  }

  ._container {
    box-sizing: border-box;
    width: 100%;
    padding: inhouse.get-spacing-size(m);
    background-color: inhouse.get-primitive-color(white);
    @include inhouse.elevation($level: 2);
    margin-bottom: inhouse.get-sizing-scale-size(80);
  }

  ._media {
    width: 100%;
    height: inhouse.get-sizing-scale-size(216);
    padding: inhouse.get-spacing-size(l) 0 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  ._title {
    @include mixins.visually-hidden;
  }

  ._message {
    padding: inhouse.get-spacing-size(s);
    @include inhouse.text(m, normal);
    &.-color-positive {
      background-color: inhouse.get-semantic-color(positive, 100);
    }
    &.-color-negative {
      background-color: inhouse.get-semantic-color(negative, 100);
    }
    a {
      @include mixins.text-link;
    }
    ul {
      margin-bottom: 0;
    }
    li {
      @include inhouse.text(s, dense);
    }
  }

  ._action {
    padding: inhouse.get-spacing-size(m) 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    * + * {
      margin-top: inhouse.get-spacing-size(m);
    }
  }

  ._footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: inhouse.get-spacing-size(l) 0;
    @include mixins.mq-boundary-show-drawer(up) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  ._footer-nav {
    margin-bottom: inhouse.get-spacing-size(m);
    @include mixins.mq-boundary-show-drawer(up) {
      margin-bottom: inhouse.get-spacing-size(xs);
    }

    ul {
      @include mixins.initialize(ul);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      @include mixins.initialize(li);
    }

    a {
      @include mixins.initialize(a);
      display: block;
      padding: math.div(inhouse.get-spacing-size(s), 2)
        math.div(inhouse.get-spacing-size(m), 2);
      color: inhouse.get-semantic-color(neutral, 800);
      border-radius: inhouse.get-radius-size(m);
      @include inhouse.text(s, dense);
      @include mixins.interactive-overlay;

      &:hover {
        color: inhouse.get-semantic-color(informative, 600);
      }
    }
  }

  ._footer-pepabo {
    @include mixins.initialize(a);
    display: block;
    color: inhouse.get-semantic-color(neutral, 600);
    @include inhouse.text(s, normal);
  }
}

body.--show-gmo-header .auth ._wrapper {
  @include mixins.mq-boundary-show-gmo-header(up) {
    height: calc(100vh - #{variables.$gmo-header-height});
  }
}
