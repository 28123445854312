@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.event__name {
  display: flex;
  align-items: center;
  @include mixins.initialize(heading);
  padding: inhouse.get-spacing-size(m) 0;
  margin-bottom: inhouse.get-spacing-size(m);
}

.event__name-icon {
  padding: 0 inhouse.get-spacing-size(m) 0 0;
}

.event__name-title {
  @include inhouse.text(xl, dense);
}

.event__item-list {
  @include mixins.initialize(dl);
}

.event__item {
  display: flex;
  width: 100%;
}

.event__item-title {
  min-width: inhouse.get-sizing-scale-size(64);
  margin-right: inhouse.get-spacing-size(m);
  @include inhouse.text(m, normal);
}

.event__item-body {
  @include mixins.initialize(dd);
  flex: 1;
  min-width: 0;
  @include inhouse.text(m, normal);
  @include mixins.kerning;
  &.--multiple-paragraph {
    @include mixins.multiple-paragraph-typography($size: m, $density: normal);
  }
  a {
    @include mixins.text-link;
  }
  &.--reception-closed {
  color: inhouse.get-primitive-color(gray, 600);
  }
}

.event__action {
  a {
    @include mixins.text-link;
  }
}

.event__item-label {
  font-weight: bold;
  @include mixins.kerning;
}

.event__item + .event__item {
  margin-top: inhouse.get-spacing-size(m);
}

.event__section + .event__section {
  margin-top: inhouse.get-spacing-size(xl);
}

.event__title {
  @include mixins.initialize(heading);
  display: flex;
  align-items: center;
  margin-bottom: inhouse.get-spacing-size(m);
  font-weight: bold;
  @include inhouse.text(m, dense);
}

.event__title-trailing {
  margin-right: inhouse.get-spacing-size(xs);
  font-size: inhouse.get-sizing-scale-size(24);
}

.event__section + .event__section {
  margin-top: inhouse.get-spacing-size(l);
}

.event__participation {
  &.--seats_available {
    color: inhouse.get-semantic-color(positive, 800);
  }
  &.--seats_full {
    box-sizing: border-box;
    display: inline-block;
    white-space: nowrap;
    height: inhouse.get-sizing-scale-size(20);
    line-height: inhouse.get-sizing-scale-size(20);
    padding: 0 inhouse.get-spacing-size(xxs);
    border-radius: 2px;
    color: inhouse.get-semantic-color(positive, 800);
    background-color: inhouse.get-semantic-color(positive, 200);
  }
  a {
    @include mixins.text-link;
  }
}

.event__participation-list {
  margin-top: inhouse.get-spacing-size(s);
}

.event__delete-button {
  color: inhouse.get-primitive-color(red, 700);
}
