@use "@pepabo-inhouse/components-web" as inhouse;

$drawer-width: inhouse.get-sizing-scale-size(264);
$header-height: inhouse.get-interactive-component-height(xl);
$bottom-nav-height: inhouse.get-interactive-component-height(xl);
$tab-nav-height: inhouse.get-interactive-component-height(l);
$overlay-color: rgba(inhouse.get-primitive-color(black), 0.5);
$animation-duration: 0.2s;
$app-bar-zindex: 2000;

// GMOインターネットグループ共通ヘッダーで決められているサイズ
$gmo-header-height: 29px;
$gmo-header-breakpoint: 769px;

// コンテンツの最大幅
$container-max-width: inhouse.get-content-size(s);

// 暫定カラー
$warm-bg: #f2f2ed;
$warm-bg-light: #fafaf7;
