@use "@pepabo-inhouse/components-web" as inhouse;
@use "../mixins";

.button-circle {
  @include inhouse.button-style(
    $options: (
      size: s,
      shape: circle,
    )
  );
  color: inhouse.get-primitive-color(gray, 700);
  background-image: inherit;
  background-color: inhouse.get-primitive-color(gray, 100);
  box-shadow: inherit;
  &:hover {
    color: inhouse.get-primitive-color(gray, 800);
    background-image: inherit;
    background-color: inhouse.get-primitive-color(gray, 200);
    box-shadow: inherit;
  }
}
