@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.snackbar {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: inhouse.get-spacing-size(m);
  pointer-events: none;
  z-index: 10000;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  width: calc(100% - #{variables.$drawer-width});
  @include mixins.animation($name: fade-in-and-bottom-to-top);
  @include mixins.mq-boundary-show-drawer(down) {
    width: 100%;
  }

  &.--visible {
    visibility: visible;
    opacity: 1;
  }

  ._container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 42rem;
    margin: 0 auto;
    color: inhouse.get-primitive-color(white);
    background-color: inhouse.get-semantic-color(neutral, 900);
    border-radius: inhouse.get-radius-size(m);
    pointer-events: auto;
    @include inhouse.elevation($level: 4);
    @include inhouse.mq-boundary(up, xl) {
      margin-left: calc(
        (100% - variables.$drawer-width - variables.$container-max-width) / 2 -
          1rem
      );
    }
  }

  &.--color-error ._container {
    color: inhouse.get-primitive-color(white);
    background-color: inhouse.get-primitive-color(red, 800);
    opacity: 0.7;
  }

  &.--color-success ._container {
    color: inhouse.get-primitive-color(white);
    background-color: inhouse.get-primitive-color(green, 600);
    opacity: 0.8;
  }

  ._body {
    padding: inhouse.get-spacing-size(m);
    @include inhouse.text(s, dense);
  }

  ._trailing {
    padding: inhouse.get-spacing-size(xs);
  }
}

// ドロワーメニューを隠しているページでは
// Snackbar の位置をドロワーメニューの横幅ぶん右に寄せる調整がいらない
body.--hide-drawer .snackbar {
  width: 100%;

  ._container {
    margin-left: auto;
  }
}
