@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.setting__note {
  @include inhouse.text(s, normal);
  span {
    display: block;
  }
  + * {
    margin-top: inhouse.get-spacing-size(s);
  }
}
