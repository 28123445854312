@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.dropdown {
  position: relative;
}

.dropdown__control {
  @include mixins.initialize(button);
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: inhouse.get-radius-size(m);
  cursor: pointer;
  @include mixins.interactive-overlay(
    $border-radius: inhouse.get-radius-size(m)
  );

  &:focus {
    outline: 0;
    box-shadow: inhouse.get-focus-ring-box-shadow();
  }
}

.dropdown__control.--fixed-width {
  width: 100%;
  max-width: inhouse.get-sizing-scale-size(312);
}

.dropdown__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: inhouse.get-semantic-color(neutral, 800);
  font-size: inhouse.get-sizing-scale-size(24);
}

.dropdown__list {
  @include mixins.initialize(ul);
  position: absolute;
  top: 100%;
  left: 0;
  background-color: inhouse.get-primitive-color(white);
  z-index: 2;
  @include inhouse.elevation($level: 2);
  max-height: 50vh;
  overflow-y: auto;
  // relativeのリストはもはやdropdownメニューじゃなくて開閉するリストとして後ほど別componentにする強い気持ち
  &.--relative {
    position: relative;
    margin-top: 0 - inhouse.get-spacing-size(xs);
  }
}

.dropdown__control[aria-expanded="false"] + .dropdown__list {
  visibility: hidden;
  opacity: 0;
  &.--relative {
    display: none;
  }
}

.dropdown__control[aria-expanded="true"] + .dropdown__list {
  visibility: visible;
  opacity: 1;
  @include mixins.animation($name: fade-in-opacity, $duration: 0.1s);
}

.dropdown.--position-left .dropdown__list {
  left: 0;
  right: auto;
}

.dropdown.--position-right .dropdown__list {
  left: auto;
  right: 0;
}

.dropdown__item {
  a,
  button {
    @include mixins.initialize(a);
    @include mixins.initialize(button);
    display: block;
    width: 100%;
    white-space: nowrap;
    text-align: justify;
    padding-right: inhouse.get-spacing-size(m);
    padding-left: inhouse.get-spacing-size(m);
    cursor: pointer;
    @include inhouse.cell-style(
      (
        density: normal,
        size: m,
      )
    );
    @include inhouse.text(m, dense);
    @include mixins.interactive-overlay;

    &:focus {
      outline: none;
      box-shadow: inhouse.get-focus-ring-box-shadow() inset;
    }

    &.-increase-indent {
      padding-left: inhouse.get-spacing-size(xl);
    }
    &.-color-negative {
      color: inhouse.get-semantic-color(negative, 600);
    }
  }

  // リンクではなく Rails の button_to でボタンを表示した場合に対応する
  form {
    position: relative;
    @include mixins.interactive-overlay;

    input[type="submit"] {
      @include mixins.initialize(button);
      display: block;
      white-space: nowrap;
      padding-right: inhouse.get-spacing-size(m);
      padding-left: inhouse.get-spacing-size(m);
      color: inherit;
      font-family: inherit;
      font-weight: normal;
      cursor: pointer;
      @include inhouse.cell-style(
        (
          density: normal,
          size: m,
        )
      );
      @include inhouse.text(m, dense);

      &:focus {
        outline: none;
        box-shadow: inhouse.get-focus-ring-box-shadow() inset;
      }
    }

    input[type="hidden"] {
      @include mixins.visually-hidden;
    }
  }
  &.-has-divider {
    border-top: 1px solid inhouse.get-primitive-color(gray, 300);
  }
}

.dropdown__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  > span:first-child {
    white-space: initial;
  }
  > span:last-child {
    white-space: nowrap;
    color: inhouse.get-primitive-color(gray, 600);
  }
}
