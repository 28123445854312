@use "@pepabo-inhouse/components-web" as inhouse;

/* succeces feedback */

.toast-default {
  pointer-events: auto;
  position: relative;
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 0;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: all;
  color: inhouse.get-primitive-color(white);
  background-color: inhouse.get-primitive-color(green, 600);
  opacity: 0.8;
  list-style: none;
}


.toast-destructive {
  gap: 1rem; /* space-x-4 */
  list-style: none;
  pointer-events: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 0;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: all;
  color: inhouse.get-primitive-color(white);
  background-color: inhouse.get-primitive-color(red, 800);
  opacity: 0.7;
}


/* Swipe data attributes */
.toast-default[data-swipe="cancel"] {
  transform: translateX(0);
}

.toast-default[data-swipe="end"] {
  transform: translateX(var(--radix-toast-swipe-end-x));
}

.toast-default[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
  transition: none;
}

/* State data attributes */
.toast-default[data-state="open"] {
  /* animate-in keyframesを定義する必要があります */
}

.toast-default[data-state="closed"] {
  opacity: 0.8; /* fade-out-80 */
}

@keyframes slideOutToBottomFull {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes slideInFromTopFull {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.8;
  }
}

@keyframes slideInFromBottomFull {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.8;
  }
}

.toast-default[data-state="closed"] {
  animation: slideOutToBottomFull 0.5s forwards;
}

.toast-default[data-state="open"] {
  animation: slideInFromTopFull 0.5s forwards;
}

/* レスポンシブ対応 */
@media (min-width: 640px) {
  .toast-default[data-state="open"] {
  animation: slideInFromBottomFull 0.5s forwards;
  }
}

.viewport_element {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 0;
  right: 0;
  top: auto;
  z-index: 100;
  max-height: 100vh;
  width: 90%;
  padding: 1rem;
}

/* Small devices (640px 以上) */
@media (min-width: 640px) {
  .viewport_element {
    bottom: 0;
    right: 0;
    top: auto;
    flex-direction: column;
  }
}

/* Medium devices (768px 以上) */
@media (min-width: 768px) {
  .viewport_element {
    max-width: 80%;
  }
}

.action_button {
  display: inline-flex;
  height: 2rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border: 1px solid;
  background-color: transparent;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  --ring-offset-color: var(--background);
  transition: colors 200ms;
}

/* ホバーステート */
.action_button:hover {
  background-color: var(--secondary);
}

/* フォーカスステート */
.action_button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--ring), 0 0 0 4px var(--ring-offset-color);
}

/* 無効化ステート */
.action_button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* グループステート: "group"クラスのホバーに対する子要素特有のスタイル */
.group:hover .action_button.destructive {
  border-color: rgba(var(--muted), 0.4);
  background-color: var(--destructive);
  color: var(--destructive-foreground);
}

/* グループ内フォーカスステート */
.group:focus .action_button.destructive {
  box-shadow: 0 0 0 2px var(--destructive);
}

.close_element {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  border-radius: 0.375rem;
  padding: 0.25rem;
  color: inhouse.get-primitive-color(white) !important;
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.close_element:hover {
  color: rgba(var(--foreground), 1);
}

.close_element:focus {
  opacity: 1;
  outline: none;
  box-shadow: 0 0 0 2px var(--ring);
}

.group:hover .close_element {
  opacity: 1;
}

.group .destructive .close_element {
  color: var(--red-300);
}

.group .destructive .close_element:hover {
  color: var(--red-50);
}

.group .destructive .close_element:focus {
  box-shadow: 0 0 0 2px var(--red-400), 0 0 0 4px var(--red-600);
}

.small-text-semibold {
  font-size: 0.875rem;
  font-weight: 600;
}

.small-text-partially-transparent {
  font-size: 0.875rem;
  opacity: 0.9;
}

/*fault error feedback*/

.toast-destructive {
  gap: 1rem; /* space-x-4 */
  list-style: none;
  pointer-events: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 0;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: all;
  color: inhouse.get-primitive-color(white);
  background-color: inhouse.get-primitive-color(red, 800);
  opacity: 0.7;
}

/* Swipe transitions */
.toast-destructive[data-swipe="cancel"] {
  transform: translateX(0);
}

.toast-destructive[data-swipe="end"] {
  transform: translateX(var(--radix-toast-swipe-end-x));
}

.toast-destructive[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
  transition: none;
}

/* State-based animations */
.toast-destructive[data-state="open"] {
  /* You will need to define keyframes for animate-in */
}

.toast-destructive[data-state="closed"] {
  /* You will need to define keyframes for animate-out */
  opacity: 0.8;
}

/* Add this space-x-4 equivalent (using the 'gap' property for flex items) */
.toast-destructive > * + * {
  margin-left: 1rem;
}

/* Responsive animation when opening (for 'sm' breakpoint) */
@media (min-width: 640px) {
  .toast-destructive[data-state="open"] {
    /* You will need to define keyframes for slideInFromBottomFull for this breakpoint */
  }
}


