@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.error-banner {
  display: flex;
  padding: inhouse.get-spacing-size(m);
  color: inhouse.get-semantic-color(negative, 800);
  background-color: inhouse.get-semantic-color(negative, 100);
  @include inhouse.elevation($level: 1);

  &.-elevation-0 {
    @include inhouse.elevation($level: 0);
  }

  .error-banner__leading {
    margin-right: inhouse.get-spacing-size(m);
    color: inhouse.get-semantic-color(negative, 700);
  }

  .error-banner__icon {
    font-size: inhouse.get-sizing-scale-size(24);
  }

  .error-banner__body {
    flex: 1;
    min-width: 0;
  }

  .error-banner__title {
    font-weight: bold;
    @include inhouse.text(m, normal);
    color: inhouse.get-semantic-color(negative, 700);
  }

  .error-banner__list {
    @include mixins.initialize(ul);
    margin-top: inhouse.get-spacing-size(xs);
  }

  .error-banner__item {
    @include inhouse.text(s, dense);
  }

  .error-banner__item + .error-banner__item,
  .error-banner__action {
    margin-top: inhouse.get-spacing-size(xs);
  }
}
