@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.participation-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include mixins.initialize(a);
    padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
    background-color: inhouse.get-primitive-color(white);
    @include inhouse.elevation($level: 2);
    border-radius: inhouse.get-radius-size(m);
    @include mixins.interactive-overlay(
      $border-radius: inhouse.get-radius-size(m)
    );
    margin: inhouse.get-spacing-size(xxl) 
    inhouse.get-spacing-size(m) inhouse.get-spacing-size(m) inhouse.get-spacing-size(m);
  }

  .participation-card__body {
    display: flex;
    align-items: center;
    padding: 0 0 inhouse.get-spacing-size(m) 0;
    }
  .participation-card__name {
    margin-left: inhouse.get-spacing-size(s);
    }

.participation-card__trailing-icon {
  width: inhouse.get-avatar-width(m);
  margin-left: inhouse.get-spacing-size(s);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
  color: inhouse.get-semantic-color(neutral, 600);
  &.--no-margin {
    margin-left: 0;
  }
}

.participation-card+ .participation-card {
  margin-top: inhouse.get-spacing-size(m);
}
