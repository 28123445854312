@use '@pepabo-inhouse/components-web' as inhouse;
@use '../variables';
@use '../functions';
@use '../mixins';

@media print {
  body.print {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    :is(.header, .drawer) {
      display: none;
    }
    .app {
      padding-top: 0;
      background-color: inhouse.get-primitive-color(white);
    }
    .app__container {
      background-color: inhouse.get-primitive-color(white);
      min-height: inherit;
      :is(h1.-visibility-hidden, ._print-button) {
        display: none;
      }
    }
    .modal-content {
      min-height: inherit;
    }
    .modal-header {
      top: 0;
      box-shadow: none;
      ._nav {
        display: none;
      }
    }
  }
}
