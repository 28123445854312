@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.modal-target {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m);
  min-height: variables.$header-height;
  border-bottom: 1px solid inhouse.get-primitive-color(gray, 200);
}

.modal-target__leading {
  margin-right: inhouse.get-spacing-size(xs);
  ._icon {
    width: inhouse.get-sizing-scale-size(24);
    height: inhouse.get-sizing-scale-size(24);
    font-size: inhouse.get-sizing-scale-size(24);
  }
}

.modal-target__body {
  flex: 1;
  min-width: 0;
}

.modal-target__title {
  color: inhouse.get-primitive-color(gray, 800);
  @include inhouse.text(m, normal);
  @include mixins.text-truncate($max-lines: 1);
}

.modal-target__description {
  color: inhouse.get-primitive-color(gray, 600);
  @include inhouse.text(xs, normal);
}

.modal-target__status {
  display: block;
  white-space: nowrap;
  color: inhouse.get-primitive-color(gray, 700);
  font-weight: bold;
  @include inhouse.text(s, normal);
}
