@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.empty-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(m);
  @include inhouse.elevation($level: 1);

  ._description {
    text-align: center;
    @include inhouse.text(m, comfort);
  }

  ._note {
    margin-top: inhouse.get-spacing-size(xs);
    @include inhouse.text(s, normal);
    color: inhouse.get-primitive-color(gray, 600);
  }

  ._link {
    @include mixins.text-link;
  }

  ._action {
    margin-top: inhouse.get-spacing-size(xs);
    text-align: center;
    + ._note {
      margin-top: inhouse.get-spacing-size(m);
    }
  }

  ._info {
    @include inhouse.text(s, normal);
    margin: inhouse.get-spacing-size(m) 0;
    background-color: inhouse.get-semantic-color(neutral, 100);
    padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
    display: flex;
    flex-direction: column;
    align-items: center;
    .button {
      margin-top: inhouse.get-spacing-size(xs);
    }
  }

  ul {
    @include mixins.initialize(ul);
    list-style: disc;
    margin: inhouse.get-spacing-size(s) 0 inhouse.get-spacing-size(s)
      inhouse.get-spacing-size(l);
  }

  li {
    @include inhouse.text(s, dense);
    @include mixins.initialize(li);
  }

  li + li {
    margin-top: inhouse.get-spacing-size(xs);
  }
}
