@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.form__unit {
  display: inline-block;
  vertical-align: bottom;
  height: inhouse.get-interactive-component-height(l);
  line-height: inhouse.get-interactive-component-height(l);
  margin-left: inhouse.get-spacing-size(xs);
  font-size: inhouse.get-font-size(m);
}

.form__control.--has-radiofield {
  padding-right: inhouse.get-spacing-size(xs);
  padding-left: inhouse.get-spacing-size(xs);
}

.form__control.--has-bg {
  background-color: inhouse.get-primitive-color(gray, 50);
  padding: inhouse.get-spacing-size(m);
}

.form__control.--has-checkfield {
  padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m);
  > * {
    padding: 0;
  }
}

.form__control-select {
  padding: 0 inhouse.get-spacing-size(m);
  display: flex;
  align-items: center;
  gap: inhouse.get-spacing-size(s);
}

.form-custom > .form__item:first-child,
.form__item + .form__item,
.form__text + .form__control,
.form__control + .form__control {
  margin-top: inhouse.get-spacing-size(m);
}

.form__item {
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  legend {
    margin-bottom: inhouse.get-spacing-size(m);
    font-weight: bold;
    @include inhouse.text(s, normal);
  }
  ._require {
    color: inhouse.get-primitive-color(red, 600);
    font-weight: normal;
    margin-left: inhouse.get-sizing-scale-size(2);
    text-decoration: none;
  }
  .form__text {
    @include mixins.initialize(p);
    @include inhouse.text(m, normal);
  }
  &.over_attendeelimit {
    color: inhouse.get-semantic-color(negative, 800);
  }
}

.form__item.--hidden,
.form__control.--hidden {
  display: none;
}

.form__item.--hidden + .form__item {
  margin-top: 0;
}

.form__item-note {
  margin-bottom: inhouse.get-spacing-size(s);
  margin-left: inhouse.get-spacing-size(m);
  color: inhouse.get-semantic-color(informative, 800);
  @include inhouse.text(s, normal);
  &.--no-margin {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.form__section.--has-margin {
  margin-bottom: inhouse.get-spacing-size(m);
}

.form__section + .form__section {
  margin-top: inhouse.get-spacing-size(xl);
}

.form__section--nest {
  @include inhouse.mq-boundary(up, m) {
    margin-left: inhouse.get-spacing-size(xxl);
  }
}

.form__section--nest + .form__section--nest {
  margin-top: inhouse.get-spacing-size(xl);
}

.form__section + .form__section--nest {
  margin-top: inhouse.get-spacing-size(xl);
}

.form__section--nest + .form__section {
  margin-top: inhouse.get-spacing-size(xl);
}

.form__header {
  display: flex;
  align-items: baseline;
}

.form__button {
  margin: inhouse.get-spacing-size(m) 0;
}

.form__title {
  @include mixins.initialize(heading);
  display: flex;
  align-items: center;
  margin-bottom: inhouse.get-spacing-size(m);
  font-weight: bold;
  @include inhouse.text(m, dense);
}

.form__title-trailing {
  margin-right: inhouse.get-spacing-size(xs);
  font-size: inhouse.get-sizing-scale-size(24);
}

.form__title-text {
  margin-right: inhouse.get-spacing-size(xs);
}

.form__label {
  display: inline-block;
  margin-bottom: inhouse.get-spacing-size(xs);
  padding: 0 inhouse.get-spacing-size(m);
  font-weight: bold;
  @include inhouse.text(s, dense);
}

.form__action {
  padding-bottom: inhouse.get-spacing-size(m);
  margin: inhouse.get-spacing-size(xl) auto 0;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 50%;
  }
  ._action-note {
    margin-top: inhouse.get-spacing-size(xs);
    color: inhouse.get-semantic-color(neutral, 600);
    @include inhouse.text(s, normal);
    text-align: center;
  }
  &.-no-margin {
    margin-top: 0;
  }
}

.form__bottom-action {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m)
    inhouse.get-spacing-size(l);
  margin: inhouse.get-spacing-size(m) #{0 - inhouse.get-spacing-size($level: m)}
    #{0 - inhouse.get-spacing-size($level: m)};
  background-color: rgba(inhouse.get-primitive-color(white), 0.8);
  @include inhouse.elevation($level: 2);
  @include mixins.mq-boundary-show-drawer(up) {
    box-shadow: inset 0 #{inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-primitive-color(
        gray,
        200
      )};
  }
  .form__action {
    margin-top: inhouse.get-spacing-size(s);
    padding-bottom: 0;
  }
}

.form__divider {
  margin: inhouse.get-spacing-size(m) 0;
  color: transparent;
  background-color: inhouse.get-primitive-color(gray, 100);
  border: 0;

  &:not([size]) {
    height: inhouse.get-border-size(m);
  }
}

.form__body {
  padding: 0 inhouse.get-spacing-size(m);
}

.form__body-item + .form__body-item {
  margin-top: inhouse.get-spacing-size(xs);
}

.form .addressee-list__body {
  @include inhouse.text(s, dense);
  border-radius: inhouse.get-radius-size(m);
  background-color: inhouse.get-primitive-color(gray, 100);
  min-height: 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(xs);
}

.form__item-forward {
  display: inline-block;
  margin: 0 inhouse.get-spacing-size(xs);
  padding-top: 0.2rem;
  font-size: inhouse.get-sizing-scale-size(20);
}

.form__result {
  @include inhouse.text(m, comfort);
  margin: 0;
  dd {
    margin-left: 0;
  }
}

.form__result-item {
  padding: 0 inhouse.get-spacing-size(m);
  display: flex;
  align-items: center;
  gap: inhouse.get-spacing-size(s);
  > *:first-child {
    width: inhouse.get-sizing-scale-size(64);
  }
}

.form__error {
  padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m);
  color: inhouse.get-semantic-color(negative, 600);
  &:empty {
    padding: 0;
  }
  &.-align-center {
    text-align: center;
  }
}

// inhouse-component0.37.0以降にアップデート後、description-list-styleを当てる
.form__info {
  background-color: inhouse.get-semantic-color(attention, 100);
  padding: inhouse.get-spacing-size(s);
  + .form__section {
    margin-top: inhouse.get-spacing-size(m);
  }
  > ._list {
    margin: 0;
    padding-left: inhouse.get-spacing-size(m);
    @include inhouse.text(s, normal);
  }
  a {
    @include mixins.text-link;
  }
}
.form__info .description-list {
  padding: 0;
  > ._item {
    box-sizing: border-box;
    margin: 0;
    padding-top: inhouse.get-spacing-size(xxs);
    padding-bottom: inhouse.get-spacing-size(xxs);
    @include inhouse.text(s, normal);
    display: flex;
    gap: inhouse.get-spacing-size(m);
    border-bottom: 1px solid inhouse.get-primitive-color(gray, 300);
  }
  dt {
    @include inhouse.text(s, dense);
    width: 40%;
    font-weight: bold;
  }
  dd {
    @include inhouse.text(s, dense);
    width: 60%;
    margin-left: 0;
  }
}

.grayed-out {
  color: inhouse.get-primitive-color(gray, 300);
}
