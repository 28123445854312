@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.checkfield {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m);
  & > * {
    display: flex;
    align-items: center;
  }

  &.--layout-inside {
    padding: inhouse.get-spacing-size(xs) 0;
  }

  &.-align-center {
    justify-content: center;
  }

  ._label {
    margin-left: inhouse.get-spacing-size(xs);
    @include inhouse.text(m, dense);
  }
}
