@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

@mixin monotonize {
  filter: saturate(0);
  opacity: 0.5;
}

.event-card {
  position: relative;
  z-index: 1;
}

.event-card__link {
  @include mixins.initialize(a);
  display: block;
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(m);
  cursor: pointer;
  @include inhouse.elevation($level: 1);
  @include mixins.interactive-overlay(
    $border-radius: inhouse.get-radius-size(m)
  );
}

.event-card.--past .event-card__link {
  color: inhouse.get-primitive-color(gray, 600);
  background-color: inhouse.get-primitive-color(gray, 100);
  @include mixins.mq-boundary-show-drawer(up) {
    background-color: inhouse.get-primitive-color(gray, 50);
  }
}

.event-card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: inhouse.get-spacing-size(xs);
}

.event-card__time-section {
  display: flex;
}

.event-card__time-icon {
  margin-right: inhouse.get-spacing-size(xs);
} 

.event-card__time {
  display: flex;
  align-items: center;
  @include inhouse.text(s, dense);
  @include mixins.kerning;

  time {
    display: block;
    font-weight: bold;
  }

  time + time {
    &::before {
      content: "〜";
      display: inline-block;
      padding: 0 0.125em;
      font-weight: normal;
    }
  }
}

.event-card__publicationrange-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 25px;
  color: inhouse.get-primitive-color(white);
  font-size: inhouse.get-font-size(xs);
  vertical-align: middle;
  background-color: inhouse.get-semantic-color(neutral, 600);
  height: inhouse.get-sizing-scale-size(16);
  line-height: inhouse.get-sizing-scale-size(16);
  padding: 0.1rem 0.5rem ;
  margin: 0 0.5rem ;
}

.event-card__publicationrange-label >span.icon {
  margin-right: 0.2rem;
}

.event-card__participation {
  display: flex;
  @include inhouse.text(xs, dense);
  @include mixins.kerning;
}

.event-card__participation-label {
  display: block;
  margin-right: 0.25em;
}

.event-card__participation-numerator {
  display: block;

  &.--has-absentee {
    color: inhouse.get-semantic-color(negative, 600);
    font-weight: bold;
  }
}

.event-card.--past .event-card__participation-numerator.--has-absentee {
  color: inherit;
}

.event-card__participation-denominator {
  display: block;
}

.event-card__participation-numerator + .event-card__participation-denominator {
  &::before {
    content: "/";
    display: inline-block;
    padding: 0 0.25em;
  }
}

.event-card__body {
  display: flex;
  align-items: center;
}

.event-card__avatar {
  margin-right: inhouse.get-spacing-size(xs);
}

.event-card.--past .event-card__avatar {
  @include monotonize;
}

.event-card__title {
  @include inhouse.text(s, dense);
  @include mixins.kerning;
}

.event-card__footer {
  margin-top: inhouse.get-spacing-size(m);
}
