@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.setup-step__list {
  @include inhouse.list-style(
    $option: (
      density: dense,
    )
  );
  ul > li,
  ul > li::before {
    @include inhouse.text(s, dense);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.setup-step__title {
  font-weight: bold;
}

// 後ほど注意書き用のコンポーネントとしてbill-group__noteと統一する
.setup-step__note {
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
  margin-top: inhouse.get-spacing-size(m);
  h3 {
    @include mixins.initialize(heading);
    font-weight: bold;
  }
  em {
    font-weight: bold;
    font-style: normal;
  }
  ._head {
    @include inhouse.text(m, normal);
    display: flex;
    align-items: center;
    gap: inhouse.get-spacing-size(xxs);
  }
  ._body {
    @include inhouse.text(s, normal);
    margin-top: inhouse.get-spacing-size(xs);
  }
  &.--attention {
    background-color: inhouse.get-semantic-color(attention, 100);
    ._head {
      color: inhouse.get-semantic-color(attention, 700);
    }
  }
  // inhouse-component0.37.0以降にアップデート後、description-list-styleを当てる
  .description-list {
    padding: 0;
    > ._item {
      box-sizing: border-box;
      margin: 0;
      padding-top: inhouse.get-spacing-size(xxs);
      padding-bottom: inhouse.get-spacing-size(xxs);
      @include inhouse.text(s, normal);
      display: flex;
      gap: inhouse.get-spacing-size(m);
      border-bottom: 1px solid inhouse.get-primitive-color(gray, 300);
    }
    dt {
      @include inhouse.text(xs, dense);
      width: 30%;
      font-weight: bold;
      @include mixins.mq-boundary-show-drawer(up) {
        @include inhouse.text(s, dense);
      }
    }
    dd {
      @include inhouse.text(xs, dense);
      width: 70%;
      margin-left: 0;
      @include mixins.mq-boundary-show-drawer(up) {
        @include inhouse.text(s, dense);
      }
    }
  }
}

.setup-step__link {
  margin: inhouse.get-spacing-size(xs) 0;
}

.setup-step__action {
  margin: inhouse.get-spacing-size(xl) auto 0;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 50%;
  }
  ._action-note {
    text-align: center;
    margin-top: inhouse.get-spacing-size(xs);
    color: inhouse.get-semantic-color(neutral, 600);
    @include inhouse.text(s, normal);
  }
}

.setup-stage {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: inhouse.get-spacing-size(m);
}

.setup-stage__head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.setup-stage__emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inhouse.get-sizing-scale-size(80);
  height: inhouse.get-sizing-scale-size(80);
  margin-top: inhouse.get-spacing-size(m);
  border-radius: 100%;
  background-color: inhouse.get-semantic-color(neutral, 100);
  &.-color-positive {
    background-color: inhouse.get-semantic-color(positive, 100);
  }
  &.-color-white {
    background-color: inhouse.get-primitive-color(white);
  }
  img {
    display: block;
    width: inhouse.get-sizing-scale-size(40);
  }
  span {
    @include inhouse.text(xxxl, dense);
  }
}

.setup-stage__title {
  color: inhouse.get-semantic-color(neutral, 600);
  font-weight: bold;
  @include inhouse.text(l, normal);
  &.-color-neutral {
    color: inhouse.get-semantic-color(neutral, 900);
  }
  &.-color-positive {
    color: inhouse.get-semantic-color(neutral, 800);
  }
}

.setup-stage__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: inhouse.get-spacing-size(m);
  width: 100%;
}

.setup-stage__description {
  width: 100%;
  text-align: left;
  @include inhouse.text(m, normal);
  a {
    @include mixins.text-link;
  }
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 70%;
  }
  ._note {
    margin-top: inhouse.get-spacing-size(s);
    display: block;
    font-weight: bold;
    @include inhouse.text(xs, normal);
  }
  &.-color-accent {
    color: inhouse.get-primitive-color(gray, 900);
  }
}

.setup-stage__action {
  box-sizing: border-box;
  padding-bottom: inhouse.get-spacing-size(m);
  margin: inhouse.get-spacing-size(m) auto 0;
  width: 100%;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 50%;
  }
}

.setup-stage__action-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: inhouse.get-spacing-size(xs);
  margin: inhouse.get-spacing-size(s) auto 0;
  width: 100%;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 50%;
  }
}
