@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.bill__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: inhouse.get-spacing-size(l) inhouse.get-spacing-size(m)
    inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(m);
  @include inhouse.elevation($level: 1);
}

.bill__card + .bill__card {
  margin-top: inhouse.get-spacing-size(m);
}

.bill__deadline {
  margin-bottom: inhouse.get-spacing-size(xs);
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(m, normal);
  @include mixins.kerning;

  b {
    color: inhouse.get-semantic-color(neutral, 800);
    font-weight: bold;
  }
}

.bill__amount {
  display: flex;
  align-items: flex-end;
  font-weight: bold;

  b {
    display: block;
    white-space: nowrap;
    @include inhouse.text(xxxl, normal);
  }

  span {
    display: block;
    margin-left: inhouse.get-spacing-size(xxs);
    @include inhouse.text(xl, normal);
  }
}

.bill__addressee {
  // background-color: inhouse.get-primitive-color(blue, 100);
  // margin-top: inhouse.get-spacing-size(s);
  // width: 100%;
  .addressee-list .addressee-list__list {
    justify-content: flex-start;
  }
  .addressee-list .addressee-list__body {
    padding: 0;
  }
}

.bill__status {
  display: inline-flex;
  align-items: center;
  height: inhouse.get-interactive-component-height(s);
  padding: 0 inhouse.get-spacing-size(xs);
  margin-top: inhouse.get-spacing-size(s);
  color: inhouse.get-primitive-color(white);
  background-color: inhouse.get-semantic-color(positive, 600);
  border-radius: inhouse.get-radius-size(m);

  span {
    display: block;
    font-weight: bold;
    white-space: nowrap;
    @include inhouse.text(s, normal);
  }

  img {
    display: block;
    height: inhouse.get-font-size(m);
  }
}

.bill__title {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: inhouse.get-spacing-size(m) inhouse.get-spacing-size(s);
  margin-top: inhouse.get-spacing-size(m);
  color: inhouse.get-semantic-color(neutral, 600);
  border-width: inhouse.get-border-size(m) 0;
  border-style: solid;
  border-color: inhouse.get-semantic-color(neutral, 300);
  @include inhouse.text(s, normal);

  span {
    display: block;
  }

  & + & {
    border-top: none;
    margin-top: 0;
  }
}

.bill__action {
  width: 100%;
  padding-bottom: inhouse.get-spacing-size(m);
  margin: inhouse.get-spacing-size(l) auto 0;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 50%;
  }
  > * + * {
    margin-top: inhouse.get-spacing-size(m);
  }
  ._action-note {
    text-align: center;
    margin-top: inhouse.get-spacing-size(xs);
    color: inhouse.get-semantic-color(neutral, 600);
    @include inhouse.text(s, normal);
    a {
      @include mixins.text-link;
    }
  }
}

.bill__issued-at,
.bill__approved-at {
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(s, dense);
  @include mixins.initialize(dl);
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  & + & {
    margin-top: inhouse.get-spacing-size(xs);
  }
  dt::after {
    content: ":";
    display: inline-block;
    width: inhouse.get-sizing-scale-size(16);
    text-align: center;
  }
  dd {
    @include mixins.initialize(dd);
    @include mixins.kerning;
  }
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 50%;
  }
}

.bill__approved-at {
  dt {
    color: inhouse.get-semantic-color(positive, 700);
  }
}

.bill__issued-at-note {
  @include mixins.initialize(ul);
  @include inhouse.text(xs, dense);
  box-sizing: border-box;
  margin-top: inhouse.get-spacing-size(s);
  display: flex;
  justify-content: center;
  li {
    @include mixins.initialize(li);
    text-indent: 0 - inhouse.get-spacing-size(m);
    padding-left: inhouse.get-spacing-size(m);
  }
  li::before {
    content: "※";
    display: inline-block;
    width: inhouse.get-spacing-size(m);
    text-align: center;
    text-indent: 0;
  }
  &.--negative {
    color: inhouse.get-semantic-color(negative, 700);
    @include inhouse.text(s, dense);
  }
}

.bill__issued-at + .bill__issued-at-note {
  margin-top: inhouse.get-spacing-size(xxs);
  margin-bottom: inhouse.get-spacing-size(s);
}

.bill__issued-at + .bill__approved-at {
  margin-top: inhouse.get-spacing-size(xs);
}
