@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.radiofield {
  display: flex;
  flex-wrap: wrap;
  margin: math.div(0 - inhouse.get-spacing-size(m), 2);

  &.--layout-stack {
    margin: 0;
  }

  ._item {
    display: flex;
    align-items: center;
    padding: math.div(inhouse.get-spacing-size(m), 2);
  }

  &.--layout-stack ._item {
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    border-radius: inhouse.get-radius-size(m);
    overflow: hidden;
    @include mixins.interactive-overlay;
  }

  ._label {
    flex: 1;
    min-width: 0;
    margin-left: inhouse.get-spacing-size(xs);
    cursor: pointer;
    @include inhouse.text(m, dense);
  }
}
