@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.billgroup-report {
  @include inhouse.interactive-table-style();
}

.billgroup-report th {
  @include inhouse.text(s, normal);
  &:first-child {
    position: sticky;
    left: 0;
    border-right: 1px solid inhouse.get-primitive-color(gray, 100);
    background: inhouse.get-primitive-color(white);
  }
}

.billgroup-report td {
  @include inhouse.text(m, normal);
  &.-size-s {
    @include inhouse.text(s, normal);
  }
  &.--empty {
    background: inhouse.get-primitive-color(gray, 50);
  }
  &:first-child {
    position: sticky;
    left: 0;
    border-right: 1px solid inhouse.get-primitive-color(gray, 100);
    background: inhouse.get-primitive-color(white);
  }
  a {
    @include mixins.text-link;
  }
}

.billgroup-report th,
.billgroup-report td {
  &.-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: inhouse.get-sizing-scale-size(216);
  }
  &.-text-wrap {
    white-space: normal;
    word-break: break-all;
    padding: inhouse.get-spacing-size(xs);
  }
}

/* inhouseのtableのhover色を上書き */
.billgroup-report tbody tr:hover td {
  background: inhouse.get-primitive-color(gray, 50);
  &.--empty {
    background: inhouse.get-primitive-color(gray, 100);
  }
}

.billgroup-report__wrapper {
  overflow: overlay;
  background-color: inhouse.get-primitive-color(white);
}

.billgroup-report__header {
  box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-primitive-color(
      gray,
      200
    )};
  z-index: 1;
  background-color: inhouse.get-primitive-color(white);
  position: sticky;
  top: variables.$header-height + variables.$tab-nav-height;
  @include mixins.mq-boundary-show-drawer(up) {
    top: variables.$header-height;
  }
}

.billgroup-report__action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: inhouse.get-spacing-size(xs);
  padding: inhouse.get-spacing-size(m);
}

.billgroup-report__search {
  @include inhouse.textfield-style();
}

.billgroup-report__button {
  @include inhouse.button-style(
    $options: (
      size: s,
      appearance: transparent,
    )
  );
  @include inhouse.mq-boundary(up, s) {
    @include inhouse.button-style(
      $options: (
        size: m,
        appearance: transparent,
      )
    );
  }
}

.enrolled-event-groups__list {
  @include inhouse.interactive-list-style(
    $option: (
      density: normal,
      size: s,
    )
  );
  padding: inhouse.get-spacing-size(m);
  padding-top: 0;
  max-height: 50vh;
  overflow-y: auto;
  background-color: inhouse.get-primitive-color(gray, 50);
}

.enrolled-event-groups__list ._list {
  display: flex;
  flex-direction: column;
}

.enrolled-event-groups__list ._item > * {
  padding: 0;
}
