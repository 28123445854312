.optional-bill-list {
  ._list {
    li > label.no-left-padding {
      cursor: default;
      padding-left: 0 !important;

      &:hover {
        background-color: initial;
      }
    }
  }
}
