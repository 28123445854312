@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.text-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inhouse.get-avatar-width(m);
  height: inhouse.get-avatar-height(m);
  font-weight: bold;
  text-transform: uppercase;
  color: inhouse.get-primitive-color(white);
  background-color: inhouse.get-primitive-color(blue, 600);
  border-radius: 100%;
  user-select: none;
  @include inhouse.text(m, dense);

  &.-size-xxs {
    width: inhouse.get-sizing-scale-size(16);
    height: inhouse.get-sizing-scale-size(16);
    @include inhouse.text(xs, dense);
    font-weight: normal;
  }

  &.-size-xs {
    width: inhouse.get-avatar-width(xs);
    height: inhouse.get-avatar-height(xs);
    @include inhouse.text(xs, dense);
    font-weight: normal;
  }

  &.-size-s {
    width: inhouse.get-avatar-width(s);
    height: inhouse.get-avatar-height(s);
    @include inhouse.text(s, dense);
  }

  &.-size-m {
    width: inhouse.get-avatar-width(m);
    height: inhouse.get-avatar-height(m);
    @include inhouse.text(m, dense);
  }

  &.-size-l {
    width: inhouse.get-avatar-width(l);
    height: inhouse.get-avatar-height(l);
    @include inhouse.text(l, dense);
  }

  &.-color-0 {
    background-color: #fd7e14;
  }

  &.-color-1 {
    background-color: #fa5252;
  }

  &.-color-2 {
    background-color: #e64980;
  }

  &.-color-3 {
    background-color: #be4bdb;
  }

  &.-color-4 {
    background-color: #7950f2;
  }

  &.-color-5 {
    background-color: #4c6ef5;
  }

  &.-color-6 {
    background-color: #228be6;
  }

  &.-color-7 {
    background-color: #15aabf;
  }

  &.-color-8 {
    background-color: #12b886;
  }

  &.-color-9 {
    background-color: #40c057;
  }
}

// テキストアバターをボタンにした際に見切れるため
.button .text-avatar {
  &.-size-l {
    width: calc(inhouse.get-avatar-width(l) - 2px);
    height: calc(inhouse.get-avatar-height(l) - 2px);
  }
}
