@use "@pepabo-inhouse/components-web" as inhouse;
@use "@pepabo-inhouse/flavor/mixins" as flavor;
@use "../variables";
@use "../functions";
@use "../mixins";

@include flavor.export;
@include flavor.icon-font;

html {
  scroll-behavior: smooth;
}

body {
  @include mixins.initialize(body);
  color: inhouse.get-primitive-color(gray, 800);
  background-color: inhouse.get-primitive-color(white);
  overflow-wrap: break-word;

  &.--show-gmo-header {
    @include mixins.mq-boundary-show-gmo-header(up) {
      top: 0 !important;
    }
  }
}

img {
  max-width: 100%;
}

.app {
  padding-top: variables.$header-height;
  background-color: variables.$warm-bg;
  @include mixins.mq-boundary-show-drawer(up) {
    display: flex;
  }

  &.--show-modal-content {
    @include mixins.mq-boundary-show-drawer(down) {
      padding-top: 0;
    }
  }

  &.--show-tab-nav {
    padding-top: variables.$header-height + variables.$tab-nav-height;
    @include mixins.mq-boundary-show-drawer(up) {
      padding-top: variables.$header-height;
    }
  }
}

body.--signin-background .app {
  padding-top: 0;
}

.app__wrapper {
  @include mixins.mq-boundary-show-drawer(up) {
    flex: 1;
    min-width: 0;
  }
}

body.--signin-background .app__wrapper {
  background-position: bottom center;
  background-image: url(/images/bg_linear.svg);
  background-size: cover;
  background-color: inhouse.get-primitive-color(gray, 100);
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.app__container {
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
  background-color: variables.$warm-bg;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: variables.$container-max-width;
    min-height: calc(100vh - #{variables.$header-height});
    margin: 0 auto;
    border-width: 0 inhouse.get-border-size(m);
    border-style: solid;
    border-color: inhouse.get-primitive-color(gray, 200);
    background-color: variables.$warm-bg-light;
  }
  @include inhouse.mq-boundary(up, xl) {
    margin-left: calc(
      (100% - variables.$drawer-width - variables.$container-max-width) / 2
    );
  }
}

body.--signin-background .app__container {
  background-color: transparent;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: inhouse.get-content-size(xs);
    border-width: 0;
  }
  @include inhouse.mq-boundary(up, xl) {
    margin-left: auto;
  }
}

body.--white-background .app__container {
  background-color: inhouse.get-primitive-color(white);
}

body.--motivational-background .app__container {
  background-image: url(/images/bg.png);
  background-color: inhouse.get-primitive-color(gray, 200);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  @include mixins.mq-boundary-show-drawer(down) {
    background-attachment: fixed;
  }
  .modal-content {
    background-color: transparent;
  }
}

body.--show-gmo-header .app__container {
  @include mixins.mq-boundary-show-gmo-header(up) {
    min-height: auto;
    height: 100vh;
    padding-top: variables.$gmo-header-height;
  }
}

body.--admin-pattern-table .app__container {
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  background: none;
  border: none;
  @include inhouse.mq-boundary(up, s) {
    padding: 0 inhouse.get-spacing-size(l);
  }
}

@keyframes fade-in-opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in-and-bottom-to-top {
  from {
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes drawer-open {
  from {
    opacity: 0;
    transform: translate3d(-4rem, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Air Datepicker の UI の配色をカスタマイズしています
.datepicker {
  --adp-color: inhouse.get-primitive-color(gray, 800);
  --adp-day-name-color: inhouse.get-primitive-color(gray, 800);
  --adp-transition-duration: 0;
}

// LINE 連携の友だち追加ボタンのスタイル
.line-add-friend-button {
  display: inline-block;
  @include mixins.interactive-overlay;

  > img {
    display: block;
    height: 36px;
  }
}
