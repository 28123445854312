@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.combo-box {
  position: relative;
  .combo-box__trailing {
    position: absolute;
    right: 0;
    top: 0;
    @include inhouse.text(xxl, normal);
    display: flex;
    align-items: center;
    padding: inhouse.get-spacing-size(m) inhouse.get-spacing-size(m)
      inhouse.get-spacing-size(s);
    height: 100%;
    box-sizing: border-box;
  }
  .combo-box__trailing::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: inhouse.get-sizing-scale-size(40);
    background-color: inhouse.get-primitive-color(gray, 200);
    margin-right: inhouse.get-spacing-size(m);
  }
}

.combo-box__list {
  display: flex;
  flex-direction: column;
  background-color: inhouse.get-primitive-color(white);
  z-index: 2;
  @include inhouse.elevation($level: 2);
  max-height: 50vh;
  overflow-y: auto;
  button {
    @include mixins.initialize(button);
    @include inhouse.text(m, normal);
    color: inhouse.get-primitive-color(gray, 800);
    display: flex;
    gap: inhouse.get-spacing-size(s);
    flex-direction: row;
    justify-content: space-between;
    min-width: 100%;
    border: none;
    padding: inhouse.get-font-size(xs) inhouse.get-font-size(m);
    background-color: inhouse.get-primitive-color(white);
    text-align: left;
    cursor: pointer;
    &:hover {
      background-color: inhouse.get-primitive-color(gray, 50);
    }
    &:focus {
      outline: 0;
      background-color: inhouse.get-primitive-color(gray, 50);
      box-shadow: inhouse.get-focus-ring-box-shadow() inset;
    }
    span {
      flex-shrink: 0;
    }
  }
}
