@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.drawer {
  @include mixins.mq-boundary-show-drawer(down) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    // App bar より z-index が高くなるように指定しています
    z-index: variables.$app-bar-zindex + 1;
  }
  @include mixins.mq-boundary-show-drawer(up) {
    width: variables.$drawer-width;
  }
}

.drawer__shadow {
  @include mixins.mq-boundary-show-drawer(down) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: variables.$overlay-color;
  }
}

.drawer__content {
  @include mixins.mq-boundary-show-drawer(down) {
    position: relative;
    min-height: 100vh;
    padding-bottom: inhouse.get-spacing-size(m);
    width: 100%;
    max-width: 266px;
    background-color: inhouse.get-primitive-color(white);
    @include inhouse.elevation($level: 3);
  }
  @include mixins.mq-boundary-show-drawer(up) {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: variables.$drawer-width;
    height: 100vh;
    padding-top: variables.$header-height;
    overflow-x: auto;
    background-color: transparent;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

html.is-drawer-opened {
  @include mixins.mq-boundary-show-drawer(down) {
    body {
      overflow: hidden;
    }

    .drawer {
      visibility: visible;
      opacity: 1;
      @include mixins.animation($name: fade-in-opacity);
    }

    .drawer__content {
      pointer-events: auto;
      @include mixins.animation($name: drawer-open);
    }
  }
}

.drawer-nav {
  position: relative;
  padding-top: inhouse.get-spacing-size(xs) !important;
  padding-bottom: inhouse.get-spacing-size(xs) !important;
  background-color: transparent;
  @include inhouse.interactive-list-style;

  // このコンポーネントでは上下に間隔を空けない
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  & + & {
    border-top: 0;
  }

  & + &::after {
    content: "";
    position: absolute;
    top: 0;
    right: inhouse.get-spacing-size(m);
    left: inhouse.get-spacing-size(m);
    display: block;
    height: inhouse.get-border-size(m);
    background-color: inhouse.get-primitive-color(gray, 200);
    @include mixins.mq-boundary-show-drawer(up) {
      left: 0;
      background-color: inhouse.get-primitive-color(gray, 300);
    }
  }

  &.-density-dense {
    padding-top: inhouse.get-spacing-size(m) !important;
    padding-bottom: inhouse.get-spacing-size(m) !important;
  }

  ._list {
    // α版ではスマホ版のインターフェースしか提供しないため
    // PCサイズでも両端のスペーシングはスマホサイズと揃える
    li > a {
      padding-right: inhouse.get-spacing-size(m) !important;
      padding-left: inhouse.get-spacing-size(m) !important;
    }
  }

  ._icon {
    color: inhouse.get-semantic-color(neutral, 600);
  }

  > ._title {
    padding-right: inhouse.get-spacing-size(m) !important;
    padding-left: inhouse.get-spacing-size(m) !important;
    margin-top: 0 - inhouse.get-spacing-size(xs);
    font-weight: normal;
  }

  ._body ._title {
    font-weight: bold;
  }

  &.-density-dense ._body ._title {
    font-weight: normal;
    @include inhouse.text(s, normal);
  }

  ._item {
    > a {
      // ドロワーメニューを常に表示するときは要素の右端を丸くする
      @include mixins.mq-boundary-show-drawer(up) {
        border-radius: 0 999px 999px 0;
      }
    }

    > a:hover,
    > a:active {
      background-color: inhouse.get-semantic-color(informative, 100);
      @include mixins.mq-boundary-show-drawer(up) {
        background-color: inhouse.get-primitive-color(white);
      }

      ._icon {
        color: inhouse.get-semantic-color(informative, 600);
      }

      ._title {
        color: inhouse.get-semantic-color(informative, 700);
      }
    }

    &.--active {
      > a {
        @include mixins.mq-boundary-show-drawer(up) {
          color: inhouse.get-semantic-color(informative, 700);
          background-color: inhouse.get-primitive-color(white);
        }
      }

      ._icon {
        color: inhouse.get-semantic-color(informative, 600);
      }

      ._title {
        color: inhouse.get-semantic-color(informative, 700);
      }
    }

    &.--mq-boundary-show-drawer-up {
      @include mixins.mq-boundary-show-drawer(down) {
        display: none;
      }
    }
  }
  .drawer-nav__list {
    @include mixins.initialize(ul);
    display: flex;
    flex-direction: column;
    padding-left: inhouse.get-sizing-scale-size(56);
    margin-bottom: inhouse.get-spacing-size(xs);
    gap: 0;
  }
  .drawer-nav__item {
    a {
      display: flex;
      @include mixins.initialize(a);
      &:hover {
        color: inhouse.get-semantic-color(informative, 700);
      }
    }
    &.--active {
      a {
        color: inhouse.get-semantic-color(informative, 700);
      }
    }
  }
  .drawer-nav__body {
    padding: inhouse.get-spacing-size(xxs) 0;
  }
  .drawer-nav__title {
    @include inhouse.text(s, normal);
    font-weight: bold;
  }
}

// 今後増えたらnotification-indicatorとしてコンポーネント化
.drawer-notification__item {
  background-color: inhouse.get-semantic-color(negative, 600);
  display: inline-block;
  border-radius: inhouse.get-sizing-scale-size(4);
  width: inhouse.get-sizing-scale-size(8);
  height: inhouse.get-sizing-scale-size(8);
  vertical-align: super;
}
.drawer-notification__badge {
  background-color: inhouse.get-primitive-color(red, 600);
  color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(l);
  text-align: center;
  padding: 0 inhouse.get-spacing-size(xs);
  font-size: inhouse.get-font-size(xs);
}
