@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.banner {
  background-color: inhouse.get-primitive-color(white);
  @include inhouse.interactive-list-style;

  // このコンポーネントでは上下に間隔を空けない
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._list {
    a {
      @include mixins.initialize(a);
    }

    // α版ではスマホ版のインターフェースしか提供しないため
    // PCサイズでも両端のスペーシングはスマホサイズと揃える
    li > * {
      padding-right: inhouse.get-spacing-size(m) !important;
      padding-left: inhouse.get-spacing-size(m) !important;
    }
  }

  ._item {
    @each $name in inhouse.get-semantic-intentions() {
      &.-color-#{$name} {
        color: inhouse.get-semantic-color($name, 600);

        ._leading,
        ._title {
          color: inherit;
        }
      }
    }
  }

  &.-color-negative {
    background-color: inhouse.get-semantic-color(negative, 100);
    ._title {
      color: inhouse.get-semantic-color(negative, 700);
    }
    ._leading {
      color: inhouse.get-semantic-color(negative, 600);
    }
  }
}
