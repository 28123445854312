@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

// https://github.com/pepabo/nachiguro/blob/master/src/components/_switch.scss

$switch-size: inhouse.get-sizing-scale-size(32);
$switch-border-width: 2px;
$switch-handle-size: calc(#{$switch-size} - #{$switch-border-width} * 2);
$switch-moving-distance: calc(
  #{$switch-handle-size} - #{$switch-border-width} * 2
);

.switch__list {
  @include inhouse.interactive-list-style(
    $option: (
      density: dense,
    )
  );
}

.switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.switch i {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: calc(#{$switch-handle-size} * 2);
  height: $switch-size;
  background-color: inhouse.get-primitive-color(gray, 200);
  border-radius: $switch-size;
  border: $switch-border-width solid rgba(inhouse.get-primitive-color(gray, 600), 0.15);
  vertical-align: top;
  transition: all 0.1s ease-in-out;
  &:hover {
    border: $switch-border-width solid rgba(inhouse.get-primitive-color(gray, 600), 0.3);
  }
  &:active {
    border: $switch-border-width solid rgba(inhouse.get-primitive-color(gray, 600), 0.4);
  }
}

.switch i:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: inhouse.get-primitive-color(blue, 600);
  border-radius: $switch-size * 0.5;
  opacity: 0;
}

.switch i:after {
  content: "";
  position: absolute;
  left: 0;
  width: $switch-handle-size;
  height: $switch-handle-size;
  background-color: inhouse.get-primitive-color(white);
  border-radius: calc(#{$switch-handle-size} / 2);
  transform: translate3d(0, 0, 0);
  transition: all 0.1s ease-in-out;
}

.switch input {
  opacity: 0;
}

.switch input:focus + i {
  border: $switch-border-width solid inhouse.get-primitive-color(blue, 600);
}

.switch input:checked + i {
  background-color: inhouse.get-primitive-color(blue, 600);
}

.switch input:checked + i:before {
  animation-name: switch-fade-in;
}

.switch input:checked + i:after {
  transform: translate3d($switch-moving-distance, 0, 0);
}

.switch input:disabled + i {
  cursor: not-allowed;
  background-color: inhouse.get-primitive-color(gray, 50);
}

.switch input:disabled + i:before {
  border-color: inhouse.get-primitive-color(gray, 200);
}

.switch input:disabled:checked + i {
  background-color: inhouse.get-primitive-color(blue, 100);
}

@keyframes switch-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
