@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.fab-button {
  position: relative;

  // FABボタンと要素の間隔の大きさぶん余白を空けておく
  height: inhouse.get-interactive-component-height(l) +
    inhouse.get-spacing-size(m);
}

.fab-button__wrapper {
  position: fixed;
  bottom: variables.$bottom-nav-height;
  left: 0;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  pointer-events: none;
  z-index: 100;
  @include mixins.mq-boundary-show-drawer(up) {
    bottom: inhouse.get-spacing-size(m);
    display: flex;
    justify-content: center;
    padding-left: math.div(variables.$drawer-width, 2);
  }
  @include inhouse.mq-boundary(up, xl) {
    padding-left: 0;
  }
}

.fab-button__container {
  display: flex;
  justify-content: flex-end;
  padding: inhouse.get-spacing-size(m);
  @include mixins.mq-boundary-show-drawer(up) {
    box-sizing: border-box;
    width: variables.$container-max-width;
  }
}

.fab-button__action {
  @include inhouse.button-style(
    $options: (
      appearance: flat,
      brightness: light,
      color: interactive,
      shape: circle,
      size: l,
      width: auto,
    )
  );
  pointer-events: auto;
  box-shadow: inhouse.get-elevation-box-shadow($level: 4) !important;
}
