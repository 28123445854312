@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.thumbnail-card {
  position: relative;
  ._button {
    @include inhouse.button-style(
      $options: (
        size: s,
        shape: circle,
      )
    );
    position: absolute;
    top: 0 - inhouse.get-spacing-size(s);
    right: 0 - inhouse.get-spacing-size(s);
    z-index: 1;
  }
  > .thumbnail-card__link {
    @include mixins.initialize(a);
    cursor: pointer;
    @include mixins.interactive-overlay(
      $border-radius: inhouse.get-radius-size(m)
    );
  }
  > *:not(._button) {
    display: block;
    background-color: inhouse.get-primitive-color(white);
    border-radius: inhouse.get-radius-size(m);
    overflow: hidden;
    @include inhouse.elevation($level: 1);
  }
  .thumbnail-card__media {
    width: 100%;
    height: inhouse.get-sizing-scale-size(192);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .thumbnail-card__file {
    display: flex;
    flex-direction: column;
    gap: inhouse.get-spacing-size(xxs);
    color: inhouse.get-primitive-color(gray, 800);
    padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m);
    > ._body {
      flex: 1 1;
      @include mixins.text-truncate($max-lines: 2);
    }
    > ._trailing {
      display: flex;
      align-items: center;
      @include inhouse.text(s, normal);
      gap: inhouse.get-spacing-size(xxs);
    }
  }
}
