@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.addressee-list {
  a {
    // 背景グレーのhoverは暫定interactive100
    @include mixins.initialize(a);
    display: block;
    border-radius: inhouse.get-radius-size(m);
    background-color: inhouse.get-primitive-color(gray, 100);
    &:hover {
      background-color: inhouse.get-semantic-color(informative, 100);
    }
  }

  // components化 -> button-circle
  .addressee-list-button {
    @include inhouse.button-style(
      $options: (
        size: s,
        shape: circle,
      )
    );
    color: inhouse.get-primitive-color(gray, 700);
    background-image: inherit;
    background-color: inhouse.get-primitive-color(gray, 100);
    box-shadow: inherit;
    &:hover {
      color: inhouse.get-primitive-color(gray, 800);
      background-image: inherit;
      background-color: inhouse.get-primitive-color(gray, 200);
      box-shadow: inherit;
    }
    &.-show-mobile {
      display: inline-block;
      @include mixins.mq-boundary-show-drawer(up) {
        display: none;
      }
    }
    &.-show-desktop {
      display: none;
      @include mixins.mq-boundary-show-drawer(up) {
        display: inline-block;
      }
    }
  }

  .addressee-list-button_body {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }

  .addressee-list-button_container {
    display: flex;
    align-items: baseline;
    gap: inhouse.get-spacing-size(xxs);
  }

  .addressee-list-avatar-button {
    @include inhouse.button-style(
      $options: (
        appearance: transparent,
        size: s,
      )
    );
    cursor: pointer;
    @include mixins.interactive-overlay(
      $border-radius: inhouse.get-radius-size(m)
    );
    &.-show-mobile {
      display: inline-block;
      @include mixins.mq-boundary-show-drawer(up) {
        display: none;
      }
    }
    &.-show-desktop {
      display: none;
      @include mixins.mq-boundary-show-drawer(up) {
        display: inline-block;
      }
    }
    // buttonコンポーネントに付いている左右のmarginを打ち消す
    > *:first-child {
      margin-right: 0;
    }
    > *:last-child {
      margin-left: 0;
    }
  }

  .addressee-list-avatar-button_body {
    display: flex;
    align-items: baseline;
    .text-avatar + .text-avatar {
      margin-left: -3px;
      box-shadow: 0 0 0px 1px inhouse.get-primitive-color(white);
    }
    ._body {
      color: inhouse.get-semantic-color(informative, 600);
    }
  }

  .addressee-list__list {
    @include mixins.initialize(ul);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: math.div(inhouse.get-spacing-size(xs), 2);
    &.--align-left {
      justify-content: flex-start;
    }
    &.-size-s {
      @include inhouse.text(s, dense);
    }
  }

  .addressee-list__item {
    @include mixins.initialize(li);
  }

  .addressee-list__body {
    display: flex;
    align-items: center;
    padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(xs);
    b {
      white-space: nowrap;
      color: inhouse.get-semantic-color(informative, 700);
    }
  }

  .addressee-list__avatar {
    margin-right: inhouse.get-spacing-size(xxs);
    ._icon {
      line-height: 1;
    }
  }

  .addressee-list__name {
    color: inhouse.get-semantic-color(neutral, 800);
    @include inhouse.text(s, dense);
    @include mixins.text-truncate($max-lines: 1);
  }

  .addressee-list__modal {
    background-color: inhouse.get-primitive-color(white);
    @include inhouse.interactive-list-style();
    &:hidden {
      display: none;
    }
    // このコンポーネントでは上下に間隔を空けない
    ul {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    ._list {
      @include mixins.mq-boundary-show-drawer(up) {
        margin: 0 auto;
        max-width: variables.$container-max-width;
      }
    }
    ._list.--no-side-margin {
      margin-right: 0 - inhouse.get-spacing-size(m);
      margin-left: 0 - inhouse.get-spacing-size(m);
    }
    ._list li:nth-child(even) {
      background-color: inhouse.get-primitive-color(gray, 50);
    }
    ._item > * {
      align-items: center;
    }

    // 「宛先」の一覧など、全画面いっぱいに広がるコンテンツのなかでは
    // .modal-header は画面上部にくっつける
    > .modal-header {
      @include mixins.mq-boundary-show-drawer(up) {
        top: 0;
        display: none;
      }
    }
  }

  > ._container {
    @include mixins.mq-boundary-show-drawer(down) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: inhouse.get-primitive-color(white);
      overflow-y: auto;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;

      // App bar より z-index が高くなるように指定しています
      z-index: variables.$app-bar-zindex + 1;
    }

    &.--visible {
      @include mixins.mq-boundary-show-drawer(down) {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
