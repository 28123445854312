@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.payment__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: inhouse.get-spacing-size(xs);
  margin-bottom: inhouse.get-spacing-size(m);
  border-bottom: inhouse.get-border-size(m) solid
    inhouse.get-primitive-color(gray, 300);
}

.payment__title {
  @include mixins.initialize(heading);
  font-weight: bold;
  @include mixins.mq-boundary-show-drawer(up) {
    @include inhouse.text(l, dense);
  }
}

.payment__item + .payment__item {
  margin-top: inhouse.get-spacing-size(s);
}

.payment__brand-list {
  @include mixins.initialize(ul);
  display: flex;
}

.payment__brand-item {
  @include mixins.initialize(li);

  img {
    display: block;
    height: inhouse.get-sizing-scale-size(32);
  }
}

.payment__body {
  ul {
    @include mixins.initialize(ul);
    list-style: disc;
    margin: inhouse.get-spacing-size(s) 0 inhouse.get-spacing-size(s)
      inhouse.get-spacing-size(xl);
  }
  li {
    @include mixins.initialize(li);
  }
  li + li {
    margin-top: inhouse.get-spacing-size(xs);
  }
  table {
    @include inhouse.table-style(
      $option: (
        density: normal,
      )
    );
    width: 100%;
    margin-bottom: inhouse.get-spacing-size(m);
    margin-top: inhouse.get-spacing-size(xs);
  }
  @include inhouse.mq-boundary(down, s) {
    @include inhouse.table-style(
      $option: (
        density: dense,
      )
    );
    table td,
    table th {
      @include inhouse.text(s, dense);
      padding-right: inhouse.get-spacing-size(xs);
      padding-left: inhouse.get-spacing-size(xs);
    }
    tbody span {
      display: block;
      white-space: nowrap;
    }
  }
}

.payment__form-row {
  display: flex;
  width: 100%;
  &.--direction-column {
    flex-direction: column;
  }
}

.payment__form-column {
  box-sizing: border-box;
  width: 100%;
  padding: math.div(inhouse.get-spacing-size(m), 2);
}

.payment__form-row.--column-2 .payment__form-column {
  width: math.div(100%, 2);
}

.payment__checkbox-label {
  display: flex;
  justify-content: center;
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 50);
  > *:first-child {
    margin-right: inhouse.get-spacing-size(s);
  }
  &:hover {
    background-color: inhouse.get-primitive-color(gray, 100);
    cursor: pointer;
  }
}

.payment__form-note {
  margin-top: inhouse.get-spacing-size(s);
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(s, normal);
  @include mixins.kerning;
  display: flex;
  justify-content: center;
}

.payment__action {
  padding-bottom: inhouse.get-spacing-size(m);
  margin: inhouse.get-spacing-size(xl) auto 0;
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 50%;
  }
}

.stripe-card-element {
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-field-surface-color(
    $color: neutral,
    $state: enabled
  );
  box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
      $color: neutral,
      $state: enabled
    )};

  &:hover {
    background-color: inhouse.get-field-surface-color(
      $color: neutral,
      $state: hover
    );
  }

  &.focused {
    background-color: inhouse.get-field-surface-color(
      $color: neutral,
      $state: focused
    );
    box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
          $color: neutral,
          $state: focused
        )},
      #{inhouse.get-focus-ring-box-shadow(
          $color:
            inhouse.get-field-border-color(
              $color: neutral,
              $state: focused
            )
        )};
  }
}

.stripe-error-element {
  padding: 0;
  text-align: center;
  color: inhouse.get-semantic-color(negative, 600);
  @include inhouse.text(s, normal);
}
