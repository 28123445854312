@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.bill-item-template {
  @include inhouse.interactive-table-style(
    $option: (
      density: dense,
    )
  );

  td:not(._message) {
    border: none;
    padding-top: inhouse.get-spacing-size(s);
    padding-bottom: inhouse.get-spacing-size(s);
    &.-width-short {
      width: inhouse.get-sizing-scale-size(96);
    }
    &:first-child {
      padding-left: 0;
      padding-right: inhouse.get-spacing-size(xs);
    }
    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
      padding-right: 0;
      width: inhouse.get-sizing-scale-size(48);
    }
    @include mixins.mq-boundary-show-drawer(up) {
      &:first-child {
        padding-left: inhouse.get-spacing-size(l);
        padding-right: inhouse.get-spacing-size(m);
      }
      &:last-child {
        padding-right: inhouse.get-spacing-size(l);
        width: inhouse.get-sizing-scale-size(64);
      }
    }
  }
  td._message {
    padding-bottom: inhouse.get-spacing-size(s);
    &:empty {
      padding: 0;
    }
    ._text {
      text-align: center;
      @include inhouse.text(s, normal);
      color: inhouse.get-semantic-color(negative, 700);
    }
  }

  input {
    &.-align-end {
      text-align: right;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.bill-item-template__action {
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(l);
  button {
    justify-content: flex-start;
  }
}
