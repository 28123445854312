@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.announcement-card {
  height: 100%;

  a {
    @include mixins.initialize(a);
    display: block;
    padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
    background-color: inhouse.get-primitive-color(white);
    @include inhouse.elevation($level: 1);
    border-radius: inhouse.get-radius-size(m);
    @include mixins.interactive-overlay(
      $border-radius: inhouse.get-radius-size(m)
    );
  }

  ._head {
    display: flex;
    gap: inhouse.get-spacing-size(xs);
    align-items: center;
    width: 100%;
  }

  ._avatar {
    width: inhouse.get-avatar-width(m);
    margin-right: inhouse.get-spacing-size(s);
  }

  ._meta {
    flex: 1;
    min-width: 0;
  }

  ._author {
    @include inhouse.text(xs, dense);
  }

  ._data {
    display: flex;
    gap: inhouse.get-spacing-size(xxs);
  }

  ._datetime {
    display: flex;
    color: inhouse.get-semantic-color(informative, 600);
    @include inhouse.text(s, dense);
  }

  ._date {
    @include mixins.initialize(em);
    display: block;
    font-weight: bold;
    @include mixins.kerning;
  }

  ._time {
    display: block;
    margin-left: 0.25em;
  }

  ._addressee {
    @include inhouse.text(xs, normal);
    background-color: inhouse.get-semantic-color(positive, 200);
    color: inhouse.get-primitive-color(gray, 800);
    padding: 0 inhouse.get-spacing-size(xxs);
    white-space: nowrap;
  }

  ._title {
    font-weight: bold;
    @include inhouse.text(m, normal);
  }

  ._description {
    @include inhouse.text(xs, normal);
    @include mixins.text-truncate($max-lines: 3);
    flex: 1 1;
  }

  ._title + ._group {
    margin-top: inhouse.get-spacing-size(xs);
    display: flex;
  }

  ._body {
    margin-top: inhouse.get-spacing-size(s);
  }
}

.announcement-card.-mode-organization {
  a {
    background-color: inhouse.get-primitive-color(white);
    @include inhouse.elevation($level: 1);
    padding: inhouse.get-spacing-size(m);
  }
  ._head {
    align-items: flex-start;
    @include mixins.mq-boundary-show-drawer(down) {
      flex-direction: column;
    }
  }
  ._title {
    flex: 1 1;
    @include mixins.text-truncate($max-lines: 2);
  }
  ._data {
    justify-content: flex-end;
    align-items: flex-end;
  }
  ._status {
    @include inhouse.text(xs, normal);
    color: inhouse.get-primitive-color(gray, 800);
    background-color: inhouse.get-primitive-color(gray, 200);
    padding: 0 inhouse.get-spacing-size(xxs);
    white-space: nowrap;
  }
  ._date {
    color: inhouse.get-primitive-color(green, 700);
    &.-is-scheduled {
      color: inhouse.get-semantic-color(neutral, 700);
      font-weight: normal;
      white-space: nowrap;
    }
  }
  ._body {
    display: flex;
  }
  ._description {
    @include inhouse.text(xs, normal);
    @include mixins.text-truncate($max-lines: 3);
    flex: 1 1;
  }
}

.announcement-card__add-new-button {
  display: flex;
  align-items: center;
  width: 100%;
}

.announcement-card__add-new-label {
  flex: 1;
  min-width: 0;
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(xs, dense);
}

.announcement-card__trailing-icon {
  width: inhouse.get-avatar-width(m);
  margin-left: inhouse.get-spacing-size(s);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
  color: inhouse.get-semantic-color(neutral, 600);
  &.--no-margin {
    margin-left: 0;
  }
}
