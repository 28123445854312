@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.classroom-card {
  position: relative;
  height: 100%;
  min-height: inhouse.get-sizing-scale-size(216);

  ._link {
    @include mixins.initialize(a);
    display: block;
    height: 100%;
    background-color: inhouse.get-primitive-color(white);
    border-radius: inhouse.get-radius-size(m);
    cursor: pointer;
    overflow: hidden;
    @include inhouse.elevation($level: 1);
    @include mixins.interactive-overlay(
      $border-radius: inhouse.get-radius-size(m)
    );
  }

  ._media {
    width: 100%;
    height: inhouse.get-sizing-scale-size(128);
    overflow: hidden;
    position: relative;
    @include inhouse.mq-boundary(up, m) {
      height: inhouse.get-sizing-scale-size(160);
    }
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: currentColor;
      border-radius: inhouse.get-radius-size(m) inhouse.get-radius-size(m) 0 0;
    }
  }

  ._action {
    position: absolute;
    top: inhouse.get-spacing-size(s);
    right: inhouse.get-spacing-size(s);
  }

  ._body {
    $avatar-size: 3.5rem;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0 - math.div($avatar-size, 2);
    padding: 0 inhouse.get-spacing-size(m) inhouse.get-spacing-size(l);
  }

  ._title {
    font-weight: bold;
    @include inhouse.text(m, dense);
  }

  ._description {
    margin-top: inhouse.get-spacing-size(xxs);
    @include inhouse.text(s, dense);
    color: inhouse.get-primitive-color(gray, 700);
  }

  ._item {
    margin-top: inhouse.get-spacing-size(xs);
    ul {
      padding-left: 0;
      @include inhouse.text(s, normal);
    }
  }

  ._avatar + ._title {
    margin-top: inhouse.get-spacing-size(xs);
  }

  ._option {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.classroom-card__label {
  display: inline-block;
  white-space: nowrap;
  height: inhouse.get-sizing-scale-size(16);
  line-height: inhouse.get-sizing-scale-size(16);
  padding: 0 inhouse.get-spacing-size(xxs);
  margin-top: inhouse.get-spacing-size(xs);
  font-size: inhouse.get-font-size(xs);
  font-weight: normal;
  text-align: center;
  &.--waiting {
    background-color: inhouse.get-semantic-color(informative, 100);
    color: inhouse.get-primitive-color(blue, 800);
  }
}

.classroom-card__add-new-button {
  height: 100%;

  ._link {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: inhouse.get-spacing-size(m);
    background-color: inhouse.get-primitive-color(white);
  }

  ._icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inhouse.get-sizing-scale-size(56);
    height: inhouse.get-sizing-scale-size(56);
    color: inhouse.get-primitive-color(white);
    background-color: inhouse.get-semantic-color(informative, 600);
    font-size: 24px;
    border-radius: 100%;
  }

  &.--appearance-gray ._icon {
    color: inhouse.get-semantic-color(neutral, 600);
    background-color: inhouse.get-semantic-color(neutral, 100);
  }

  ._label {
    margin-top: inhouse.get-spacing-size(s);
    color: inhouse.get-semantic-color(informative, 600);
    @include inhouse.text(m, dense);
  }

  &.--appearance-gray ._label {
    color: inhouse.get-semantic-color(neutral, 600);
  }
}
