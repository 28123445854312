@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.dialog-v2 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: variables.$app-bar-zindex + 1;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.--visible {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

.dialog-v2__shadow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: variables.$overlay-color;
}

.dialog-v2__wrapper {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: inhouse.get-sizing-scale-size(96) inhouse.get-spacing-size(m)
    inhouse.get-spacing-size(m);
  pointer-events: none;
  @include mixins.mq-boundary-show-drawer(up) {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: inhouse.get-spacing-size(m);
  }
}

.dialog-v2__container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: inhouse.get-content-size(xs);
  padding: inhouse.get-spacing-size(m);
  margin: 0 auto;
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(l);
  pointer-events: auto;
  @include inhouse.elevation($level: 3);
  &.--align-center {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.dialog-v2__title {
  color: inhouse.get-primitive-color(gray, 800);
  font-weight: bold;
  @include inhouse.text(l, dense);
}

.dialog-v2__title + .dialog-v2__description {
  margin-top: inhouse.get-spacing-size(xs);
  @include inhouse.text(m, normal);
}

.dialog-v2__header {
  position: relative;
  width: 100%;
  .dialog-v2__title {
    text-align: center;
  }
  ._switch {
    $height-height: 3.25rem;
    $switch-size: inhouse.get-interactive-component-height(m);

    position: absolute;
    top: ($height-height - $switch-size) * 0.5;
    right: ($height-height - $switch-size) * 0.5;
  }
}

.dialog-v2__link {
  text-align: center;
  margin: inhouse.get-spacing-size(s) 0 inhouse.get-spacing-size(m);
  a {
    @include mixins.text-link;
    @include inhouse.text(s, normal);
  }
}

.dialog-v2__action {
  display: flex;
  justify-content: flex-end;
  margin-top: inhouse.get-spacing-size(s);
  &.--border-top {
    padding-top: inhouse.get-spacing-size(m);
    border-top: 1px solid inhouse.get-primitive-color(gray, 300);
  }
}

.dialog-v2__container.--align-center {
  .dialog-v2__action {
    justify-content: center;
  }
}
