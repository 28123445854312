@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

$gutter: inhouse.get-spacing-size(m);

.help-modal {
}

.help-modal__text {
  margin-bottom: inhouse.get-spacing-size(xs);
  @include inhouse.text(m, normal);
}

.help-modal__item {
  box-sizing: border-box;
  width: 320px;
  margin: 0 auto;
  padding: $gutter * 0.5;
  &.-width-full {
    width: 100%;
    padding: inhouse.get-spacing-size(xs) 0;
  }
}

.help-modal__note {
  text-align: center;
  @include inhouse.text(s, dense);
  ul {
    text-align: left;
    @include inhouse.list-style(
      $option: (
        density: dense,
        list-style-type: disc,
        size: s,
      )
    );
    li {
      @include inhouse.text(s, dense);
      padding-left: inhouse.get-spacing-size(m);
      &::before {
        width: inhouse.get-sizing-scale-size(16);
      }
    }
    a {
      @include mixins.text-link;
    }
  }
}
