@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: variables.$app-bar-zindex + 1;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.--visible {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  ._shadow {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: variables.$overlay-color;
  }

  ._wrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: inhouse.get-sizing-scale-size(96) inhouse.get-spacing-size(m)
      inhouse.get-spacing-size(m);
    pointer-events: none;
    @include mixins.mq-boundary-show-drawer(up) {
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: inhouse.get-spacing-size(m);
    }
  }

  ._container {
    position: relative;
    width: 100%;
    max-width: inhouse.get-content-size(xs);
    margin: 0 auto;
    background-color: inhouse.get-primitive-color(white);
    border-radius: inhouse.get-radius-size(l);
    pointer-events: auto;
    @include inhouse.elevation($level: 3);
  }

  ._header {
    position: relative;
    padding: inhouse.get-spacing-size(m) inhouse.get-spacing-size(m)
      inhouse.get-spacing-size(s);
    text-align: center;
    box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-primitive-color(
        gray,
        300
      )};
  }

  ._title {
    font-weight: bold;
    @include inhouse.text(l, dense);
    margin: 0;
  }

  ._switch {
    $height-height: 3.25rem;
    $switch-size: inhouse.get-interactive-component-height(m);

    position: absolute;
    top: ($height-height - $switch-size) * 0.5;
    right: ($height-height - $switch-size) * 0.5;
  }

  ._body {
    padding: inhouse.get-spacing-size(m);
  }

  ._title + ._description {
    margin-top: inhouse.get-spacing-size(xs);
  }

  ._action {
    display: flex;
    justify-content: flex-end;
    margin-top: inhouse.get-spacing-size(s);
  }
}
