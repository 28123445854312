@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.under-development {
  ._media {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    ._overlay {
      width: 100%;
      height: 100%;
      background: rgba(inhouse.get-primitive-color(blue, 900), 0.9);
      position: absolute;
      top: 0;
      text-align: center;
      color: inhouse.get-primitive-color(white);
    }

    ._message {
      padding-top: 30vh;
    }
  }
}
