@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.radio {
  @include inhouse.radio-style;
}

.disabled-label {
  color: inhouse.get-primitive-color(gray, 400);
}
