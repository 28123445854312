@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.header__member-control {
  display: flex;
  align-items: center;
  padding: inhouse.get-spacing-size(m);
  @include inhouse.mq-boundary(down, s) {
    width: 100%;
    box-sizing: border-box;
  }
  @include inhouse.mq-boundary(up, s) {
    justify-content: space-between;
  }
  &__leading {
    display: flex;
    align-items: center;
    flex: none;
    .member-role__link {
      @include inhouse.button-style(
        $options: (
          color: interactive,
          size: m,
          appearance: transparent,
          width: full,
        )
      );
      &.--hover {
        @include inhouse.button-style(
          $options: (
            color: neutral,
            size: m,
            appearance: transparent,
            width: full,
          )
        );
      }
    }
  }
  &__trailing {
    display: flex;
    margin-left: inhouse.get-spacing-size(m);
  }
  .-sort {
    width: 176px;
    margin-right: inhouse.get-spacing-size(xs);
    &__select {
      @include inhouse.select-style(
        $options: (
          size: m,
          width: full,
        )
      );
    }
  }
  .-invite {
    width: 80px;
    margin-right: inhouse.get-spacing-size(xs);
    &__button {
      @include inhouse.button-style(
        $options: (
          color: interactive,
          size: m,
          appearance: outlined,
          width: full,
        )
      );
    }
  }
}

.admin-table {
  @include inhouse.mq-boundary(up, m) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: width 0.15s ease-out 0s;
    &.js-overwrap {
      // 小さめのPCで詳細ペインを展開した時に元の下層となるテーブル側の隠れてる部分を横スクロールで引き出せるようにするため、詳細ペインの幅に応じてwrapperの幅を縮める
      // inhouse.get-spacing-size(l) は body.--admin-pattern-table .app__container の padding に合わせる
      width: calc(
        100vw - variables.$drawer-width - 600px - inhouse.get-spacing-size(l)
      );
      overflow-x: scroll;
      transition: width 0.15s ease-out 0.1s;
    }
  }
}

.admin-table__collection {
  background-color: variables.$warm-bg-light;
  width: 100%;
  min-height: calc(100vh - #{variables.$header-height});
  @include inhouse.mq-boundary(down, s) {
    overflow-x: scroll;
  }
  @include inhouse.mq-boundary(up, s) {
    width: 800px;
    margin-right: calc((800px - 100%) * -1);
  }
  @include inhouse.mq-boundary(up, xl) {
    width: 1080px;
    margin-right: calc((1080px - 100%) * -1);
  }
}

.admin-table__collection-table {
  background-color: inhouse.get-primitive-color(white);
  white-space: wrap !important;
  @include inhouse.interactive-table-style(
    $option: (
      density: dense,
    )
  );
  thead th {
    position: relative;
    .button__filter-open {
      @include inhouse.button-style(
        $options: (
          appearance: transparent,
          color: neutral,
          size: m,
          shape: circle,
        )
      );
      width: 40px;
    }
  }
  tbody td {
    position: relative;
  }
  ._student-name {
    // max-width: 10rem;
    height: 4.5rem;
    @include inhouse.mq-boundary(down, m) {
      @include mixins.text-truncate($max-lines: 1);
    }
  }
  ._student-description {
    max-width: 15rem;
    @include inhouse.mq-boundary(down, m) {
      @include mixins.text-truncate($max-lines: 1);
    }
  }
  ._student-course {
    max-width: 10rem;
  }
  ._student-recurrence-bill{
    max-width: 15rem;
    @include inhouse.mq-boundary(down, m) {
      @include mixins.text-truncate($max-lines: 1);
    }
  }
  ._student-course > p {
    @include mixins.initialize(p);
    @include inhouse.text(m, normal);
    @include inhouse.mq-boundary(down, m) {
      @include mixins.text-truncate($max-lines: 1);
    }
  }

  ._clickable {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: none;
    border: none;
    cursor: pointer;
    &:focus {
      // 普通にクリックされた時はスタイル変わらずに、tabキーでfocusされた時だけスタイルを変える
      appearance: none;
      outline: none;
      &:not(:active) {
        box-shadow: inset 0 0 0 0.25rem
          rgba(inhouse.get-semantic-color(informative, 700), 0.15);
        border-radius: 0.25rem;
      }
    }
  }
  .popup {
    position: absolute;
    top: 40px;
    left: 0;
    min-width: 200px;
    padding: inhouse.get-spacing-size(m);
    background-color: inhouse.get-primitive-color(white);
    font-weight: normal;
    @include inhouse.elevation($level: 2);
    z-index: 1;
    .popup-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        @include mixins.initialize(p);
        @include inhouse.text(s, normal);
      }
    }
    .button__filter-close {
      @include inhouse.button-style(
        $options: (
          appearance: transparent,
          color: neutral,
          size: s,
          shape: circle,
        )
      );
      width: 32px;
    }
    &._description {
      left: auto;
      right: 0;
    }
    ._search {
      margin-top: inhouse.get-spacing-size(s);
      @include inhouse.textfield-style(
        $options: (
          width: full,
        )
      );
    }
    ._checkbox-list {
      @include mixins.initialize(ul);
      overflow-y: auto;
      max-width: 320px;
      max-height: 400px;
      @include inhouse.mq-boundary(up, s) {
        max-width: 480px;
      }
    }
    ._checkbox-item label {
      display: flex;
      align-items: center;
      margin-top: inhouse.get-spacing-size(xs);
      cursor: pointer;
    }
    ._checkbox-item p {
      @include mixins.initialize(p);
      @include inhouse.text(m, normal);
      @include mixins.text-truncate($max-lines: 1);
      padding-left: 6px;
    }
    ._checkbox {
      @include inhouse.checkbox-style;
    }
  }
  ._media ._thumbnail {
    width: 2.5rem;
    padding: inhouse.get-spacing-size(xs) 0;
  }
  ._media ._thumbnail img {
    border-radius: 100px;
  }
}

.admin-table__single {
  position: fixed;
  z-index: 10;
  background-color: inhouse.get-primitive-color(white);
  @include inhouse.elevation($level: 3);
  overflow-y: auto;
  top: 0;
  bottom: 0;
  transition: right 0.15s ease-out;
  overscroll-behavior-y: none;
  @include inhouse.mq-boundary(down, s) {
    right: -100vw;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    z-index: 2000; // :(
  }
  @include inhouse.mq-boundary(up, s) {
    padding-top: 6.5rem;
    right: -600px;
    width: 600px;
  }
  @include inhouse.mq-boundary(up, m) {
    padding-top: 3.5rem;
  }
  &._visible {
    right: 0;
  }
}

.single-container {
  position: relative;
}

.single-control {
  position: absolute;
  top: inhouse.get-spacing-size(xs);
  right: inhouse.get-spacing-size(xs);
  left: inhouse.get-spacing-size(xs);
  z-index: 1;
  display: flex;
  justify-content: space-between;
}
.single-control__button {
  & > button {
    @include inhouse.button-style(
      $options: (
        appearance: transparent,
        size: m,
        shape: circle,
        width: auto,
      )
    );
    width: 40px;
  }
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    margin: 0 1rem;
  }
  .icon.--close {
    @include inhouse.mq-boundary(down, s) {
      display: none;
    }
  }
  .icon.--back {
    @include inhouse.mq-boundary(up, s) {
      display: none;
    }
  }
  .dropdown__list {
    left: auto;
    right: 0;
    width: 160px;
  }
  .custom-dropdown-list {
    width: 190px;
    text-align: left;
  }
}

.member {
  padding: inhouse.get-spacing-size(m) 0;
}

.member__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 inhouse.get-spacing-size(m);
}

.member__header-title {
  @include mixins.initialize(heading);
  font-weight: bold;
  margin-bottom: inhouse.get-spacing-size(xs);
  @include inhouse.text(m, dense);
}

.member__header + .member__section,
.member__section + .member__section {
  margin-top: inhouse.get-spacing-size(l);
}

.member__section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 inhouse.get-spacing-size(m);
  margin-bottom: inhouse.get-spacing-size(s);
}

.member__section-title {
  @include mixins.initialize(heading);
  font-weight: bold;
  @include inhouse.text(l, dense);
}

.member__action {
  &.-appearance-transparent {
    margin-right: 0 - inhouse.get-spacing-size(s);
  }
}

.member-managers {
  .--attention {
    color: inhouse.get-semantic-color(attention, 500);
  }
}

// お知らせや入金でも使うようになったらsegment切り替えのcomponentsをつくる
.member__select-segment {
  width: 100%;

  ._switch {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 inhouse.get-spacing-size(m);
    color: inhouse.get-field-text-color($color: neutral, $state: enabled);
    background-color: inhouse.get-primitive-color(white);
    box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
        $color: neutral,
        $state: enabled
      )};
    border-radius: 0;
    cursor: pointer;
    @include mixins.interactive-overlay;
  }

  ._switch-label {
    display: block;
    min-width: inhouse.get-sizing-scale-size(216);
    height: inhouse.get-sizing-scale-size(64);
    font-size: inhouse.get-font-size(l);
    line-height: inhouse.get-sizing-scale-size(64);
    @include mixins.text-truncate($max-lines: 1);
  }

  ._switch-trailing {
    font-size: inhouse.get-sizing-scale-size(24);
  }

  ._list {
    width: 100%;
  }
}

// componentsまたはbaseに移動する
.member-select {
  .--un-selected.--change {
    background-color: inhouse.get-semantic-color(informative, 100);
  }
  .--pre-selected {
    background-color: inhouse.get-semantic-color(neutral, 50);
  }
  .--pre-selected.--change {
    background-color: inhouse.get-semantic-color(neutral, 100);
  }
}

.manager-list {
  background-color: variables.$warm-bg-light;
  min-height: calc(100vh - #{variables.$header-height});
  @include inhouse.mq-boundary(up, s) {
    width: 100%;
    max-width: 800px;
  }
  @include inhouse.mq-boundary(up, xl) {
    max-width: 1080px;
  }
}
