@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.page {
  padding: inhouse.get-spacing-size(m) 0 inhouse.get-spacing-size(xl);
  background-color: inhouse.get-primitive-color(white);
}

.page.-has-order {
  display: flex;
  &.-column-reverse {
    flex-direction: column-reverse;
    .-order-start {
      order: 5; /* セクション数が5だったため */
    }
    .-order-end {
      order: -1; /* reverseのため-1が一番下になる */
    }
  }
}

.page__container {
  padding: 0 inhouse.get-spacing-size(m);
}

.cover + .page__header {
  margin-top: inhouse.get-spacing-size(m);
}

.page__header + .page__section,
.page__section + .page__section {
  margin-top: inhouse.get-spacing-size(xl);
}

.page__item {
  &.--column-2 {
    > *:last-child {
      margin-top: inhouse.get-spacing-size(s);
    }
    @include inhouse.mq-boundary(up, s) {
      display: flex;
      gap: inhouse.get-spacing-size(s);
      > *:last-child {
        margin-top: 0;
      }
    }
  }
  .text-link {
    @include mixins.text-link;
  }
}

.page__page-head {
  display: flex;
  flex-wrap: wrap;
}

.page__item + .page__item,
.page__page-head + .page__item {
  margin-top: inhouse.get-spacing-size(m);
}

.page__section-head + .page__item,
.page__section-head + .page__section-body,
.page__section-body + .page__item {
  margin-top: inhouse.get-spacing-size(s);
}

.page__item.--no-side-margin {
  margin-right: 0 - inhouse.get-spacing-size(m);
  margin-left: 0 - inhouse.get-spacing-size(m);
}

.page__item-info {
  @include inhouse.text(m, normal);
  background: inhouse.get-semantic-color(informative, 100);
  padding: inhouse.get-spacing-size(m);

  &.-color-positive {
    background: inhouse.get-semantic-color(positive, 100);
  }

  &.-color-attention {
    background: inhouse.get-semantic-color(attention, 100);
  }

  .page__item-info-action {
    margin-top: inhouse.get-spacing-size(s);
  }

  .page__item-info-head + .page__item-info-body {
    margin-top: inhouse.get-spacing-size(xs);
  }
}

.page__section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: inhouse.get-spacing-size(xs);
  border-bottom: inhouse.get-border-size(m) solid
    inhouse.get-primitive-color(gray, 300);
}

.page__section-body {
  @include inhouse.text(m, normal);
}

.page__data-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.--align-left {
    justify-content: flex-start;
  }
}

.page__title {
  @include mixins.initialize(heading);

  &.--level-1 {
    font-weight: bold;
    @include inhouse.text(xl, dense);
  }

  &.--level-2 {
    font-weight: bold;
    @include inhouse.text(l, dense);
  }

  &.--level-3 {
    font-weight: bold;
    @include inhouse.text(m, dense);
  }
}

.page__note {
  display: inline-block;
  margin-left: inhouse.get-spacing-size(xs);
  font-weight: normal;
  @include inhouse.text(xs, dense);
}

.page__action {
  &.-appearance-transparent {
    margin-right: 0 - inhouse.get-spacing-size(s);
  }
}

.p__decolation {
  @include inhouse.text(m, normal);

  &.-color-positive {
    color: inhouse.get-semantic-color(positive, 700);
  }

  &.-color-attention {
    color: inhouse.get-semantic-color(attention, 700);
  }

  &.-strong {
    font-weight: 800;
  }
}

.page__info {
  margin-right: auto;
}

.page__help {
  text-align: right;
}

.page__data-body {
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(m, normal);
  @include mixins.kerning;

  &.--description {
    @include mixins.multiple-paragraph-typography($size: s, $density: normal);
  }

  ol {
    @include inhouse.text(s, dense);
  }
}

.page__data-note {
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(s, normal);
  @include mixins.kerning;

  > a {
    @include mixins.text-link;
  }
}

.page__data-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include inhouse.text(m, normal);
  > * {
    margin-right: inhouse.get-spacing-size(xs);
  }
}

.page__data-head + .page__data-body,
.page__data-body + .page__data-body,
.page__data-body + .page__data-note {
  margin-top: inhouse.get-spacing-size(s);
}

// inhouse-component0.37.0以降にアップデート後、description-list-styleを当てる
.page__description-list {
  > ._item {
    box-sizing: border-box;
    margin: 0;
    padding-top: inhouse.get-spacing-size(xxs);
    padding-bottom: inhouse.get-spacing-size(xxs);
    @include inhouse.text(s, normal);
    display: flex;
    gap: inhouse.get-spacing-size(m);
    border-bottom: 1px solid inhouse.get-primitive-color(gray, 300);
  }
  dt {
    @include inhouse.text(s, dense);
    width: 40%;
    font-weight: bold;
  }
  dd {
    @include inhouse.text(s, dense);
    width: 60%;
    margin-left: 0;
  }
}

p {
  padding: 0;
  &.--indent {
    padding: 0 0 0 inhouse.get-spacing-size(xl);
  }
  > a {
    @include mixins.text-link;
  }
}

p + p {
  margin-top: inhouse.get-spacing-size(s);
}
