@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.pagination {
  padding: inhouse.get-spacing-size(m) inhouse.get-spacing-size(s)
    inhouse.get-spacing-size(xl);

  ._wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  ._page-number {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: inhouse.get-semantic-color(neutral, 600);
    z-index: 1;
    @include inhouse.text(s, normal);
  }

  ._container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
  }
}
