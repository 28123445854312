@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.qr-code {
  ._media {
    svg {
      display: block;
    }
  }

  ._url {
    padding-bottom: inhouse.get-spacing-size(xl);
    text-align: center;
    color: inhouse.get-semantic-color(neutral, 800);
    @include inhouse.text(m, normal);
  }
}
