@use '@pepabo-inhouse/components-web' as inhouse;
@use '../variables';
@use '../functions';
@use '../mixins';

.visually-hidden {
  @include mixins.visually-hidden;
}

.visually-hidden-focusable {
  @include mixins.visually-hidden-focusable;
}
