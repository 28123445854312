@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.interactive-list {
  background-color: inhouse.get-primitive-color(white);
  @include inhouse.interactive-list-style;

  // このコンポーネントでは上下に間隔を空けない
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._list {
    // α版ではスマホ版のインターフェースしか提供しないため
    // PCサイズでも両端のスペーシングはスマホサイズと揃える
    li > * {
      padding-right: inhouse.get-spacing-size(m) !important;
      padding-left: inhouse.get-spacing-size(m) !important;
    }
  }
}
