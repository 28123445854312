@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.tab {
  overflow-y: hidden;
  overflow-x: auto;

  ._list {
    @include mixins.initialize(ul);
    display: block;
    white-space: nowrap;
    text-align: center;
    font-size: 0;
  }

  ._item {
    display: inline-block;

    a,
    span {
      @include mixins.initialize(a);
      display: block;
      white-space: nowrap;
      height: variables.$tab-nav-height;
      line-height: variables.$tab-nav-height;
      padding: 0 inhouse.get-spacing-size(m);
      font-size: inhouse.get-font-size(s);
      font-weight: bold;
      color: inhouse.get-semantic-color(neutral, 600);
    }

    a {
      @include mixins.interactive-overlay;

      &:focus {
        outline: 0;
        box-shadow: inhouse.get-focus-ring-box-shadow() inset;
      }
    }

    span {
      cursor: default;
    }

    &.--current {
      a,
      span {
        color: inhouse.get-semantic-color(informative, 600);
        box-shadow: inset 0 #{0 - inhouse.get-border-size($level: l)} 0 0 currentColor;
      }
    }
  }
  &.tab-home {
    @include mixins.mq-boundary-show-drawer(up) {
      display: none;
    }
  }
  &.tab-payouts {
    @include mixins.mq-boundary-show-drawer(up) {
      display: none;
    }
  }
  &.tab-nav-contents {
    ._list {
      display: flex;
    }
    ._item {
      flex-grow: 1;
    }
    ._item a {
      box-shadow: inset 0 #{0 - calc(inhouse.get-border-size($level: l) / 2)}
        0 0 inhouse.get-semantic-color(neutral, 200);
      @include mixins.mq-boundary-show-drawer(up) {
        font-size: inhouse.get-font-size(m);
      }
      &:focus {
        box-shadow: inhouse.get-focus-ring-box-shadow() inset;
      }
    }
    ._item.--current a {
      color: inhouse.get-semantic-color(neutral, 800);
      box-shadow: inset 0 #{0 - inhouse.get-border-size($level: l)} 0 0 inhouse.get-semantic-color(neutral, 800);
    }
  }
}
