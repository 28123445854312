@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.home {
  padding: inhouse.get-spacing-size(m) 0;
}

.home__banner {
  .banner:last-child {
    margin-bottom: inhouse.get-spacing-size(m);
  }
}

.home__section + .home__section {
  margin-top: inhouse.get-spacing-size(l);
}

.home__section-title {
  @include mixins.initialize(heading);
  padding: 0 inhouse.get-spacing-size(m);
  margin-bottom: inhouse.get-spacing-size(s);
  @include inhouse.text(l, dense);
}

.home.--mode-organization .home__section-title {
  color: inhouse.get-primitive-color(gray, 800);
}

.home.--mode-student .home__section-title {
  color: inhouse.get-primitive-color(gray, 800);
}

.home__section-body.--has-card-list {
  margin: (0 - inhouse.get-spacing-size(m)) 0;
}

.home__action {
  padding: 0 inhouse.get-spacing-size(m);
  margin-top: inhouse.get-spacing-size(s);
  text-align: right;

  a {
    @include mixins.initialize(a);
    @include inhouse.text(s, dense);
  }
}

.home.--mode-organization .home__action a {
  color: inhouse.get-primitive-color(blue, 600);
}

.home.--mode-student .home__action a {
  color: inhouse.get-primitive-color(white);
}
