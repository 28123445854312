@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.avatar {
  @include inhouse.avatar-style;
}

// アバターをボタンにした際に見切れるため
.button .avatar {
  &.-size-l {
    width: calc(inhouse.get-avatar-width(l) - 2px);
    height: calc(inhouse.get-avatar-width(l) - 2px);
  }
}
