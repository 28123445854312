@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

$nav-bar-height: inhouse.get-interactive-component-height(l);

.event-list {
  position: relative;

  // ページの最後にあっても次の予定の座標へスクロールできるようにページの下に余白を空けておく
  $spacing: 8.75rem;
  padding-bottom: calc(
    100vh - #{(
        variables.$header-height + $nav-bar-height +
          variables.$bottom-nav-height + $spacing
      )}
  );
}

.event-list__nav-bar {
  box-sizing: border-box;
  position: sticky;
  top: variables.$header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $nav-bar-height;
  padding: 0 inhouse.get-spacing-size(xxs) 0 inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(blue, 600);
  z-index: 3;
}

.event-list__classroom-filter {
  width: 50%;
}

.event-list__day {
  position: relative;

  // アンカーの座標へスクロールしてもコンテンツがヘッダーに隠れないようにしておく
  scroll-margin-top: variables.$header-height + $nav-bar-height;
}

.event-list__header {
  position: sticky;
  top: variables.$header-height;
  display: flex;
  align-items: center;
  height: inhouse.get-interactive-component-height(m);
  padding: 0 inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 100);
  z-index: 2;
}

.event-list__day-label {
  @include inhouse.text(m, normal);
  @include mixins.initialize(heading);
  @include mixins.kerning;
  display: inline-block;
  font-weight: bold;
}

.event-list__body {
  padding: inhouse.get-spacing-size(xs) 0;
}

.event-list__item {
  padding: 0 inhouse.get-spacing-size(m);
}

.event-list__item + .event-list__item {
  margin-top: inhouse.get-spacing-size(xs);
}

.event-list__call-out {
  padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m);
}
