@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.empty-state {
  pointer-events: none;

  ._container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 30rem;
    padding: inhouse.get-spacing-size(m);
    padding-bottom: variables.$header-height + variables.$tab-nav-height;
    @include mixins.mq-boundary-show-drawer(up) {
      padding-bottom: variables.$header-height;
    }
  }

  &.--inside-section {
    position: relative;
    ._container {
      padding-bottom: inhouse.get-spacing-size(m);
    }
  }

  ._icon {
    margin-bottom: inhouse.get-spacing-size(s);
    color: inhouse.get-semantic-color(neutral, 500);
    font-size: inhouse.get-sizing-scale-size(48);
    line-height: 1;
  }

  ._title {
    text-align: center;
    font-weight: bold;
    color: inhouse.get-semantic-color(neutral, 500);
    @include inhouse.text(l, normal);
  }

  ._description {
    @include inhouse.text(m, normal);
  }

  ._action {
    margin-top: inhouse.get-spacing-size(xs);
    display: flex;
    justify-content: center;
  }
}

.empty-state__card {
  margin-top: inhouse.get-spacing-size(l);
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(m);
  @include inhouse.elevation($level: 1);
  pointer-events: all;
}
