@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";

.schedules-calendar-v1 {
  border: 0 !important;
  color: inhouse.get-primitive-color(gray, 600);
  font-family: initial !important;
  margin-bottom: inhouse.get-spacing-size(m);
  width: 100% !important;

  .react-calendar__navigation__label {
    background-color: initial !important;
    pointer-events: none;

    .react-calendar__navigation__label__labelText {
      font-family: initial !important;
      font-size: 1rem;
      font-weight: 700;

      .today {
        border-radius: inhouse.get-radius-size(m);
        border: 1px solid inhouse.get-semantic-color(neutral, 700);
        color: inhouse.get-semantic-color(neutral, 700);
        cursor: pointer;
        margin-left: inhouse.get-spacing-size(xs);
        padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(xs);
        pointer-events: initial;
      }
    }
  }

  .react-calendar__navigation__arrow {
    background-color: initial !important;
    color: inhouse.get-primitive-color(blue, 600);
    font-size: inhouse.get-font-size(xl);
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr[title] {
      text-decoration: none !important;
    }

    &.react-calendar__month-view__weekdays__weekday--weekend:nth-of-type(1) {
      color: #CC313B;
    }

    &.react-calendar__month-view__weekdays__weekday--weekend:nth-of-type(7) {
      color: #0053A1;
    }
  }

  .schedules-calendar-v1__tile {
    border: 0.85px solid #DEE0E3;
    color: #393C41 !important;
    display: flex;
    flex-direction: column;
    height: calc(inhouse.get-content-size(xs) / 7) !important;
    justify-content: space-between;
    padding: 0 !important;
    text-align: left;
    width: calc(inhouse.get-content-size(xs) / 7) !important;

    &:hover {
      background-color: #fff !important;
      opacity: 50% !important;
    }

    &.react-calendar__tile--active {
      background: initial !important;
      opacity: 50% !important;
    }

    &.react-calendar__tile--now {
      background-color: #FFFBE8 !important;
      opacity: 100%;
    }

    &.blue {
      background-color: #E5F4FF;
    }

    &.red {
      background-color: #FFF0F0;
    }

    &.gray {
      background-color: #EDEEF0;
    }

    &.past {
      .event-name {
        background-color: #90DECB;
        color: #767B85;
      }
    }

    &.upcoming {
      .event-name {
        color: inhouse.get-primitive-color(white);
        background-color: #25AC9F;
      }
    }

    abbr {
      padding-left: 5px;
      padding-top: 5px;
      width: 100%;
    }

    .additional-events-container {
      display: flex;
      justify-content: space-between;
      text-wrap: nowrap;

      .additional-events {
        color: #0263B2;
        font-size: 0.55rem;
      }
    }

    .event-name {
      border-radius: 0.2rem;
      display: inline-block;
      font-size: inhouse.get-font-size(xxs);
      padding: 0 0.2rem;
      white-space: nowrap;
      width: 100% !important;
    }
  }
}
