@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.textfield {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;

  &.-width-auto {
    width: auto;
  }

  &.-width-full {
    display: block;
    width: 100%;
    max-width: 100%;
  }

  &.-width-auto {
    min-width: math.div(100%, 2);
  }

  &.-width-auto {
    min-width: math.div(100%, 3);
  }

  ._input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-family: inherit;
    border: none;
    outline: 0;
    appearance: none;
    padding: inhouse.get-sizing-scale-size(28) inhouse.get-spacing-size(m)
      inhouse.get-spacing-size(s);
    color: inhouse.get-field-text-color(
      $color: neutral,
      $state: enabled
    );
    background-color: inhouse.get-field-surface-color(
      $color: neutral,
      $state: enabled
    );
    border-radius: 0;
    box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
        $color: neutral,
        $state: enabled
      )};
    @include inhouse.text(m, normal);

    &:hover {
      background-color: inhouse.get-field-surface-color(
        $color: neutral,
        $state: hover
      );
      box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
          $color: neutral,
          $state: hover
        )};
    }

    &:focus {
      background-color: inhouse.get-primitive-color($name: white);
      box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
            $color: neutral,
            $state: focused
          )},
        #{inhouse.get-focus-ring-box-shadow(
            $color:
              inhouse.get-field-border-color(
                $color: neutral,
                $state: focused
              )
          )};
    }

    &:disabled {
      box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
          $color: neutral,
          $state: disabled
        )};

      // disabled のときの見た目を Inhouse のスタイルからカスタマイズしています
      color: inhouse.get-semantic-color(neutral, 800);
      background-color: inhouse.get-primitive-color(white);
    }

    &::placeholder {
      color: transparent;
    }
  }

  // iOSでは日付・時刻の表示をシャドウコンテンツで描画しているため
  // 値が空の場合も要素の高さを保つするように全体の高さからpaddingを引いた値をheightとして指定しています
  > input[type="date"]::-webkit-date-and-time-value,
  > input[type="time"]::-webkit-date-and-time-value,
  > input[type="datetime"]::-webkit-date-and-time-value {
    height: inhouse.get-sizing-scale-size(64) -
      inhouse.get-sizing-scale-size(28) - inhouse.get-spacing-size(s);
  }

  textarea._input {
    min-height: 8em;
    font-family: inherit;
    &.-height-half {
      min-height: 4em;
    }
  }

  &.--textarea-large textarea._input {
    min-height: 40vh;
  }

  &.--textarea-middle textarea._input {
    height: 15vh;
    min-height: 7em;
  }

  ._label {
    position: absolute;
    top: inhouse.get-spacing-size(s);
    left: inhouse.get-spacing-size(m);
    color: inhouse.get-semantic-color(neutral, 600);
    transition: variables.$animation-duration ease;
    @include inhouse.text(xs, dense);
  }

  ._input:placeholder-shown ~ ._label {
    top: 1.125rem;
    cursor: text;
    @include inhouse.text(l, normal);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  ._input:focus ~ ._label {
    top: inhouse.get-spacing-size(s);
    color: inhouse.get-semantic-color(informative, 600);
    @include inhouse.text(xs, dense);
  }
}
