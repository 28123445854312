@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.chips-list {
  .chips-list__list {
    @include mixins.initialize(ul);
    display: flex;
    flex-wrap: wrap;
    gap: inhouse.get-spacing-size(xxs);
    margin-bottom: inhouse.get-spacing-size(m);
    &:empty {
      margin-bottom: 0;
    }
  }

  .chips-list__item {
    @include mixins.initialize(li);
  }

  .chips-list__item > * {
    padding: 0 inhouse.get-spacing-size(xxs);
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    width: auto;
    height: auto;
    min-height: inhouse.get-sizing-scale-size(32);
    border: 1px solid transparent;
    border-radius: inhouse.get-radius-size(m);
    cursor: pointer;
    color: inhouse.get-primitive-color(blue, 700);
    background-color: inhouse.get-primitive-color(blue, 100);
    &:hover {
      color: inhouse.get-primitive-color(blue, 800);
      background-color: inhouse.get-primitive-color(blue, 200);
    }
  }

  .chips-list__leading {
    display: flex;
    align-items: center;
    margin-right: inhouse.get-spacing-size(xxs);
  }

  .chips-list__body {
    @include inhouse.text(s, dense);
  }
}
