@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.accordion-card {
  position: relative;
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(m);
}

.accordion-card__header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: inhouse.get-spacing-size(xxs)
    inhouse.get-interactive-component-height(xl) inhouse.get-spacing-size(xxs)
    inhouse.get-spacing-size(m);
  min-height: inhouse.get-interactive-component-height(xl);
}

button.accordion-card__switch {
  $spacing-size: math.div(
    inhouse.get-interactive-component-height(xl) -
      inhouse.get-interactive-component-height(m),
    2
  );

  position: absolute;
  top: $spacing-size;
  right: inhouse.get-spacing-size(s);
}

.accordion-card[aria-expanded="false"] {
  .accordion-card__header {
    cursor: pointer;
    @include mixins.interactive-overlay;
  }

  .accordion-card__body {
    display: none;
  }
}

.accordion-card[aria-expanded="true"] {
  .accordion-card__header {
    cursor: pointer;
    @include mixins.interactive-overlay;
  }

  .accordion-card__switch {
    transform: rotate(180deg);
  }

  .accordion-card__body {
    display: block;
  }
}

.accordion-card__body {
  ._trailing {
    // interactive-listの_trailingにあたっているcolorを上書き
    color: inhouse.get-primitive-color(gray, 600) !important;
  }
}

.accordion-card__title {
  font-weight: bold;
  @include inhouse.text(m, dense);
  @include mixins.kerning;
}

.accordion-card__link {
  @include mixins.initialize(a);
  display: block;
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
  @include mixins.interactive-overlay(
    $border-radius: inhouse.get-radius-size(m)
  );
}
