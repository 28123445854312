@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

// TODO addressee-list.scssをmodal-list.scssにする
// モーダルを開いてリストの中から選択させるUI

.modal-list {
  // memberページの2ペイン側に展開するためfixedではなくabsoluteにしています
  // ただ、そうするとmodal-listの高さがsingle-containerの高さになってしまうので
  // コース数が少ないときに下に余白がかなりできてしまう
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2001;

  .modal-list__modal {
    background-color: inhouse.get-primitive-color(white);
    @include inhouse.interactive-list-style();
    // このコンポーネントでは上下に間隔を空けない
    ul {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    ._list {
      @include mixins.mq-boundary-show-drawer(up) {
        margin: 0 auto;
        max-width: variables.$container-max-width;
      }
    }
    ._list.--no-side-margin {
      margin-right: 0 - inhouse.get-spacing-size(m);
      margin-left: 0 - inhouse.get-spacing-size(m);
    }
    ._list li:nth-child(even) {
      background-color: inhouse.get-primitive-color(gray, 50);
    }
    ._item > * {
      align-items: center;
    }
    ._description {
      margin-top: inherit;
    }

    // 「宛先」の一覧など、全画面いっぱいに広がるコンテンツのなかでは
    // .modal-header は画面上部にくっつける
    > .modal-header {
      @include mixins.mq-boundary-show-drawer(up) {
        top: 0;
      }
    }
  }

  .--loading {
    @include inhouse.interactive-list-export();
    @include inhouse.checkbox-export();
  }

  > ._container {
    background-color: inhouse.get-primitive-color(white);
    height: 100%;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    overscroll-behavior-y: none;

    // App bar より z-index が高くなるように指定しています
    z-index: variables.$app-bar-zindex + 1;

    &.--visible {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }
}
