@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.participation-list {
  margin-right: 0 - inhouse.get-spacing-size(m);
  margin-left: 0 - inhouse.get-spacing-size(m);
  @include inhouse.interactive-list-style;

  // このコンポーネントでは上下に間隔を空けない
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  // interactive-listコンポーネントの上書き
  ._item > * {
    @include inhouse.mq-boundary(down, s) {
      align-items: flex-start;
    }
  }

  ._body {
    min-height: inhouse.get-sizing-scale-size(64);
  }

  ._leading {
    align-items: center;
  }
}

.participation-list__group-header {
  @include mixins.initialize(heading);
  @include inhouse.text(m, dense);
  font-weight: bold;
  padding: inhouse.get-spacing-size(m) inhouse.get-spacing-size(m)
    inhouse.get-spacing-size(xs);
  scroll-margin-top: variables.$header-height;
  background: inhouse.get-primitive-color(white);
  z-index: 1;
  position: sticky;
  top: variables.$header-height;
  box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-primitive-color(
      gray,
      200
    )};
  @include mixins.mq-boundary-show-gmo-header(up) {
    scroll-margin-top: variables.$header-height * 2;
    top: variables.$header-height * 2;
  }
}

.participation-list__leading {
  position: relative;
}

.participation-list__content {
  display: flex;
  gap: inhouse.get-spacing-size(xxs);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @include inhouse.mq-boundary(up, s) {
    align-items: center;
    flex-direction: row;
    gap: inhouse.get-spacing-size(xxs);
  }
}

.participation-list__content > * {
  width: 100%;
  @include inhouse.mq-boundary(up, s) {
    max-width: 50%;
  }
}

.participation-list__name {
  color: inhouse.get-semantic-color(neutral, 800);
  word-break: break-all;
  @include inhouse.text(m, dense);
  @include mixins.text-truncate($max-lines: 2);
}

.participation-list__course {
  margin-top: inhouse.get-spacing-size(xxs);
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(xs, dense);
}

.participation-list__body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: inhouse.get-sizing-scale-size(2);
  @include inhouse.mq-boundary(up, s) {
    align-items: flex-end;
  }
}

.participation-list__comment {
  @include inhouse.text(xs, dense);
  padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(xs);
  border-radius: inhouse.get-radius-size(m);
  background: inhouse.get-primitive-color(gray, 100);
}

.participation-list__update {
  @include inhouse.text(xs, dense);
}

.participation-list__status {
  display: block;
  position: absolute;
  top: 58%;
  right: -4px;
  .icon {
    background-color: inhouse.get-primitive-color(white);
    border-radius: 50%;
    border: 1px solid inhouse.get-primitive-color(white);
    &.-color-positive {
      color: inhouse.get-semantic-color(positive, 600);
    }
    &.-color-negative {
      color: inhouse.get-semantic-color(negative, 600);
    }
  }
}
