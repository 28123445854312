@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.credit-card-list {
  @include inhouse.interactive-list-style(
    $option: (
      density: normal,
    )
  );

  ._list {
    padding: 0;
    background-color: inhouse.get-primitive-color(white);
  }

  /* interactive-list上書き */
  ._item > * {
    padding: 0;
  }

  ._item > * > ._leading {
    align-items: center;
    gap: inhouse.get-spacing-size(xxs);
  }

  ._item > * > ._body {
    padding: inhouse.get-spacing-size(xs) 0;
  }

  ._item > * > ._trailing {
    line-height: 1;
    @include mixins.mq-boundary-show-drawer(down) {
      align-items: flex-end;
      flex-direction: column;
    }
  }

  ._item ._title {
    display: flex;
    align-items: center;
  }

  ._item ._title,
  ._item ._description {
    line-height: 1;
  }

  ._thumbnail img {
    /* デフォルトだとcover */
    object-fit: contain !important;
  }

  .credit-card-list__brand {
    @include inhouse.text(xs, dense);
  }

  ._switch {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 inhouse.get-spacing-size(m);
    margin-bottom: inhouse.get-spacing-size(m);
    color: inhouse.get-field-text-color(
      $color: neutral,
      $state: enabled
    );
    background-color: inhouse.get-field-surface-color(
      $color: neutral,
      $state: enabled
    );
    box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
        $color: neutral,
        $state: enabled
      )};
    border-radius: 0;
    cursor: pointer;
    @include mixins.interactive-overlay;
    > * {
      width: 100%;
    }
  }

  ._switch-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ._switch-label {
    display: block;
    height: inhouse.get-sizing-scale-size(64);
    font-size: inhouse.get-font-size(l);
    line-height: inhouse.get-sizing-scale-size(64);
    @include mixins.text-truncate($max-lines: 1);
  }

  ._switch-trailing {
    font-size: inhouse.get-sizing-scale-size(24);
  }
}

.credit-card-dropdown__list {
  color: inhouse.get-primitive-color(gray, 800);
  width: 100%;
}

.credit-card-dropdown__item {
  display: flex;
  grid-gap: inhouse.get-spacing-size(xs);
  gap: inhouse.get-spacing-size(xs);
  align-items: stretch;
  width: 100%;
}

.credit-card-status {
  display: inline-flex;
  align-items: center;
  gap: inhouse.get-spacing-size(xxs);
  margin-left: inhouse.get-spacing-size(xxs);
  @include inhouse.text(xs, dense);
  &.--positive {
    color: inhouse.get-semantic-color(positive, 700);
  }
  &.--notice {
    color: inhouse.get-semantic-color(notice, 700);
  }
  a {
    @include mixins.text-link;
  }
  @include mixins.mq-boundary-show-drawer(down) {
    .credit-card-status__body {
      text-align: left;
    }
    span {
      display: block;
    }
  }
  @include mixins.mq-boundary-show-drawer(up) {
    margin-left: inhouse.get-spacing-size(xs);
  }
}
