@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.bill-group__item {
  display: flex;

  &.--no-side-padding {
    margin-right: 0 - inhouse.get-spacing-size(m);
    margin-left: 0 - inhouse.get-spacing-size(m);
  }
}

.bill-group__item-title {
  min-width: inhouse.get-sizing-scale-size(72);
  margin-right: inhouse.get-spacing-size(m);
  @include inhouse.text(m, normal);
}

.bill-group__item-body {
  flex: 1;
  min-width: 0;
  color: inhouse.get-semantic-color(neutral, 600);
  @include inhouse.text(m, normal);
  @include mixins.kerning;
  .interactive-list ._item > *:hover {
    background-color: inhouse.get-primitive-color(gray, 50);
  }
}

.bill-group__item-label {
  font-weight: bold;
  @include mixins.kerning;
}

.bill-group__item + .bill-group__item {
  margin-top: inhouse.get-spacing-size(m);
}

.bill-group__section + .bill-group__section {
  margin-top: inhouse.get-spacing-size(xl);
}

.bill-group__title {
  @include mixins.initialize(heading);
  display: flex;
  align-items: center;
  margin-bottom: inhouse.get-spacing-size(m);
  font-weight: bold;
  @include inhouse.text(m, dense);
}

.bill-group__title-trailing {
  margin-right: inhouse.get-spacing-size(xs);
  font-size: inhouse.get-sizing-scale-size(24);
}

.bill-group__section + .bill-group__section {
  margin-top: inhouse.get-spacing-size(l);
}

.bill-group__note {
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
  margin-top: inhouse.get-spacing-size(m);
  width: 100%;
  h3 {
    @include mixins.initialize(heading);
    font-weight: bold;
  }
  ._head {
    @include inhouse.text(m, normal);
    display: flex;
    align-items: center;
    gap: inhouse.get-spacing-size(xxs);
  }
  ._body {
    @include inhouse.text(s, normal);
    margin-top: inhouse.get-spacing-size(xs);
  }
  &.--attention {
    background-color: inhouse.get-semantic-color(attention, 100);
    ._head {
      color: inhouse.get-semantic-color(attention, 700);
    }
  }
  a {
    @include mixins.text-link;
  }
}

.bill-group__action {
  margin-top: inhouse.get-spacing-size(m);
  margin-bottom: inhouse.get-sizing-scale-size(32);
  padding: 0 inhouse.get-spacing-size(m);
  @include mixins.mq-boundary-show-drawer(up) {
    margin-bottom: inhouse.get-sizing-scale-size(48);
  }
  &.-align-right {
    text-align: right;
  }
}
