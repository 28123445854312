@use "@pepabo-inhouse/components-web" as inhouse;

.no-courses-notice {
  color: #767B85;
  font-weight: bold;
  margin-top: inhouse.get-spacing-size(xxl);
  text-align: center;

  .icon._icon {
    font-size: inhouse.get-font-size(xxxl);
    margin: inhouse.get-spacing-size(m) 0;
  }
}
