@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.home-event-card {
  position: relative;
  z-index: 1;
}

.home-event-card__link {
  @include mixins.initialize(a);
  display: block;
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(m);
  cursor: pointer;
  @include inhouse.elevation($level: 1);
  @include mixins.interactive-overlay(
    $border-radius: inhouse.get-radius-size(m)
  );
}

.home-event-card__header {
  padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 100);
  border-radius: inhouse.get-radius-size(m) inhouse.get-radius-size(m) 0 0;
}

.home-event-card__body {
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
}

.home-event-card__footer {
  padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 50);
  border-radius: 0 0 inhouse.get-radius-size(m) inhouse.get-radius-size(m);
}

.home-event-card__classroom {
  display: flex;
  align-items: center;
  padding: math.div(inhouse.get-spacing-size(xxs), 2);
}

.home-event-card__classroom-avatar {
  margin-right: inhouse.get-spacing-size(xxs);
}

.home-event-card__classroom-name {
  max-width: 12rem;
  color: inhouse.get-semantic-color(neutral, 800);
  @include inhouse.text(xs, dense);
  @include mixins.text-truncate($max-lines: 1);
}

.home-event-card__time {
  display: flex;
  align-items: center;
  @include inhouse.text(s, dense);
  @include mixins.kerning;

  time {
    display: block;
    font-weight: bold;
  }

  time + time {
    &::before {
      content: "〜";
      display: inline-block;
      padding: 0 0.125em;
      font-weight: normal;
    }
  }
}

.home-event-card__today-label {
  display: block;
  white-space: nowrap;
  height: inhouse.get-sizing-scale-size(16);
  line-height: inhouse.get-sizing-scale-size(16);
  padding: 0 inhouse.get-spacing-size(xxs);
  margin-left: inhouse.get-spacing-size(xs);
  color: inhouse.get-primitive-color(white);
  background-color: inhouse.get-semantic-color(positive, 600);
  font-size: inhouse.get-font-size(xs);
}

.home-event-card__title {
  margin-top: inhouse.get-spacing-size(xs);
  @include inhouse.text(l, dense);
  @include mixins.kerning;
}

.home-event-card__description {
  display: flex;
  align-items: center;
  margin-top: inhouse.get-spacing-size(xs);
  color: inhouse.get-primitive-color(gray, 600);
}

.home-event-card__description-body {
  @include mixins.text-truncate($max-lines: 1);
}

.home-event-card__location-description {
  display: flex;
  align-items: center;
  margin-top: inhouse.get-spacing-size(xs);
  color: inhouse.get-primitive-color(gray, 600);
  @include mixins.kerning;
}

.home-event-card__location-description-icon {
  margin-right: inhouse.get-spacing-size(xxs);
  font-size: inhouse.get-font-size(m);
  line-height: 1;
}

.home-event-card__description-body,
.home-event-card__location-description-body {
  @include inhouse.text(xs, normal);
}

.home-event-card__participation {
  display: flex;
  @include inhouse.text(xs, dense);
  @include mixins.kerning;
}

.home-event-card__participation-label {
  display: block;
  margin-right: 0.25em;
}

.home-event-card__participation-numerator {
  display: block;

  &.--has-absentee {
    color: inhouse.get-semantic-color(negative, 600);
    font-weight: bold;
  }
}

.home-event-card__participation-denominator {
  display: block;
}

.home-event-card__participation-numerator
  + .home-event-card__participation-denominator {
  &::before {
    content: "/";
    display: inline-block;
    padding: 0 0.25em;
  }
}

.home-event-card__person-list {
  @include mixins.initialize(ul);
}

.home-event-card__person-item {
  @include mixins.initialize(li);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-event-card__person-item + .home-event-card__person-item {
  margin-top: inhouse.get-spacing-size(xxs);
  padding-top: inhouse.get-spacing-size(xxs);
  border-top: inhouse.get-border-size(m) solid
    inhouse.get-primitive-color(gray, 200);
}

.home-event-card__person-avatar {
  margin-right: inhouse.get-spacing-size(xxs);
  ._icon {
    line-height: 1;
  }
}

.home-event-card__person-name {
  flex: 1;
  min-width: 0;
  color: inhouse.get-semantic-color(neutral, 800);
  @include inhouse.text(xs, dense);
  @include mixins.text-truncate($max-lines: 1);
}

.home-event-card__person-status {
  display: flex;
  align-items: center;
}

.home-event-card__person-status-label {
  margin-right: inhouse.get-spacing-size(xxs);
  @include inhouse.text(xs, dense);
  @include mixins.kerning;
}

.home-event-card__person-status-text {
  display: block;
  padding: 0 inhouse.get-spacing-size(xxs);
  background-color: inhouse.get-primitive-color(white);
  @include inhouse.text(xs, normal);
  display: flex;
  align-items: center;
  gap: 2px;

  &.-color-positive {
    .icon {
      color: inhouse.get-semantic-color(positive, 600);
    }
  }
  &.-color-negative {
    .icon {
      color: inhouse.get-semantic-color(negative, 600);
    }
  }
  &.-color-notice {
    .icon {
      color: inhouse.get-semantic-color(notice, 700);
    }
  }
}
