@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.cover {
  position: relative;

  ._media {
    img {
      width: 100%;
      height: inhouse.get-sizing-scale-size(112);
      object-fit: cover;
      vertical-align: top;
      @include mixins.mq-boundary-show-drawer(up) {
        height: inhouse.get-sizing-scale-size(160);
      }
    }

    svg {
      display: block;
      width: 100%;
      height: inhouse.get-sizing-scale-size(160);
      fill: currentColor;
      path {
        fill: inhouse.get-primitive-color(black);
      }
    }
  }

  &.-size-s ._media {
    height: inhouse.get-sizing-scale-size(112);
    overflow: hidden;
    @include mixins.mq-boundary-show-drawer(up) {
      height: inhouse.get-sizing-scale-size(160);
    }
  }

  ._container {
    $avatar-size: 3.5rem;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 inhouse.get-spacing-size(m);
    margin-top: 0 - math.div($avatar-size, 2);
  }

  &.-align-left ._container {
    align-items: flex-start;
  }

  ._title {
    font-weight: bold;
    @include inhouse.text(m, dense);
  }

  ._avatar + ._title {
    margin-top: inhouse.get-spacing-size(xs);
    ._link {
      @include mixins.initialize(a);
      color: inhouse.get-primitive-color(gray, 600);
    }
  }

  ._action {
    position: absolute;
    top: inhouse.get-spacing-size(m);
    right: inhouse.get-spacing-size(m);
  }

  ._link {
    @include mixins.interactive-overlay;
  }

  &.-color-0 {
    .gradient-color1 {
      stop-color: #ffe8cc;
    }
    .gradient-color2 {
      stop-color: #ffd8a8;
    }
  }

  &.-color-1 {
    .gradient-color1 {
      stop-color: #fcc0c0;
    }
    .gradient-color2 {
      stop-color: #f59d9d;
    }
  }

  &.-color-2 {
    .gradient-color1 {
      stop-color: #fcc0c0;
    }
    .gradient-color2 {
      stop-color: #f59d9d;
    }
  }

  &.-color-3 {
    .gradient-color1 {
      stop-color: #f3cffc;
    }
    .gradient-color2 {
      stop-color: #d6a9f5;
    }
  }

  &.-color-4 {
    .gradient-color1 {
      stop-color: #dacffc;
    }
    .gradient-color2 {
      stop-color: #bca9f5;
    }
  }

  &.-color-5 {
    .gradient-color1 {
      stop-color: #e5ebff;
    }
    .gradient-color2 {
      stop-color: #a9b8f5;
    }
  }

  &.-color-6 {
    .gradient-color1 {
      stop-color: #e5f3ff;
    }
    .gradient-color2 {
      stop-color: #a9d1f5;
    }
  }

  &.-color-7 {
    .gradient-color1 {
      stop-color: #c0f5fc;
    }
    .gradient-color2 {
      stop-color: #9debf5;
    }
  }

  &.-color-8 {
    .gradient-color1 {
      stop-color: #c5fcec;
    }
    .gradient-color2 {
      stop-color: #9af5da;
    }
  }

  &.-color-9 {
    .gradient-color1 {
      stop-color: #c5fccf;
    }
    .gradient-color2 {
      stop-color: #9af5ab;
    }
  }
}
