@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.announcement {
  ._meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-bottom: inhouse.get-spacing-size(m);
    margin-bottom: inhouse.get-spacing-size(l);
    border-bottom: inhouse.get-border-size(m) solid
      inhouse.get-primitive-color(gray, 300);
  }

  ._meta:not(.announcement__classroom) {
    gap: inhouse.get-spacing-size(xs);
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 0;
    @include inhouse.mq-boundary(down, s) {
      flex-direction: column;
      align-items: flex-start;
    }
    &:has(._address),
    &:has(._updated-date) {
      padding-bottom: inhouse.get-spacing-size(m);
    }
  }

  ._meta.announcement__classroom {
    margin-bottom: 0;
    border-bottom: none;
    gap: inhouse.get-spacing-size(s);
  }

  .addressee-select ._avatar {
    margin-right: 0;
  }

  ._author {
    flex: 1;
    min-width: 0;
    @include inhouse.text(m, dense);
  }

  ._address {
    display: flex;
    align-items: flex-start;
    @include inhouse.text(xs, normal);
    // announcementのaddressee-listのみ表示項目が異なる
    .addressee-list {
      > ._body {
        background-color: variables.$warm-bg;
        padding: 0 inhouse.get-spacing-size(xs);
        display: inline-block;
      }
      > ._body.announcement__tag-classroom {
        background-color: inhouse.get-semantic-color(positive, 200);
      }
      .addressee-list__list {
        justify-content: flex-start;
      }
      .addressee-list__modal {
        ._body {
          width: inhouse.get-sizing-scale-size(120);
        }
        ._status {
          width: inhouse.get-sizing-scale-size(40);
        }
        :is(._body, ._status) {
          flex: 0 0 auto;
        }
        ._description {
          flex-grow: 1;
          margin-top: inherit;
        }
      }
    }
    ._text {
      padding-top: 6px;
      white-space: nowrap;
    }
  }

  ._issued-date,
  ._updated-date {
    @include inhouse.text(xs, normal);
    span:not(._status) {
      display: inline-block;
    }
    ._status {
      @include inhouse.text(xs, normal);
      color: inhouse.get-primitive-color(gray, 800);
      background-color: inhouse.get-primitive-color(gray, 200);
      padding: 0 inhouse.get-spacing-size(xxs);
      margin-right: inhouse.get-spacing-size(xxs);
      display: inline-block;
    }
  }

  ._title {
    @include mixins.initialize(heading);
    font-weight: bold;
    @include inhouse.text(l, dense);
    @include mixins.kerning;
  }

  ._description {
    margin-top: inhouse.get-spacing-size(m);
    @include mixins.multiple-paragraph-typography($size: m, $density: normal);

    a {
      @include mixins.text-link;
    }
  }

  ._file {
    margin-top: inhouse.get-spacing-size(l);
  }
}
