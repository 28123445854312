@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.container {
  $side-gutter: inhouse.get-spacing-size(m);

  padding-right: $side-gutter;
  padding-left: $side-gutter;

  &.--revert {
    padding-right: 0;
    padding-left: 0;
    margin-right: (0 - $side-gutter);
    margin-left: (0 - $side-gutter);
  }
}
