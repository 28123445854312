@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.brand {
  border-bottom: inhouse.get-border-size(m) solid
    inhouse.get-primitive-color(gray, 200);
  @include mixins.mq-boundary-show-drawer(up) {
    border-bottom: none;
  }

  a {
    @include mixins.initialize(a);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inhouse.get-sizing-scale-size(64);
    padding: 0 inhouse.get-spacing-size(l);
    @include mixins.interactive-overlay;
    @include mixins.mq-boundary-show-drawer(up) {
      justify-content: flex-start;
    }

    &:focus {
      outline: none;
      box-shadow: inhouse.get-focus-ring-box-shadow() inset;
    }
  }

  img {
    display: block;
    height: 44px;
  }
}
