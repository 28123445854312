@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.dev-mode-switch {
  margin: inhouse.get-spacing-size(m);
  padding: inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 50);
  border-radius: inhouse.get-radius-size(m);

  span {
    display: block;
    margin-bottom: inhouse.get-spacing-size(xs);
    color: inhouse.get-primitive-color(gray, 600);
    @include inhouse.text(s, normal);
  }
}
