@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

$gap-size: math.div(inhouse.get-spacing-size(s), 2);

.textfield-group {
  display: flex;
  margin: 0 - $gap-size;
  &.-no-margin {
    margin: 0;
  }
}

.textfield-group__item {
  box-sizing: border-box;
  padding: $gap-size;
  &.-flex-grow {
    flex: 1;
  }
}

.textfield-group.textfield-group--datetime {
  .textfield-group__item {
    width: math.div(100%, 2);
    @include inhouse.mq-boundary(up, s) {
      width: math.div(100%, 3);
    }
  }

  &.textfield-group--start {
    .textfield-group__item {
      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  &.textfield-group--end {
    .textfield-group__item {
      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 30%;
      }
    }
  }
}
