@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.default-payment-status {
  @include inhouse.interactive-list-style;

  ._item {
    background-color: inhouse.get-primitive-color(gray, 50);
  }

  ._icon {
    color: inhouse.get-primitive-color(gray, 600);
  }

  ._description {
    @include inhouse.text(s, dense);
  }

  &.--notice {
    ._item {
      background-color: inhouse.get-semantic-color(notice, 200);
    }
    ._leading ._icon {
      color: inhouse.get-semantic-color(notice, 700);
    }
    ._title {
      color: inhouse.get-semantic-color(notice, 800);
    }
    ._description {
      color: inhouse.get-semantic-color(neutral, 800);
    }
  }

  &.--positive {
    ._item {
      background-color: inhouse.get-semantic-color(positive, 100);
    }
    ._leading ._icon {
      color: inhouse.get-semantic-color(positive, 700);
    }
    ._title {
      color: inhouse.get-semantic-color(positive, 800);
    }
  }
}
