@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.inline-list {
  @include mixins.initialize(ul);
  display: inline;

  ._item {
    display: inline;
  }

  ._item + ._item {
    &::before {
      content: ",";
      margin-right: inhouse.get-spacing-size(xxs);
    }
  }
}
