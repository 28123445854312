@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.optional-bill-list {
  background-color: inhouse.get-primitive-color(white);
  @include inhouse.interactive-list-style;

  // このコンポーネントでは上下に間隔を空けない
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._list {
    // α版ではスマホ版のインターフェースしか提供しないため
    // PCサイズでも両端のスペーシングはスマホサイズと揃える
    li > * {
      padding-right: inhouse.get-spacing-size(m) !important;
      padding-left: inhouse.get-spacing-size(m) !important;
    }
    li > *:hover {
      background-color: inhouse.get-primitive-color(gray, 50);
    }
  }

  ._name {
    color: inhouse.get-semantic-color(neutral, 600);
    @include inhouse.text(s, dense);
  }

  ._schedule {
    color: inhouse.get-semantic-color(neutral, 800);
    @include inhouse.text(l, normal);
  }
}

.optional-bill-list + .optional-bill-list {
  border-top: 0;
}
