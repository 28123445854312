@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.bottom-navigation {
  // @include inhouse.bottom-navigation-style;
}

.bottom-nav {
  position: relative;
  height: variables.$bottom-nav-height;
  padding-bottom: env(safe-area-inset-bottom);
  @include mixins.mq-boundary-show-drawer(up) {
    display: none;
  }

  ._wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 100;
    @include mixins.mq-boundary-show-drawer(up) {
      display: flex;
      justify-content: center;
      padding-left: math.div(variables.$drawer-width, 2);
    }
  }

  ._container {
    height: variables.$bottom-nav-height;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: inhouse.get-primitive-color(white);
    pointer-events: auto;
    @include inhouse.elevation($level: 2);
    @include mixins.mq-boundary-show-drawer(up) {
      width: variables.$container-max-width;
    }
  }

  ._list {
    @include mixins.initialize(ul);
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: variables.$bottom-nav-height;
  }

  ._item {
    width: math.div(100%, 3);

    &.--active a {
      color: inhouse.get-semantic-color(informative, 600);
    }

    a {
      @include mixins.initialize(a);
      position: relative;
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      color: inhouse.get-semantic-color(informative, 200);

      &:focus {
        color: inhouse.get-semantic-color(informative, 600);
        background-color: inhouse.get-semantic-color(informative, 100);
        outline: none;
        box-shadow: inhouse.get-focus-ring-box-shadow() inset;
      }

      &:hover {
        color: inhouse.get-semantic-color(informative, 600);
        background-color: inhouse.get-semantic-color(informative, 100);
      }
    }
  }

  ._icon {
    text-align: center;
    font-size: 28px;
    padding-top: 6px;
    position: relative;
  }

  ._badge {
    position: absolute;
    right: 16%;
    top: 8px;
    background-color: inhouse.get-primitive-color(red, 600);
    color: inhouse.get-primitive-color(white);
    height: inhouse.get-sizing-scale-size(16);
    border-radius: inhouse.get-radius-size(l);
    text-align: center;
    padding: 0 6px;
    font-size: inhouse.get-font-size(xxs);
    box-shadow: 0 0 0 2px inhouse.get-primitive-color(white);
  }

  ._title {
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: inhouse.get-font-size(xs);
  }
}
