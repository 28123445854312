@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.invoice {
  .invoice__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: inhouse.get-spacing-size(m) 0px;
    grid-auto-flow: row;
    grid-template-areas:
      ". . action action"
      "name name issuer issuer";
  }
  .invoice__action {
    grid-area: action;
    justify-self: end;
  }
  .invoice__name {
    grid-area: name;
    justify-self: start;
    padding-top: inhouse.get-spacing-size(xs);
  }
  .invoice__issuer {
    grid-area: issuer;
    justify-self: start;
    ._media {
      width: 80%;
      img {
        vertical-align: bottom;
        margin-left: 0 - inhouse.get-spacing-size(s);
      }
    }
    ._address {
      @include inhouse.text(s, dense);
    }
  }
  .invoice__body {
    margin-top: inhouse.get-spacing-size(xxl);
  }
  .invoice__item-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 0;
    grid-auto-flow: row;
    grid-template-areas: "period date";
    margin-bottom: inhouse.get-spacing-size(m);
  }
  .invoice__period {
    grid-area: period;
    justify-self: start;
  }
  .invoice__date {
    grid-area: date;
    justify-self: start;
  }
  .invoice__item + .invoice__item {
    margin-top: inhouse.get-spacing-size(m);
  }
  .invoice__table {
    @include inhouse.interactive-table-style(
      $option: (
        density: dense,
      )
    );
    @include inhouse.mq-boundary(down, s) {
      width: 100%;
    }
    &.-width-auto {
      width: auto;
    }
    &.-whitespace-initial {
      white-space: initial;
    }
    td.-no-wrap {
      white-space: nowrap;
    }
  }
}
