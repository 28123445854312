@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

// indexページ
.payout {
  padding: inhouse.get-spacing-size(m) 0;
}

.payout__banner {
  padding-top: inhouse.get-spacing-size(m);
}

.payout__section-title {
  @include mixins.initialize(heading);
  padding: 0 inhouse.get-spacing-size(m);
  margin-bottom: inhouse.get-spacing-size(s);
  @include inhouse.text(l, dense);
}

// cardはcurrentページで使用
.payout-summary-card {
  position: relative;
  z-index: 1;
  background-color: inhouse.get-primitive-color(white);
  border-radius: inhouse.get-radius-size(m);
  @include inhouse.elevation($level: 1);
}

.payout-summary-card__header {
  padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 50);
  border-radius: inhouse.get-radius-size(m) inhouse.get-radius-size(m) 0 0;
}

.payout-summary-card__body {
  padding: inhouse.get-spacing-size(s) inhouse.get-spacing-size(m);
}

.payout-summary-card__footer {
  padding: inhouse.get-spacing-size(xs) inhouse.get-spacing-size(m)
    inhouse.get-spacing-size(m);
  border-radius: 0 0 inhouse.get-radius-size(m) inhouse.get-radius-size(m);

  .payout__table-wrapper {
    @include inhouse.mq-boundary(down, s) {
      overflow: auto;
    }
  }
}

// currentとshowページ共通のpayout-summaryスタイル
.payout-summary__header {
  padding: inhouse.get-spacing-size(xxs) inhouse.get-spacing-size(m);
  background-color: inhouse.get-primitive-color(gray, 50);
}

.payout-summary__body {
  padding: inhouse.get-spacing-size(s) 0;
}

.payout-summary__footer {
  padding: inhouse.get-spacing-size(xs) 0 inhouse.get-spacing-size(m);
}

.payout-summary-card__footer .payout__table-wrapper,
.payout-summary__footer .payout__table-wrapper {
  margin-top: inhouse.get-spacing-size(xs);
}

.payout-summary__name {
  @include mixins.initialize(heading);
  margin-bottom: inhouse.get-spacing-size(s);
  @include inhouse.text(l, dense);
}

.payout-summary__classroom {
  display: flex;
  align-items: center;
  padding: math.div(inhouse.get-spacing-size(xxs), 2);
}

.payout-summary__classroom-avatar {
  margin-right: inhouse.get-spacing-size(xxs);
}

.payout-summary__classroom-name {
  max-width: 12rem;
  color: inhouse.get-semantic-color(neutral, 800);
  @include inhouse.text(xs, dense);
  @include mixins.text-truncate($max-lines: 1);
}

.payout-summary__title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: inhouse.get-spacing-size(xs);
}

.payout-summary__title {
  @include mixins.initialize(heading);
  @include inhouse.text(m, dense);
  text-align: center;
  font-weight: normal;
  color: inhouse.get-primitive-color(gray, 600);
}

.payout-summary__amount {
  text-align: center;
  font-weight: bold;
  @include inhouse.text(xxl, dense);
}

.payout-summary__term {
  text-align: center;
  @include inhouse.text(s, dense);
}

.payout-summary__detail {
  margin-top: inhouse.get-spacing-size(xs);
  display: flex;
  justify-content: space-evenly;
  background-color: inhouse.get-primitive-color(gray, 100);
  padding: inhouse.get-spacing-size(s);
  text-align: center;
  @include inhouse.text(m, dense);
  span {
    display: block;
    color: inhouse.get-primitive-color(gray, 600);
    @include inhouse.text(s, dense);
  }
}

.payout-summary__bank {
  margin-top: inhouse.get-spacing-size(xs);
  padding: inhouse.get-spacing-size(s);
  background-color: inhouse.get-primitive-color(gray, 100);
  text-align: center;
  @include inhouse.text(s, dense);
  span {
    display: inline-block;
    color: inhouse.get-primitive-color(gray, 600);
    margin-right: inhouse.get-spacing-size(xs);
  }
  a {
    @include mixins.text-link;
  }
  &.-color-negative {
    background-color: inhouse.get-semantic-color(negative, 100);
    span {
      display: block;
      margin-right: 0;
    }
  }
}

// 集金内訳のtable
.payout__table {
  @include inhouse.interactive-table-style(
    $option: (
      density: dense,
    )
  );
  table-layout: fixed;
  background-color: inhouse.get-primitive-color(white);

  @include inhouse.mq-boundary(down, s) {
    width: 100%;
  }

  th,
  td {
    padding-right: inhouse.get-spacing-size(m);
    padding-left: inhouse.get-spacing-size(m);
    @include inhouse.text(m, comfort);

    // ウィンドウ幅が375px未満のときにフォントサイズを小さく調整しています
    @media screen and (max-width: 374px) {
      padding-right: inhouse.get-spacing-size(s);
      padding-left: inhouse.get-spacing-size(s);
      @include inhouse.text(s, comfort);
    }
  }

  td {
    @include mixins.text-truncate($max-lines: 1);
    &.--no-truncate {
      text-overflow: clip;
    }
  }

  @include inhouse.mq-boundary(up, m) {
    thead {
      th:nth-child(1) {
        width: 20%;
      }
      th:nth-child(2) {
        width: 20%;
      }
      th:nth-child(3) {
        width: 25%;
      }
      th:nth-child(4) {
        width: 20%;
      }
      th:nth-child(5) {
        width: 15%;
      }
    }
  }

  &.-inside-card {
    th,
    td {
      @include inhouse.text(s, normal);
      padding-right: inhouse.get-spacing-size(xs);
      padding-left: inhouse.get-spacing-size(xs);

      // ウィンドウ幅が375px未満のときにフォントサイズを小さく調整しています
      @media screen and (max-width: 374px) {
        @include inhouse.text(xs, comfort);
      }
    }
  }

  .payout__status {
    @include inhouse.text(s, normal);
    overflow-x: auto;

    // ウィンドウ幅が375px未満のときにフォントサイズを小さく調整しています
    @media screen and (max-width: 374px) {
      @include inhouse.text(xs, comfort);
    }

    .--calculating {
      color: inhouse.get-semantic-color(notice, 700);
    }
    .--preparing {
      color: inhouse.get-primitive-color(gray, 600);
    }
    .--completed {
      color: inhouse.get-semantic-color(positive, 700);
    }
    .--unnecessary {
      color: inhouse.get-primitive-color(gray, 600);
    }
    &.-color-negative {
      color: inhouse.get-semantic-color(negative, 700);
    }
  }

  .-fixed-width {
    width: 60%;

    a {
      display: block;
    }
  }

  a {
    @include mixins.text-link;
  }
}
