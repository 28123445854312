@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.modal-content {
  min-height: 100vh;
  background-color: inhouse.get-primitive-color(white);
  @include mixins.mq-boundary-show-gmo-header(up) {
    min-height: calc(100vh - #{variables.$header-height});
  }

  &.--animation-fade-in {
    @include mixins.animation($name: fade-in-and-bottom-to-top);
  }

  &.-background-transparent {
    background-color: transparent;
  }
}

.modal-content__container {
  padding: inhouse.get-spacing-size(m);
  @include mixins.mq-boundary-show-drawer(up) {
    margin: 0 auto;
    max-width: variables.$container-max-width;
  }
}
