@use "@pepabo-inhouse/components-web" as inhouse;
@use "./variables";
@use "./functions";
@use "sass:map";

// ドロワーメニューの表示・非表示のブレークポイントで分岐する Media Queries
@mixin mq-boundary-show-drawer($name) {
  @if $name == up {
    @include inhouse.mq-boundary(up, m) {
      @content;
    }
  } @else if $name == down {
    @include inhouse.mq-boundary(down, m) {
      @content;
    }
  }
}

// GMOインターネットグループ共通ヘッダーのブレークポイントで分岐する Media Queries
@mixin mq-boundary-show-gmo-header($name) {
  @if $name == up {
    @media screen and (min-width: variables.$gmo-header-breakpoint) {
      @content;
    }
  } @else if $name == down {
    $max-width: variables.$gmo-header-breakpoint - 1px;
    @media screen and (max-width: $max-width) {
      @content;
    }
  }
}

// スクリーンリーダーのみに情報を伝える
//
// オリジナル: Twitter Bootstrap
// https://github.com/twbs/bootstrap/blob/eae52b1cb0af18c5bf3fddd660f53d58d689cd63/scss/mixins/_visually-hidden.scss
@mixin visually-hidden() {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@mixin visually-hidden-focusable() {
  &:not(:focus):not(:focus-within) {
    @include visually-hidden();
  }
}

// 全角約物を　YakuHanJP Noto で表示して字詰めする
@mixin kerning() {
  font-family: "Open Sans", YakuHanJP_Noto, "Noto Sans JP", apple-system,
    BlinkMacSystemFont, Roboto, "Lucida Grande", Helvetica, Arial, sans-serif;
}

// 最大行数以降の文字列を省略する
@mixin text-truncate($max-lines: 1) {
  overflow: hidden;

  @if $max-lines > 1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $max-lines;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// CSSアニメーションのプロパティの指定を揃える
@mixin animation(
  $name: none,
  $duration: variables.$animation-duration,
  $timing-function: ease,
  $delay: 0s,
  $iteration-count: 1,
  $direction: normal,
  $fill-mode: none,
  $play-state: running
) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing-function;
  animation-delay: $delay;
  animation-iteration-count: $iteration-count;
  animation-direction: $direction;
  animation-fill-mode: $fill-mode;
  animation-play-state: $play-state;
}

// クリックできる要素にマウスカーソルを重ねたときのスタイルを指定する
@mixin interactive-overlay($border-radius: 0) {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: inhouse.get-background-overlay-color(light, enabled);
    border-radius: $border-radius;
    pointer-events: none;
  }

  &:hover::after,
  &:active::after {
    background-color: inhouse.get-background-overlay-color(light, hover);
  }
}

// テキストリンクのスタイルを指定する
@mixin text-link {
  @include initialize(a);
  color: inhouse.get-semantic-color(informative, 600);
  word-break: break-all;
  outline: 0;

  &:hover,
  &:focus {
    color: darken(inhouse.get-semantic-color(informative, 600), 10%);
  }

  &:focus {
    box-shadow: inhouse.get-focus-ring-box-shadow() inset;
  }
}

// 複数の p 要素が含まれる可能性がある要素のスタイルを指定する
@mixin multiple-paragraph-typography($size: m, $density: normal) {
  @include inhouse.text($size, $density);

  p {
    @include initialize(p);
    @include inhouse.text($size, $density);
  }

  p + p {
    margin-top: inhouse.get-spacing-size($size);
  }
}

// 要素セレクタに指定された user agent stylesheet を無効にする
@mixin initialize($element, $support-ie11: true) {
  @include -element-initial-style($element, $support-ie11);
}

@mixin -element-initial-style($element, $support-ie11) {
  // a {
  //   color: inherit;
  //   text-decoration: none;
  // }
  @if $element == a {
    @include -property-initial-style(color, $support-ie11);
    @include -property-initial-style(text-decoration, $support-ie11);
  }

  // b {
  //   font-weight: normal;
  // }
  @else if $element == b {
    @include -property-initial-style(font-weight, $support-ie11);
  }

  // body {
  //   margin: 0;
  // }
  @else if $element == body {
    @include -property-initial-style(margin, $support-ie11);
  }

  // code, kbd, pre, samp {
  //   font-family: inherit;
  //   font-size: 100%;
  // }
  @else if $element == code-block {
    @include -property-initial-style(font-family, $support-ie11);
    @include -property-initial-style(font-size, $support-ie11);
  } @else if $element == code {
    @include -element-initial-style(code-block, $support-ie11);
  } @else if $element == kbd {
    @include -element-initial-style(code-block, $support-ie11);
  } @else if $element == pre {
    @include -element-initial-style(code-block, $support-ie11);
  } @else if $element == samp {
    @include -element-initial-style(code-block, $support-ie11);
  }

  // dd {
  //   margin: 0;
  // }
  @else if $element == dd {
    @include -property-initial-style(margin, $support-ie11);
  } @else if $element == dl {
    @include -property-initial-style(margin, $support-ie11);
  }

  // em {
  //   font-style: normal;
  //   font-weight: normal;
  // }
  @else if $element == em {
    @include -property-initial-style(font-style, $support-ie11);
    @include -property-initial-style(font-weight, $support-ie11);
  }

  // fieldset {
  //   padding: 0;
  // }
  @else if $element == fieldset {
    @include -property-initial-style(padding, $support-ie11);
  }

  // button, input, optgroup, select, textarea {
  //   appearance: none;
  //   background: none;
  //   border: none;
  //   border-radius: 0;
  //   font-family: inherit;
  //   margin: 0;
  // }
  @else if $element == form-input {
    @include -property-initial-style(appearance, $support-ie11);
    @include -property-initial-style(background, $support-ie11);
    @include -property-initial-style(border, $support-ie11);
    @include -property-initial-style(border-radius, $support-ie11);
    @include -property-initial-style(font-family, $support-ie11);
    @include -property-initial-style(margin, $support-ie11);
  } @else if $element == button {
    @include -element-initial-style(form-input, $support-ie11);
  } @else if $element == input {
    @include -element-initial-style(form-input, $support-ie11);
  } @else if $element == optgroup {
    @include -element-initial-style(form-input, $support-ie11);
  } @else if $element == select {
    @include -element-initial-style(form-input, $support-ie11);
  } @else if $element == textarea {
    @include -element-initial-style(form-input, $support-ie11);
  }

  // h1, h2, h3, h4, h5, h6 {
  //   font-size: 100%;
  //   font-weight: normal;
  //   margin: 0;
  // }
  @else if $element == heading {
    @include -property-initial-style(font-size, $support-ie11);
    @include -property-initial-style(font-weight, $support-ie11);
    @include -property-initial-style(margin, $support-ie11);
  } @else if $element == h1 {
    @include -element-initial-style(heading, $support-ie11);
  } @else if $element == h2 {
    @include -element-initial-style(heading, $support-ie11);
  } @else if $element == h3 {
    @include -element-initial-style(heading, $support-ie11);
  } @else if $element == h4 {
    @include -element-initial-style(heading, $support-ie11);
  } @else if $element == h5 {
    @include -element-initial-style(heading, $support-ie11);
  } @else if $element == h6 {
    @include -element-initial-style(heading, $support-ie11);
  }

  // i {
  //   font-style: normal;
  // }
  @else if $element == i {
    @include -property-initial-style(font-style, $support-ie11);
  }

  // ul, ol {
  //   list-style: none;
  //   margin: 0;
  //   padding: 0;
  // }
  @else if $element == list {
    @include -property-initial-style(list-style, $support-ie11);
    @include -property-initial-style(margin, $support-ie11);
    @include -property-initial-style(padding, $support-ie11);
  } @else if $element == ul {
    @include -element-initial-style(list, $support-ie11);
  } @else if $element == ol {
    @include -element-initial-style(list, $support-ie11);
  }

  // p {
  //   margin: 0;
  // }
  @else if $element == p {
    @include -property-initial-style(margin, $support-ie11);
  }

  // small {
  //   font-size: 100%;
  // }
  @else if $element == small {
    @include -property-initial-style(font-size, $support-ie11);
  }

  // strong {
  //   font-weight: normal;
  // }
  @else if $element == strong {
    @include -property-initial-style(font-weight, $support-ie11);
  }
}

@mixin -property-initial-style($property, $support-ie11) {
  #{$property}: -get-property-initial-value($property, $support-ie11);
}

@function -get-property-initial-value($property, $support-ie11) {
  $value: null;

  // appearance: none;
  @if $property == appearance {
    $value: -get-browser-support-value(none, $support-ie11);
  }

  // background: none;
  @else if $property == background {
    $value: -get-browser-support-value(none, $support-ie11);
  }

  // border: none;
  @else if $property == border {
    $value: -get-browser-support-value(none, $support-ie11);
  }

  // border-radius: 0;
  @else if $property == border-radius {
    $value: -get-browser-support-value(0, $support-ie11);
  }

  // color: inherit;
  @else if $property == color {
    $value: -get-browser-support-value(inherit, $support-ie11);
  }

  // font-family: inherit;
  @else if $property == font-family {
    $value: -get-browser-support-value(inherit, $support-ie11);
  }

  // font-size: 100%; (100% as medium)
  @else if $property == font-size {
    $value: -get-browser-support-value(100%, $support-ie11);
  }

  // font-style: normal;
  @else if $property == font-style {
    $value: -get-browser-support-value(normal, $support-ie11);
  }

  // font-weight: normal;
  @else if $property == font-weight {
    $value: -get-browser-support-value(normal, $support-ie11);
  }

  // list-style: none;
  @else if $property == list-style {
    $value: -get-browser-support-value(none, $support-ie11);
  }

  // margin: 0;
  @else if $property == margin {
    $value: -get-browser-support-value(0, $support-ie11);
  }

  // padding: 0;
  @else if $property == padding {
    $value: -get-browser-support-value(0, $support-ie11);
  }

  // text-decoration: none;
  @else if $property == text-decoration {
    $value: -get-browser-support-value(none, $support-ie11);
  }

  @return $value;
}

@function -get-browser-support-value($initial-value, $support-ie11) {
  $value: initial;

  @if $support-ie11 {
    $value: $initial-value;
  }

  @return $value;
}
