@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.app-bar {
  @include inhouse.app-bar-style;

  > ._content > ._leading {
    @include mixins.mq-boundary-show-drawer(up) {
      display: none;
    }
  }
}
