@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.simple-textfield {
  @include inhouse.textfield-style;

  // iOSでは日付・時刻の表示をシャドウコンテンツで描画しているため
  // input要素のサイズの変更にあわせて表示が崩れないように調整しています
  > input[type="date"]::-webkit-date-and-time-value,
  > input[type="time"]::-webkit-date-and-time-value,
  > input[type="datetime"]::-webkit-date-and-time-value {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.-size-l {
    > input[type="text"],
    > input[type="date"],
    > input[type="time"],
    > input[type="datetime"] {
      height: inhouse.get-interactive-component-height(l);
      line-height: inhouse.get-interactive-component-height(l);
    }
  }
}
