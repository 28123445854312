@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.receipt {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto auto auto auto auto;
  gap: 0 inhouse.get-spacing-size(xxs);
  grid-auto-flow: row;
  grid-template-areas:
    ". . receipt__action receipt__action"
    "receipt__name receipt__name receipt__meta receipt__meta"
    "receipt__amount receipt__amount receipt__amount receipt__amount"
    "receipt__description-title receipt__description-body receipt__description-body receipt__description-body"
    "receipt__day-title receipt__day-body receipt__day-body receipt__day-body"
    "receipt__hr receipt__hr receipt__hr receipt__hr"
    "receipt__method-type receipt__method-type receipt__method-type ."
    ". receipt__issuer receipt__issuer receipt__issuer";
}

.receipt__action {
  justify-self: end;
  margin-bottom: inhouse.get-spacing-size(m);
  grid-area: receipt__action;
}

.receipt__name {
  justify-self: start;
  align-self: start;
  @include inhouse.text(l, normal);
  grid-area: receipt__name;
}

.receipt__meta {
  justify-self: end;
  align-self: start;
  text-align: right;
  @include inhouse.text(s, dense);
  grid-area: receipt__meta;
}

.receipt__amount {
  justify-self: stretch;
  @include inhouse.text(xxl, normal);
  text-align: center;
  margin: inhouse.get-spacing-size(l) 0 inhouse.get-spacing-size(m);
  padding: inhouse.get-spacing-size(xxs) 0;
  background-color: inhouse.get-primitive-color(gray, 100);
  grid-area: receipt__amount;
}

.receipt__description-title {
  justify-self: start;
  align-self: start;
  grid-area: receipt__description-title;
}

.receipt__description-body {
  justify-self: start;
  align-self: start;
  grid-area: receipt__description-body;
}

.receipt__day-title {
  justify-self: start;
  align-self: start;
  margin-top: inhouse.get-spacing-size(xs);
  grid-area: receipt__day-title;
}

.receipt__day-body {
  justify-self: start;
  align-self: start;
  margin-top: inhouse.get-spacing-size(xs);
  grid-area: receipt__day-body;
}

.receipt__hr {
  margin-top: inhouse.get-spacing-size(xxs);
  justify-content: stretch;
  grid-area: receipt__hr;
  hr {
    color: transparent;
    background-color: inhouse.get-primitive-color(gray, 600);
    border: 0;
    &:not([size]) {
      height: inhouse.get-border-size(m);
    }
  }
}

.receipt__method-type {
  justify-self: start;
  align-self: start;
  @include inhouse.text(s, normal);
  margin-top: inhouse.get-spacing-size(l);
  grid-area: receipt__method-type;
}

.receipt__issuer {
  justify-self: end;
  align-self: start;
  text-align: right;
  margin-top: inhouse.get-spacing-size(l);
  grid-area: receipt__issuer;
  ._name {
    @include inhouse.text(l, normal);
  }
  ._address {
    @include inhouse.text(s, dense);
  }
}
