@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.addressee-select {
  background-color: inhouse.get-primitive-color(white);
  @include inhouse.interactive-list-style(
    $option: (
      density: normal,
    )
  );

  // このコンポーネントでは上下に間隔を空けない
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._switch {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 inhouse.get-spacing-size(m);
    margin-bottom: inhouse.get-spacing-size(m);
    color: inhouse.get-field-text-color(
      $color: neutral,
      $state: enabled
    );
    background-color: inhouse.get-field-surface-color(
      $color: neutral,
      $state: enabled
    );
    box-shadow: inset 0 #{0 - inhouse.get-border-size($level: m)} 0 0 #{inhouse.get-field-border-color(
        $color: neutral,
        $state: enabled
      )};
    border-radius: 0;
    cursor: pointer;
    @include mixins.interactive-overlay;
  }

  ._switch-label {
    display: block;
    height: inhouse.get-sizing-scale-size(64);
    font-size: inhouse.get-font-size(l);
    line-height: inhouse.get-sizing-scale-size(64);
    @include mixins.text-truncate($max-lines: 1);
  }

  ._switch-trailing {
    font-size: inhouse.get-sizing-scale-size(24);
  }

  ._list {
    @include mixins.mq-boundary-show-drawer(up) {
      margin: 0 auto;
      max-width: variables.$container-max-width;
    }
    @include mixins.mq-boundary-show-drawer(down) {
      margin: 0 0 - inhouse.get-spacing-size(m);
    }

    li > * {
      // α版ではスマホ版のインターフェースしか提供しないため
      // PCサイズでも両端のスペーシングはスマホサイズと揃える
      padding-right: inhouse.get-spacing-size(m) !important;
      padding-left: inhouse.get-spacing-size(m) !important;

      // 要素を上下中央に揃える
      display: flex;
      align-items: center;
    }
  }

  .addressee-select__list {
    @include mixins.mq-boundary-show-drawer(down) {
      min-height: calc(100vh - #{variables.$header-height});
    }
  }

  .addressee-selected__list {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ._list.--no-side-margin {
    margin-right: 0 - inhouse.get-spacing-size(m);
    margin-left: 0 - inhouse.get-spacing-size(m);
  }

  ._item ._body {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  ._item ._title {
    min-width: 110px;
    margin-right: inhouse.get-spacing-size(xs);
  }

  ._item ._description {
    line-height: inhouse.get-sizing-scale-size(20);
  }

  ._button {
    padding-top: inhouse.get-spacing-size(s);
    padding-bottom: inhouse.get-spacing-size(s);
  }

  > ._container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: inhouse.get-primitive-color(white);
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    // App bar より z-index が高くなるように指定しています
    z-index: variables.$app-bar-zindex + 1;

    &.--visible {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }

    // ウィンドウの幅が広いときのヘッダーの位置を調整しています
    > .modal-header {
      @include mixins.mq-boundary-show-drawer(up) {
        top: 0;
      }
    }
  }
}
