@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";

.dev-badge {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  height: inhouse.get-sizing-scale-size(16);
  line-height: inhouse.get-sizing-scale-size(16);
  padding: 0 inhouse.get-sizing-scale-size(8);
  font-size: inhouse.get-font-size(s);
  color: inhouse.get-primitive-color(white);
  background-color: rgba(inhouse.get-primitive-color(gray, 600), 0.8);
  border-radius: 0 0 inhouse.get-radius-size(m) 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 2147483647; // z-indexの最大値

  &.--develop {
    background-color: rgba(inhouse.get-primitive-color(yellow, 600), 0.8);
  }

  &.--staging {
    background-color: rgba(inhouse.get-primitive-color(red, 600), 0.8);
  }
}
