@use "@pepabo-inhouse/components-web" as inhouse;
@use "../variables";
@use "../functions";
@use "../mixins";
@use "sass:math";

.header.--show-modal-content {
  @include mixins.mq-boundary-show-drawer(down) {
    display: none;
  }
}

.header-title {
  display: flex;
  align-items: center;
  @include mixins.mq-boundary-show-drawer(up) {
    padding-left: inhouse.get-spacing-size(xs);
  }
}

.header-title__label {
  flex: 1;
  min-width: 0;
  max-width: calc(100vw - #{136px});
  font-weight: bold;
  @include inhouse.text(m, dense);
  @include mixins.kerning;
  @include mixins.text-truncate($max-lines: 1);
  @include mixins.mq-boundary-show-drawer(up) {
    max-width: 24em;
    @include inhouse.text(l, dense);
  }
}

// タイトルの文字がタブナビゲーションに重ならないように調整しています
.header.--show-tab-nav .header-title__label {
  @include inhouse.mq-boundary(down, l) {
    @include mixins.mq-boundary-show-drawer(up) {
      max-width: 20rem;
    }
  }
}

.header__tab-nav {
  width: calc(100% + 16px);
  margin: 8px -8px -8px;
  @include mixins.mq-boundary-show-gmo-header(up) {
    box-sizing: border-box;
    padding-left: variables.$drawer-width;
  }
  @include mixins.mq-boundary-show-drawer(down) {
    padding-left: 0;
  }
  @include mixins.mq-boundary-show-drawer(up) {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: variables.$drawer-width;
    margin: 0;
    pointer-events: none;
  }

  a {
    @include mixins.mq-boundary-show-drawer(up) {
      pointer-events: auto;
    }
  }
  @include inhouse.mq-boundary(up, xl) {
    width: calc(100% - variables.$drawer-width);
  }
}
